/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
fury-config-panel {

  .section {
    border-bottom: 1px solid $theme-divider;
  }

  .subheading {
    color: $theme-secondary-text;
  }

  .style {
    &:hover {
      background: $theme-background-hover;
    }
  }

  .color {

    &.default {
      background: $sidenav-background;
      color: $sidenav-color;
    }

    &.light {
      background: white;
      color: #000;
    }

    &.dark {
      background: #303030;
      color: white;
    }

    &.flat {
      background: #f5f5f5;
      color: #000;
    }
  }
}
