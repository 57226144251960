/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
.sidenav-item {
  color: $sidenav-item-color;

  .subheading {
    color: $sidenav-item-subheading-color;
    font-family: $sidenav-item-subheading-typography-font-family;
  }

  .sidenav-item-link {
    color: inherit;
    font-family: $sidenav-item-typography-font-family;
    font-size: $sidenav-item-typography-font-size;
    font-weight: $sidenav-item-typography-font-weight;

    &:hover {
      background: $sidenav-item-background-hover;
      color: $sidenav-item-color-hover;

      .text-icon {
        color: inherit;
      }
    }

    &.active {
       background: #1976d2;
       color:#fff;

      .text-icon {
        color: inherit;
      }
      i{ color: #fff;}
    }

    .text-icon {
      color: $sidenav-item-text-icon-color;
    }
    i{ font-size: 20px; color: #fff;}
  }

  &.level-0 {
    &.dropdown-open {
      background: $sidenav-item-dropdown-level1;

      > .sidenav-item-link {
        color: $sidenav-item-color-hover;
      }
    }
  }

  &.level-1 {
    background: $sidenav-item-dropdown-level1;

    .sidenav-item-link {
      padding-left: $sidenav-item-padding-left-level1;
    }
  }

  &.level-2 {
    background: $sidenav-item-dropdown-level2; 

    .sidenav-item-link {
      padding-left: $sidenav-item-padding-left-level2;
    }
  }

  &.level-3 {
    background: $sidenav-item-dropdown-level3;

    .sidenav-item-link {
      padding-left: $sidenav-item-padding-left-level3;
    }
  }

  &.level-4 {
    background: $sidenav-item-dropdown-level4;

    .sidenav-item-link {
      padding-left: $sidenav-item-padding-left-level4;
    }
  }
}



simplebar-content-wrapper{position: relative;
}
  .setting_faq{
  position:fixed ;
  bottom: 0px;
  //background: #313a4e;
  width: 15em;
  max-width: 100%;
  max-height: 100%;
  
} 