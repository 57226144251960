/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
.fury-dark {

    // Start Hubspot Dark theme 
    .hotspottalk {
        .bootm-modal {
            background: #3a3a3a;
            border: 1px solid #eae6e1;

            .modal-inner {
                .sticky-header {
                    .help-heading {
                        background: #373737;
                        color: #fff;
                    }

                    .contct-sud {
                        background: #373737;
                        color: #fff;
                    }
                }

                .heade-sec {
                    background: #1976d2;
                }
            }
        }
    }

    // End Hubspot Dark theme 
    .adminentrown .adminentn {
        background: #424242 !important;

        h2 {
            background: #373737 !important;
        }

        &:hover h2 {
            color: #fff !important;
        }

        .bodytext ul li {
            background: #424242 !important;
        }
    }

    .sys_admin {
        .tcard-new {
            background: #424242 !important;

            h2 {
                background-color: #373737 !important;
            }

            &:hover h2 {
                color: #fff !important;
            }

            .bodytext ul li {
                background: #424242 !important;
            }
        }
    }

    .cardrow .tradcard,
    .cardrow .tradcard .bodytext ul li {
        background: #424242;
    }

    .cardrow .tradcard .bodytext ul li:hover {
        background-position: left bottom;
        background: #5e5d5d;
    }

    fury-toolbar-create {
        background: none !important;
    }

    fury-toolbar-upload {
        background: none !important;
    }

    fury-toolbar-view {
        background: none !important;
    }

    //  .tradecard:nth-child(1) h2, .tradecard:nth-child(2) h2,
    //  .tradecard:nth-child(3) h2, .tradecard:nth-child(4) h2,
    //  .tradecard:nth-child(5) h2,  .Portfoliocard:nth-child(1) h2,
    //  .Portfoliocard:nth-child(2) h2, .Portfoliocard:nth-child(3) h2,
    //  .Portfoliocard:nth-child(4) h2,  .accoutcard:nth-child(1) h2,
    //  .accoutcard:nth-child(2) h2,  .accoutcard:nth-child(3) h2,
    //  .accoutcard:nth-child(4) h2, .investorico:nth-child(1) h2
    //  {color: #424242;}
    .page_container .next_links ul li {
        background-color: hsla(0, 0%, 100%, .1) !important;
    }

    .page_container .next_links ul li:hover {
        background-color: hsla(0, 0%, 100%, .5) !important;
    }

    .mat-step-header:nth-child(1) {
        background: #424242;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-gap {
        border: solid 1px #fff;
        width: 100% !important;
        border-left: none !important;
        border-right: none !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-start {
        border-right-style: none !important;
    }

    fury-toolbar-search-bar .search-wrapper:hover .search-input {
        background: #000 !important;
        color: #fff !important;
    }

    .setting_faq,
    .mfaHeader {
        background: #212121;
        background-color: #212121 !important;
    }

    .toolbar-button:hover {
        background: none;
    }

    table.tableheader-remove .material-icons-outlined {
        color: #fff !important;
        font-weight: normal !important;
    }

    fury-list .fury-list-header {
        z-index: 99;
        border-radius: 0px;
    }

    .subhead-bg {
        background-color: #5e5d5d !important;
    }

    .createpopup .heading h3 {
        color: #fff;
    }

    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: #fff;
    }

    .mat-menu-item:hover:not([disabled]) {
        color: #fff;
    }

    .mat-button,
    .mat-icon-button,
    .mat-stroked-button {
        color: #fff !important;
    }

    .translatedrop select {
        height: 30px;
        background: #212121 !important;
        color: #fff;
        border-radius: 4px;
    }


    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
        //z-index: 999999;
        position: absolute;
        top: 25px !important;
        background: #424242;
        width: auto;
        height: auto;
        padding: 0px;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
        border-top-color: #fff;
    }

    .mat-button-toggle-standalone,
    .mat-button-toggle-group {
        border-radius: 4px !important;
        margin-right: -20%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
        box-shadow: 0 0 0 30px #f5f5f5 inset !important;
    }

    fury-toolbar-user .dropdown .list-item-icon {
        color: white !important;
    }

    fury-toolbar-user .dropdown .list .list-item:hover {
        background: rgba(0, 0, 0, 0.04);
        color: rgb(255 255 255);
    }

    .p-inputtext {
        color: #fff !important;
        background: none;
    }

    .p-float-label input:focus~label,
    .p-float-label input.p-filled~label,
    .p-float-label textarea:focus~label,
    .p-float-label textarea.p-filled~label,
    .p-float-label .p-inputwrapper-focus~label,
    .p-float-label .p-inputwrapper-filled~label {
        background: #424242 !important;
        color: #838689 !important;
    }

    .p-float-label>label {
        color: #838689 !important;
        //background: #494949;
    }

    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        // color: #1976d2;
        background: #212121 !important;
    }

    tr.example-element-row:not(.example-expanded-row):hover {
        background: #565656 !important;
    }

    //  .cardrow{margin-top: 0em !important; padding-top: 0px !important;}
    .cardrow .tradcard h2 {
        color: #424242;
    }

    .cardrow .tradcard .bodytext ul li:hover {
        background: #5e5d5d !important;
    }

    .mat-step-header .mat-step-label.mat-step-label-active {
        color: #424242;
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: #ffffff;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
        background-color: #ffffff;
    }

    .upload-screen .mat-stepper-vertical {
        background-color: #424242;
        width: 100%;
    }

    .mat-step-header .mat-step-label,
    .mat-step-header .mat-step-optional {
        color: #fff !important;
        font-size: 14px;
        font-weight: 500;
    }

    .mat-step-header .mat-step-icon {
        background-color: rgb(25 118 210);
        color: white;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: #fff;
        left: .3235em;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-end,
    .ag-overlay-wrapper,
    .mat-form-field-appearance-outline .mat-form-field-outline-start,
    sup {
        color: #fff;
    }

    .mat-form-field-type-mat-select .mat-form-field-label {
        color: rgba(255, 255, 255, 0.7) !important;
    }

    .mat-select-arrow {
        color: rgba(255, 255, 255, 0.7) !important;
    }

    .login-page-component .text-center p {
        color: rgb(255 255 255 / 54%) !important;
    }

    .sign-space {
        border: none !important;
    }

    //  .p-multiselect{background: #424242;  border: 1px solid #ffffff;}
    .p-inputtext:nth-child(1) {
        color: #fff !important;
        background: none;
    }
    .p-inputtext:nth-child(2) {
        color: #3f3b3b !important;
        background: none;
    }

    // .pi-chevron-down:before {
    //     border-top: 5px solid #eaeaea;
    // }

    .mat-form-field.mat-form-field-invalid {
        color: #f44336 !important;
    }

    h6.mte-list-header {
        background-color: #212121 !important;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        border: 1px solid #4e4e4e;
        color: #fff;
    }

    .p-datatable .p-datatable-tbody>tr {
        background: #424242;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
        border: solid 1px #494949;
        background: #373737;
        color: #fff;
    }

    select.translatedrop {
        height: 30px;
        background: #212121;
        color: #fff;
        border-radius: 4px;
    }

    //  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    //     color: #ffffff;
    //     background: #212121;
    // }
    //  .p-multiselect-panel .p-multiselect-header{background: #212121;}
    //  .p-multiselect-panel{background: #424242;}
    //  .p-multiselect-panel .p-multiselect-items .p-multiselect-item{ color: #ffffff !important;}
    //  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight { background: #212121;}
    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #212121;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #f8f9fa;
        background: #f8f9fa;
    }

    // .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message { color: #ffffff;}

    .p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header {
        background: #303030 !important;
    }

    .p-dialog .p-dialog-content {
        background: #424242;
        color: #fff;
        padding-bottom: 0em;
    }

    //expire modal
    .expireModal .p-dialog .p-dialog-header {
        background: #303030 !important;
    }

    .expireModal .p-dialog .p-dialog-footer button {
        margin: 0.5rem 0.5rem 0 0;
    }

    .mat-divider li.list-group-item {
        background-color: rgba(255, 255, 255, 0.1) !important;
        color: #fff !important;
    }

    .mat-divider li.list-group-item:hover {
        background-color: rgba(255, 255, 255, 0.5) !important;
        color: #fff !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:active,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:hover {
        -webkit-box-shadow: 0 0 0 30px rgb(85 85 85) inset !important;
        box-shadow: 0 0 0 30px rgb(85 85 85) inset !important;
    }

    // .p-dialog .p-dialog-header .p-dialog-title{color: #424242;}
    .createpopup .popuporow .col-2 .list ul,
    .createpopup .popuporow .col-3 .list ul {
        background: #424242 !important;
    }

    .createpopup .popuporow .col-1 ul,
    .createpopup .popuporow .col-4 ul {
        background: #424242 !important;
    }

    .createpopup .popuporow .col-1,
    .createpopup .popuporow .col-4 {
        border-left: 1px solid #505050 !important;
    }

    .createpopup .popuporow .col-2,
    .createpopup .popuporow .col-3 {
        border-left: 1px solid #505050 !important;
    }

    .createpopup .subheading {
        background: #ffffff !important;
        color: #424242 !important;
    }

    .createpopup .heading {
        border-bottom: 1px solid #505050 !important;
    }

    //  .mat-vertical-content {
    //     padding:24px;
    // }
    .card .content {
        margin-top: 24px;
    }

    .p-multiselect,
    .p-multiselect-panel,
    .p-dropdown,
    .p-dropdown-panel {
        background: #424242;
    }

    .p-multiselect-panel .p-multiselect-header,
    .p-dropdown-panel .p-dropdown-header {
        background: #212121;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: #ffffff;
        background: #212121;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: #1976d2;
        background: #212121;
    }

    .p-multiselect:not(.p-disabled):hover,
    .p-dropdown:not(.p-disabled):hover {
        border-color: #fff !important;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message,
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
    .createpopup .popuporow li a {
        color: #ffffff;
    }

    //  fury-list .mat-row:hover { background: rgb(66 66 66);}
    fury-list .mat-row:hover label.p-float-label,
    td.mat-column-expandedDetail {
        background: #494949;
    }

    .createpopup .popuporow ul {
        background: rgb(33 33 33 / 10%) !important;
    }

    .createpopup .popuporow .subheading {
        background: #1976d2 !important;
        color: #fff !important;
    }

    //  .createpopup .heading {
    //     background: #1976d2 !important;
    // }

    fury-processing {
        background: #303030;
    }

    .batch-modal .p-dialog-title {
        color: #424242;
    }

    .batch-modal .p-dialog .p-dialog-footer {
        background: #424242;
    }

    .row.batch-dailog-box span.material-icons-outlined {
        color: #fff;
        vertical-align: middle;
    }

    .mat-form-field-label {
        color: rgb(125 128 131);
    }

    .p-multiselect .p-multiselect-label,
    .p-dropdown .p-dropdown-label,
    .mat-input-element {
        color: #fff;
    }


    fury-toolbar .toolbar .toolbar-button {
        border-left-color: rgb(176 175 175 / 8%);
    }

    .createpopup .popuporow ul li a::after {
        color: #fff !important;
    }

    .outline_btn {
        color: #fff;
        background-color: transparent;
        background-image: none;
        border: 1px solid #fff;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        font-family: var(--font-family);
        border-radius: .25rem;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        height: 36px;
        //  margin-top: 5px;
        cursor: pointer;

        &:hover {
            background: #1976d2;
            color: #fff;
            border: 1px solid #1976d2;
            transition: all 0.3s;
        }

    }

    .p-dialog-header .p-dialog-title {
        color: #424242;
    }

    .grid-formui .workflowheadinput label.p-float-label {
        background: #424242;
    }

    td.mat-column-expandedDetail .label-font {
        background: #494949 !important;
        border: none !important;
    }

    .toolbarsetting {
        background: #212121 !important;
    }

    .example-expanded-row .mat-column-processName {
        color: #ffffff !important;
    }

    .ag-theme-alpine .ag-row,
    .ag-body-viewport {
        background: #424242;
    }

    .ag-theme-alpine .ag-row {
        border-color: #4e4e4e;
    }

    .ag-theme-alpine .ag-root-wrapper,
    .ag-theme-alpine .ag-header {
        border-color: #4e4e4e;
    }

    .ag-row-inline-editing.ag-row-editing.ag-row {
        background-color: #424242 !important;
    }

    .ag-cell-value,
    .ag-group-value {
        color: #fff;
    }

    span.agomsvisibility {
        color: #fff !important;
    }

    //   Icons in ag grid & table
    .agvisibility,
    span.agtoggle_off,
    span.agtoggle_on,
    span.agomsvisibility,
    .material-icons-outlined.aggrid_view,
    .material-icons-outlined.agedit_note,
    .material-icons-outlined.viewrowdatavisibility,
    .material-icons-outlined.viewrowfile_download {
        color: #fff;
    }

    td.mat-column-expandedDetail .p-float-label {
        background: #494949;
    }

    // .translatedrop.toolbar-button:hover select {
    //     background: #212121 !important;
    // }

    .example-expanded-row:hover>mat-icon {
        color: blue;
    }

    .example-expanded-row {
        .processname {
            color: #fff !important;
        }
    }

    .example-element-row:hover {
        .processname {
            color: #fff !important;
        }
    }

    .fury-default fury-list .mat-row.example-element-row:hover {
        background: #f8f9fa !important;
    }

    .mat-step-header {
        background: #424242 !important;
    }

    .colorheader {
        background-color: #1976d2 !important;
        color: white !important;
        border-radius: 2px 2px 0px 0px !important;
        box-shadow: 0px 2px 3px #373737;
    }


    .p-fileupload {
        .p-fileupload-buttonbar {
            background: #303030;
        }

        .p-fileupload-content {
            background: #424242;
            color: #fff;

            .p-progressbar {
                background: #424242;
            }
        }
    }

    .p-datatable .p-datatable-thead>tr>th {
        background: #373737;
        color: #fff;
        border: none;
    }

    .p-paginator {
        background: #373737;
        color: #fff;
        border: none;
    }

    .p-datatable .p-datatable-scrollable-header,
    .p-datatable .p-datatable-scrollable-footer {
        background: #424242;
    }

    .p-datatable .p-datatable-thead>tr>th {
        height: 56px;
        background: #373737 !important;
        font-size: 12px;
    }

    .p-datatable-scrollable-header-box {
        background: #212121;
    }

    .ag-theme-alpine .ag-header {
        background: #373737;
    }

    .ag-header-cell-text {
        color: #fff
    }

    .dropdown-bgcolors .p-float-label label.p-float-label {
        background: #424242;
    }

    .dropdown-bgcolors input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #424242 inset !important;
        box-shadow: 0 0 0 30px #424242 inset !important;
    }

    .cardrow .tradcard h2 {
        text-transform: lowercase;
        background-size: 12%;
        background-color: #373737;
        background-repeat: no-repeat;
        background-position: center right;
        background-position-x: 94%;
        color: #fff;
        background-blend-mode: soft-light;
    }

    .cardrow .tradcard:hover h2 {
        -webkit-transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
        -moz-transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
        -o-transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
        transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
        background-size: 15%;
        transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
        color: #fff;
        background-blend-mode: color-dodge;
    }

    .p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
        background: #fff;
    }

    .p-dialog .p-dialog-header .p-dialog-title {

        color: white;
    }

    //   .querycardinner{
    //     background: #2d2d2d;
    //   }
    .pagenot-found {
        background: #303030 !important;
    }

    span.caption.discription_page {
        color: #f4f4f4;
    }

    // span.caption.discription_page:hover {
    //     color: #1d2125;
    // }

    .fury-card {
        min-height: 200px;
    }

    .toolbarsetting.arrow.open {
        background: #1976d2 !important;
    }

    .example-expanded-row:hover {
        background: #373737 !important;
    }

    fury-list .mat-row.example-expanded-row:hover {
        background: rgba(255, 255, 255, 0.04) !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #424242 inset !important;
        box-shadow: 0 0 0 30px #424242 inset !important;
        -webkit-text-fill-color: #fff;
    }

    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    textarea:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #424242 inset !important;
        box-shadow: 0 0 0 30px #424242 inset !important;

        -webkit-text-fill-color: #fff;
    }

    .sign-space input:-webkit-autofill,
    .sign-space input:-webkit-autofill:hover,
    .sign-space input:-webkit-autofill:focus,
    .sign-space input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #424242 inset !important;
        box-shadow: 0 0 0 30px #424242 inset !important;

    }

    .workflowheadinput .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
        top: 2.1em !important;
        left: 0.5em;
    }

    .workflowheadinput .mat-form-field-label-wrapper .label-font {
        padding: 0 0px 0 10px;
    }

    .mat-select-value-text {
        color: #fff;
    }

    .label-font {
        padding: 0 0.75em;
    }

    .mat-input-element {
        padding-left: 0.75em;
    }

    button.blueColor u {
        color: #fff;
    }

    .reportcount .countClass {
        color: white;
    }

    .paginationInput span {
        color: #fff;
    }

    .p-dialog .p-dialog-footer {
        background: #303030 !important;
    }

    .mat-select-value-text {
        color: #aeaeae;
    }

    .label-font {
        color: #aeaeae;
        padding: 0 0.75em;
    }

    .blueColor {
        color: #fff !important;
    }

    .modal-popup .modal-body p,
    .questc,
    .mresc,
    .checkc,
    .commentsec label,
    .countClass {
        color: #fff
    }

    .mcontainer {
        border-bottom: 1px solid #565656 !important;
    }

    svg {

        #Rectangle_685,
        #Rectangle_688,
        #Rectangle_686,
        #Rectangle_687,
        #Rectangle_689,
        #Rectangle_690,
        #Rectangle_691,
        #Path_303,
        #Path_304,
        #Path_305,
        #Path_306,
        #Path_307,
        #Path_308,
        #Path_309,
        #Path_310,
        #Path_311,
        #Path_312,
        #Path_313,
        #Path_314,
        #Path_315 {
            fill: #fff !important
        }
    }

    .executework-flowrun .p-dialog-content mat-label,
    .executework-flowrun span.mat-checkbox-label {
        color: #fff;
    }

    .whitetextcolor {
        color: #fff !important;
    }

    .washsale_popuptext .p-dialog .p-dialog-content {
        background: #424242;
        color: #fff !important;
        padding-bottom: 0em;
    }

    .dwnewsm_card.fury-card {
        padding-left: 0 !important;
    }

    .workflowtree_design .fury-list-table .fury-list-header .fury-filter-input {
        background: none;
    }

    .treetable_text .p-treetable .p-treetable-thead>tr>th {
        background: #373737;
        color: #fff;
    }

    .treetable_text .p-treetable .p-treetable-tbody>tr>td {
        background: #424242;
        color: #fff;
    }

    // OMS Unapproved Order Popup comment Text area color  issue fixed
    .comment_textarea {
        h5 {
            float: left;
            color: #fff;
        }

        textarea {
            overflow-x: auto;
            max-width: 100%;
            width: 100%;
            padding: 4px;
            background: #424242;
            color: #fff;
        }
    }

    // Loader Seamless(Text) backgroung change
    .spinner_text p {
        background: #fff;
    }

    .report-delivery-card .fury-card.basic-forms.route-animations-elements {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    // UI all Input blue border with blue placeholder on focus 
    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
        color: #1976d2;
        border: 2px solid #1976d2 !important;
        border-radius: 5px;
    }

    .mat-focused mat-label.label-font {
        color: #1976d2;
        background: #424242;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
    .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
    .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
        border-width: 0px;
    }

    // Doc Management table thead background issue
    .documentTable .docTabel thead tr th {
        padding: 1em 0.5em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        font-weight: 400;
        text-align: left;
        color: rgba(0, 0, 0, 0.54);
        font-size: 14px;
        background: #212121 !important;
        color: #fff !important;
    }

    // UI all P-multiselect Input blue border with blue placeholder on focus 
    .p-multiselect:not(.p-disabled):hover,
    .p-dropdown:not(.p-disabled):hover {
        border-color: #1976d2 !important;
    }

    .p-float-label input:focus~label,
    .p-float-label input.p-filled~label,
    .p-float-label textarea:focus~label,
    .p-float-label textarea.p-filled~label,
    .p-float-label .p-inputwrapper-focus~label,
    .p-float-label .p-inputwrapper-filled~label {
        background: #494949;
        color: #1976d2 !important;
    }

    .p-multiselect,
    .p-dropdown {
        border: 2px solid #ced4da !important;
        border-radius: 5px;
    }

    .background-pattern {
        background: #303030 !important;
    }

    // Recon Trade manually & Multi Recon-manually input & button hide issue
    .recon-btn {
        display: flex;
        position: relative;
        align-items: flex-end;
        flex-flow: row;
        justify-content: flex-end;
        top: 0.5em;
        margin-bottom: -5em;
        left: 35%;
        width: 64%;
        z-index: 99999;
    }

    .instructionmodal {
        .mat-step-header {
            background: #212121 !important;
            border-radius: 0px;
            border-top: 2px solid #424242;
        }

        .mat-vertical-content-container {
            background: #424242;
        }
    }

    .p-dialog-header-icons .pi-times:before {
        color: #212121;
    }

    .queryreporthead {
        .qrh {
            background: rgb(247 247 247 / 0) !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        }
    }

    .ag-theme-alpine .ag-side-buttons {
        background: #212121;
        color: #fff;
    }

    .ag-theme-alpine .ag-column-select-virtual-list-viewport {
        background: #424242;
        color: #fff;
    }

    .ag-theme-alpine .ag-drag-handle {
        color: #fff;
    }

    .docTabel thead tr th {
        background: #141414 !important;
        color: rgb(255 255 255) !important;
    }

    .docTabel tbody tr:hover {
        background: #424242 !important;
    }

    .tableview_desgin th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
        border: none;
    }

    // END Recon Trade manually & Multi Recon-manually input & button hide issue

    .queryt-custom {
        thead {
            th {
                background: #212121;
                padding: 1em;
                // border: 1px solid rgba(0, 0, 0, 0.08);
                color: #fff;
            }
        }

        .accordionh {
            background: #212121 !important;
        }

        .mainhead>th {
            background: #212121;
            color: #fff;
            border-left: 1px solid #303030;
        }

        .queryt-custom .subheadtotal>th {
            background: #424242 !important;
        }

        .accordion {
            border-bottom: 1px solid rgb(244 244 244 / 20%);
        }

        .submainhead>th {
            background: #424242;
            color: #fff;
            border-bottom: 1px solid #fff;
            ;
        }

        .subhead>th {
            background: #424242;
            color: #ffffff;
        }

        tbody {
            td {
                border: 1px solid #4e4e4e;
                color: #fff;
                background-color: #424242;
            }

        }
    }

    .intromodal .introductionv .heading {
        background: #212121;
    }

    .lookup-table {
        margin: 20px;

        table {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

            thead {
                background: #f5f5f5;

                tr {
                    th {
                        padding: 1rem 2rem;
                        color: rgba(255, 255, 255, 0.7) !important;
                        background: #212121 !important;
                        font-size: 12px !important;
                        font-weight: normal;
                        border-bottom: 1px solid #4e4e4e !important;
                        text-align: left;

                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: .50rem 2rem;
                        border-bottom: 1px solid #4e4e4e !important;
                        color: #fff !important;

                        input {
                            border: solid #4e4e4e !important;
                            color: #212121 !important;
                            border-width: 1px;
                            height: 34px;
                            padding: 0 10px;
                        }

                        span {
                            padding-right: 10px;

                            &.checked {
                                color: green;
                                cursor: pointer;
                            }

                            &.edit {
                                color: #1976d2;
                                cursor: pointer;
                            }

                            &.delete {
                                color: red;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

    }

    app-custom-header h6 {
        color: #fff;
    }

    .ag-theme-alpine .ag-status-bar {
        background: #373737;

        span {
            color: #fff;
        }
    }

    .ag-theme-alpine .ag-paging-panel {
        background: #373737;

        span {
            color: #fff;
        }
    }

    .modal-scd-head h6 {
        background: #303030;

    }

    .padding .mat-form-field-appearance-fill .mat-form-field-flex {
        background: #000;
    }

    .uploadedFile {
        color: #fff !important;
    }

    .p-datatable .p-datatable-tfoot>tr>td {
        background: #1c1c1c;
        border: 1px solid #424242;
        color: #ffffff;
    }

    .p-button.p-button-text:enabled:hover {
        background: rgb(25 118 210);
        color: #ffffff;
        border-color: #1976d2;
    }

    .mat-linkexprot .header-btn button {
        background: #212121 !important;
        height: 41px !important;
        color: #fff;
        position: relative;
        right: -65px;
        top: 0px;
    }

    // START UNIVERSAL ICON MODAL Dark Theme 
    .moodal-view-toolbar {

        .popup-view-toolbar,
        .popup-view-toolbar1,
        .popup-view-toolbar2 {
            border-left: 1px solid #eae6e133 !important;

            h3 {
                color: #fff !important;
                border-bottom: 1px solid #eae6e133 !important;
            }

            ul {
                background-color: #1d2125 !important;

                li {
                    background: #1d2125;
                }

            }
        }
    }

    // .ag-theme-alpine .ag-ltr .ag-cell {
    //     border-right: solid 1px #494949 !important;
    // }
    .fury-filter-input-icon {
        color: #fff !important;

    }

    .select_executebtn {
        .workflow_button {
            background: #1161BF;
        }
    }

    .ag-cell-wrapper {
        .material-icons-outlined {
            color: #fff !important;
        }
    }

    .ag-header-cell {
        border-right: 1px solid #4e4e4e;
    }

    .cardrow .tradcard .bodytext ul li .iconset span:hover {
        color: #f3f3f3;
        box-shadow: 0 0 5px #fff;
    }

    .reportsvgtext path {
        fill: #fff;
    }

    .reportDiv td,
    .reportDiv th,
    .reportDiv td,
    .reportDiv th,
    .reportDiv td,
    .reportDiv th,
    .amtColReport ul li,
    .panel-default>.panel-heading,
    .amtColReport td {
        color: #fff !important;
    }

    .panel-default>.panel-heading,
    .panel-group .panel,
    .panel-default>.panel-heading,
    .panel-default>.panel-heading,
    .reportDiv .table-bordered.check_reports th {
        background-color: #303030 !important;
        box-shadow: none !important;
    }

    .reportDiv tr:nth-child(even) {
        background-color: #303030 !important;
    }

    // START Processing Run data import Workflow Tooltip background color & width manage
    .toolTip-color {
        .ag-tooltip {
            background-color: #ffffff !important;
            color: #000000 !important;
            border-color: #545454;
            border-radius: 3px;
        }
    }

    // END Processing Run data import Workflow Tooltip background color & width
    // START Processing Run data import Workflow Tooltip background color & width manage
    .toolTip-color {
        .ag-tooltip {
            background-color: #ffffff !important;
            color: #000000 !important;
            border-color: #545454;
            border-radius: 3px;
        }
    }

    // END Processing Run data import Workflow Tooltip background color & width
    //START Header Formidium banner update 
    .formidium-img {
        .formidium-default-img {
            display: none;
        }

        .formidium-dark-img {
            display: block !important;
        }
    }

    //END Header Formidium banner update 
    //START 404 Page footer nav and discription text
    .login-footer1.login-footer2 {
        background-color: #eae6e1 !important;

        a {
            color: #646668 !important;
        }
    }

    //END 404 Page footer nav and discription text
    // Fury-dark Rapid css START here 

    .setup-process .step-flow .step-work {
        background: #3a3a3a !important;
    }

    .setup-grid-form-view .formgrid-view .fury-list-table {
        background: #424242 !important;
    }

    .setup-process .mat-vertical-stepper-header {
        border-top: solid 1px #424242;
        border-bottom: solid 1px #545454 !important;
    }

    .setup-process .rapid-field-icon .ac-typelabel {
        background: #424242;
        color: #929292;
        font-weight: normal !important;
    }

    .setup-process .non-custodian-main .mat-vertical-stepper-content {
        overflow: auto;
    }

    .setup-process span.stickyButton.source-detail-editbtn .splitgroupbtn .mat-button-toggle span .mat-menu-item {
        height: 44px;
        line-height: 44px;
    }

    .setup-process .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
        background: #282828;
    }

    .setup-process .rapid-pos-btn .setup-advance-details .mat-step-header {
        background: rgb(40 40 40) !important;
        border-top: solid 1px #282828;
    }

    .setup-process .rapid-pos-btn .setup-advance-details .mat-step-header .mat-step-text-label {
        color: #fff !important;
        font-weight: normal !important;
    }

    .setup-process .rapid-pos-btn .adv-config:after {
        border-bottom: 11px solid #fff;
        font-weight: normal !important;
        border-top: none !important;
    }

    .rapid-pos-btn .adv-config-after:after {
        border-top: 11px solid #fff !important;
    }

    .expansion-heading.selected h3,
    .detail-fields.selected p {
        color: #fff !important;
    }

    span.detail-fields {
        border-top: solid 1px #434343 !important;
    }

    .expansion-heading h3 {
        color: #aeaeae !important;
    }

    .expansion-heading p {
        color: #aeaeae !important;
    }

    span.detail-fields .active-icon {
        color: #ffffff !important;
    }

    span.detail-fields .default-icons {
        color: #aeaeae !important;
    }

    .setup-process .step-flow .step-work p {
        color: #aeaeae !important;
    }

    span.material-icons-outlined.vi-icn {
        color: #ffffff !important;
    }

    .setup-process .selected h1 {
        color: #fff !important;
    }

    span.mat-tooltip-trigger.material-icons-outlined.default-icons.active-icon {
        color: #fff !important;
    }

    .setup-process {
        .step-flow {
            .step-work {
                h1 {
                    color: #aeaeae !important;
                }
            }
        }
    }

    .step-work.selected {
        .content-setup-icon {

            .step-content {
                h1 {
                    color: #ffffff !important;
                }

                p {
                    color: #ffffff !important;
                }
            }

            .iconset.icnset-view {
                .material-icons-outlined.vi-icn {
                    color: #ffffff !important;
                }
            }
        }
    }

    .iconset.icnset-view {
        .material-icons-outlined {
            color: #aeaeae !important;
        }
    }

    .step-flow.user-management-flow {
        .step-work.selected {
            .step-content {
                h1 {
                    color: #ffffff !important;
                }

                p {
                    color: #ffffff !important;
                }
            }
        }
    }

    .setup-process {
        .step-work {
            .mat-accordion {
                .mat-expansion-panel {
                    .mat-expansion-panel-content {
                        .mat-expansion-panel-body {
                            .detail-fields.selected {
                                p {
                                    color: #ffffff !important;
                                }

                                .active-icon {
                                    color: #ffffff !important;
                                }
                            }
                        }
                    }

                    .mat-content {
                        .expansion-heading.selected {
                            p {
                                color: #ffffff !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .rapid-field-icon {
        background: #cccccc94;
    }

    .rapid-field-icon-processing {
        padding: 0em 1em 1em 1em;
        width: 98.5%;
        margin-left: 3px;

        .ac-typelabel {
            background: #424242;
        }
    }

    .setup-process .default-grid .view-data-collection .card {
        z-index: 9;
    }


    .warningm span {
        color: #fff;
    }


    .reportDiv {
        .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
            box-shadow: 1px 1px 5px 1px #706f6f;
            background: #353434 !important;
            color: #fff !important;
            border: none;
        }

        .p-accordion .p-accordion-content {
            background: #4b4a49;
        }
    }

    .workflow-headerui .mat-header-cell {
        color: #fff;
    }

    // FS Report Dark theme start 
    .fs-btn {

        .ui-text,
        .mat-icon {
            color: #000;
        }

        .mat-raised-button {
            background: #fff;
        }

        .mat-raised-button.mat-button-disabled {
            background-color: #fff !important;

            .ui-text {
                color: #000;
            }
        }
    }

    .fs-note-list {
        .fs-accordian-header {
            background: #212121 !important;
        }

        .note-list {
            background: #212121 !important;
        }

        .submodules .note-list {
            background: #373737 !important;
        }
    }

    .fs-accordian-header {
        background: #212121 !important;
    }

    .note-list {
        background: #212121 !important;
    }

    .submodules .note-list {
        background: #373737 !important;
    }

    // End FS 
    .expand-userentry.gridcard-header .fury-card {
        border-radius: 0 !important;
    }

    .workflow-headerui {
        .workflownew {
            .mat-form-field-appearance-outline {
                .mat-form-field-flex {
                    margin-top: 17px;
                }
            }
        }
    }

    .inputupload-brose-section .p-fileupload .p-fileupload-content {
        background: #828282;
        border: 2px dashed #424242;
        border-radius: 0;
    }

    fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header {
        .mat-vertical-stepper-header {
            .mat-step-text-label {
                color: #fff;
            }
        }

        .mat-vertical-stepper-header {
            background-color: #282828 !important;
        }
    }

    .setup-process {
        .mat-step {
            .trade-view-input:first-child {
                .mat-step-header {
                    background-color: #282828 !important;
                }
            }
        }
    }

    .p-fileupload.p-fileupload-advanced h3 {
        color: #2b2b2b;
    }

    app-grid.oms-fullwidth fury-page-layout .fury-page-layout-content {
        border-top: 1px solid #373737 !important;
    }

    app-grid-filter.oms-fullwidth fury-page-layout.input-card .fury-page-layout-content {
        border-top: 1px solid #373737;
    }

    .grid-input-viewform .ag-theme-alpine .ag-root-wrapper {
        border: 1px solid #424242;
        border-left: 1px #424242 !important;
        border-right: 1px #424242 !important;
    }

    .inputupload-brose-section {
        .p-fileupload .p-fileupload-buttonbar .p-button {
            border: solid 2px #424242;
            border-radius: 5px;
            background: transparent;
            color: #424242;
            font-weight: 500;
        }
    }

    .non-custodian-main.default_sourcedetialtab .non-custodian-form .mat-step-header[aria-selected="false"] {
        background-color: #282828 !important;
    }

    .non-custodian-main.default_sourcedetialtab .non-custodian-form .mat-step-header[aria-selected="false"] .mat-step-text-label {
        color: #fff;
    }

    .non-custodian-main.active_sourcedetialtab .non-custodian-form .mat-step-header[aria-selected="true"] {
        background-color: #282828 !important;
    }

    .non-custodian-main.active_sourcedetialtab .non-custodian-form .mat-step-header[aria-selected="true"] .mat-step-text-label {
        color: #fff;
    }

    .fetch-market-datatui-option .mat-step-text-label {
        color: #fff !important;
    }

    // .fetch-market-datatui-option .mat-step:first-child .mat-step-header {
    //     background: #282828 !important;
    // }
    .fetch-market-datatui-option .mat-step .mat-step-header[aria-selected=true] {
        background: #282828 !important;
        border-radius: 0px;
    }

    .fetch-market-datatui-option .mat-step .mat-step-header[aria-selected=false] {
        background: #282828 !important;
        border-radius: 0px;
    }

    fury-card.fury-card.basic-forms.route-animations-elements.workflow-treesubheader {
        border-radius: 0px;
    }

    .toolbar-user .button .avatar {
        border: none !important;
    }

    .content.input-trade-upload .mat-form-field-label {
        padding: 4px 0px !important;
        background: none;
    }

    .is-distribution-radio {
        background: none !important;
    }

    .well {
        background: none;
        border-radius: 0px;
    }

    .default-grid .rapid-field-icon-processing-account .ac-typelabel {
        background: #1d2125 !important;
    }

    .default-grid .rapid-field-icon-processing-account {
        border: solid 1px #fff;
        border-radius: 0px;
    }

    .setup-process span.crypto-marketdata-form {
        .mat-raised-button {
            color: #000;
        }
    }

    .workflowheadinput {
        .mat-form-field-appearance-outline {
            .mat-form-field-flex {
                margin-top: 0px !important;
            }
        }

        span {
            label.p-float-label {
                background: #1d2125 !important;
            }
        }
    }

    .newmodalui-create {
        .moodal-view-toolbar {
            .popup-view-toolbar {
                border-left: solid 1px #eae6e1;

                .investor-content-view,
                .trade-content-view,
                .portfolio-content-view,
                .accounting-content-view {
                    ul {
                        background-color: #1d2125;
                    }
                }

                .setup-content-view,
                .usermanagement-content-view,
                .customization-content-view {
                    ul {
                        background-color: #1d2125;
                    }
                }

            }

            .popup-view-toolbar1 {
                border-left: solid 1px #eae6e1;

                .setup-content-view {
                    ul {
                        background-color: #1d2125;
                    }
                }
            }

            .popup-view-toolbar2 {
                border-left: solid 1px #eae6e1;

                .setup-content-view {
                    ul {
                        background-color: #1d2125;
                    }
                }
            }

            .heading {
                h3 {
                    color: #fff;
                    border-bottom: solid 1px #eae6e1;
                }
            }
        }
    }

    .newmodalui-view {
        .moodal-view-toolbar {
            .popup-view-toolbar {
                border-left: solid 1px #eae6e1;

                .setup-content-view {
                    ul {
                        background-color: #1d2125;
                    }
                }
            }

            .popup-view-toolbar1 {
                border-left: solid 1px #eae6e1;

                .setup-content-view {
                    ul {
                        background-color: #1d2125;
                    }
                }
            }

            .popup-view-toolbar2 {
                border-left: solid 1px #eae6e1;

                .setup-content-view {
                    ul {
                        background-color: #1d2125;
                    }
                }
            }

            .heading {
                h3 {
                    color: #fff;
                    border-bottom: solid 1px #eae6e1;
                }
            }
        }
    }

    .dropdown.viewpop.popupvw.newmodalui-create.open {
        top: 52px !important;
    }

    fury-toolbar-user .dropdown {
        border-radius: 0px !important;
    }

    .translatedrop.toolbar-button:hover select {
        background: #646668 !important;
    }

    // See Rapid All Options Modal design 
    .mainmodal-cardui {

        .newmodalui-view-modal,
        .newmodalui-view-modal1,
        .newmodalui-view-modal2 {
            .inputmodal {
                background: #1d2125;
                box-shadow: 0 2px 5px 1px #403c4329;
                border: 1px solid #eae6e133;

                ul {
                    background: #1d2125;

                    li {
                        a {
                            color: #1d2125;

                            &:hover {
                                color: #1a73f1;
                            }
                        }

                        &:hover {
                            background: #ee8434;
                            color: #fff;
                            cursor: pointer;

                            a {
                                color: #fff;
                            }
                        }
                    }

                    h3 {
                        border-bottom: solid 1px #646668;
                        color: #fff;
                    }
                }
            }
        }
    }

    .setup-process span .mat-raised-button span {
        color: #1d2125 !important;
    }

    .p-header-wrapper .p-dialog-header-icon {
        background-color: #1976d2 !important;
    }

    fury-toolbar-training-videos li {
        background: #212121 !important;
    }

    .cuvcontrol caption {
        background: #212939;
        border-top: 1px solid rgb(50 58 75);
    }

    .hrefdochead {
        img.defaulttheme {
            display: none !important;
        }

        img.darktheme {
            display: flex !important;
        }
    }

    //fs-grid css
    .fs-grid {
        background-color: #303030;

        .multi-form-heading {
            background-color: #303030;
        }
    }

    // START API-Documentation
    fury-api-reference {
        .api-bg {
            background: #424242;
        }

        .createpopup ul li {
            background: #424242 !important;
        }

        .header h4 {
            color: #fff !important;
        }

        .method-example-response-title {
            color: #1d2125 !important;
        }

        span {
            color: #fff;
        }

        .method-example-response-title {
            color: #1d2125 !important;
        }

        pre.code_response {

            color: #1d2125;
        }

        .number-line,
        .key,
        .number,
        .string,
        .boolean,
        .null {
            color: #1d2125;
        }

        .json-schema-view span {
            color: #fff !important;
        }

        .api-listurl {
            color: #fff !important;
        }

        tr:nth-child(even) {
            background: #424242 !important;
        }

        th {
            background: #424242 !important;
        }

        button.tabContainer {
            color: #fff;
        }

        .errorCodes ul li a {
            color: #fff !important;
        }

        .createpopup .subheading {
            border-bottom: none !important;
        }

        .container button.active {
            border: 1px solid #ffffff !important;
        }
    }

    // END API-Documentation

    //  START MFA Design 
    body:has(#mfaCard),
    body:has(#gaCard) {
        .mfaModal {
            .mfaModalContent {
                color: #fff !important;

                p,
                strong {
                    color: #fff !important;
                }
            }
        }

        .MFAfontstyle {

            .text-grey p,
            p,
            strong {
                color: #fff !important;
            }
        }
    }

    .dashboard-chart {
        box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%) !important;
        border-radius: 8px;
        padding: 15px !important;
        background-color: #fff;
    }

    .card-header-income {
        font-size: 16px;
        padding: 15px 0px 9px 15px;
        color: #fff !important;
    }

    .label-font {
        color: #1976d2;
    }

    .expense-label {
        color: #fff !important;
    }

    .expense-percentage {
        color: #fff !important;
    }

    .managementfee-dark {
        background-color: #303030 !important;
        color: #fff !important;
        box-shadow: none !important;
        padding: 5px;
        align-items: center;
    }

    .chartname {
        background-color: #303030 !important;
    }

    // AUM, Financial,Crypto Dashboard

    .aum-card-monthly,
    .aum-card-income {
        border: 1px solid #6d7377 !important;
    }
    .crypto-dashboard {
        .column {
            background-color: #303030 !important;
        }

        .net-Income,
        .donutName,
        .cash-balance,
        .metric-value,
        .vamiText,
        .metric-label,
        .GL-vol-heading {
            color: #fff !important;
        }

    }

    .finan-dash1 {
        .assets-value {
            color: #fff !important;
        }
    }
}

body.fury-dark:has(fury-financial-dashboard) .content-inner {
    background-color: #303030 !important;
}

// END MFA Design
//START cryptobackoffice logo
.sudrania-logo.default.cryptologo {
    display: block !important;
}

//END cryptobackoffice



// Fury-dark Rapid css END here 

// Fury-light css start here - put the code above for light theme without using .fury-light

.fury-light {

    .toolbarsetting {
        background: #fafafa !important;
    }

    .sio,
    .sro {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.87);
        border: 1px solid rgba(0, 0, 0, 0.87);
    }

    .setting_faq.setting_faq_top .toolbarsetting.arrow.open:after {

        border-top: 8px solid #1976d2;
        transform: rotate(0deg);
        position: absolute;
        left: 4.5em
    }

    .toolbarsetting.arrow.open {
        background: #1976d2 !important;
    }

    .setting_faq.setting_faq_top {
        color: #212939;

        .toolbarsetting {
            background: #fff !important;
        }
    }

    .setting_faq.setting_faq_top fury-toolbar-setting.toolbar-button {
        background: #fff;
    }

    .toolbarsetting.arrow.open {
        background: #1976d2 !important;
        color: #fff !important;
    }





    // Fury-light css end here - put the code above for light theme without using .fury-light
}






// Fury-flat css starts here - put the code above for flat theme without using .fury-flat


.fury-flat {

    .toolbarsetting {
        background: #fafafa !important;
    }

    .setting_faq.setting_faq_top fury-toolbar-setting.toolbar-button {
        background: #fafafa;
    }

    .setting_faq.setting_faq_top .toolbarsetting.arrow.open:after {

        border-top: 8px solid #1976d2;
        transform: rotate(0deg);
        position: absolute;
        left: 4.5em
    }

    .toolbarsetting.arrow.open {
        background: #1976d2 !important;
    }

    .setting_faq.setting_faq_top {
        color: rgba(0, 0, 0, 0.87);

        .toolbarsetting {
            background: #fafafa !important;
        }
    }

    .sio,
    .sro {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.87);
        border: 1px solid rgba(0, 0, 0, 0.87);

    }

    .setting_faq {
        background-color: #fafafa !important;
    }

    .setting_faq_top .toolbar-button .mat-button.mat-button-base.button.toolbarsetting.arrow {
        height: 56px;
    }

    .toolbarsetting.arrow.open {
        background: #1976d2 !important;
        color: #fff !important;
    }

    // Fury-flat css end here - put the code above for flat theme without using .fury-flat
}