@media (min-width: 1200px) and (max-width: 1440px) {
    .setup-process {
        .step-flow {
            margin: 40px 10px 0px 75px !important;
            width: 20.5em !important;

            .step-work {
                width: 252px !important;
            } 
        }
        .setup-progress {
            .progress-bar {
                margin-left: 0px !important;
            }
        }
        .mat-stepper-vertical{
            width: 535px !important;
        }
        .setup-grid-form-view{
            margin-top: 40px !important;
            .rapid-field-icon{
                .sm-logo{
                    margin-right: 22% !important;
                }
                .sr-logo{
                    margin-right: 22% !important;
                }               
            } 
            .formgrid-view {
                .expend-view {
                    position: relative;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: -1.6em;
                    margin-bottom: 0.2em;
                    margin-right: 25px;
                    float: right;
                }

                .collapse-view {
                    position: relative;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: -1.6em;
                    margin-bottom: 0.2em;
                    float: right;
                    // img{
                    //     margin-right: 0.5vw !important;
                    // }
                }
            }
        }

        .setup-progress {
            margin-top: 3.35em !important;
        }

        .setup-progress.setup-grid-form-view.setup-gridview {
            margin-top: 40px !important;
        }

        .rapid-expend-grid {
            position: absolute;
            width: 100%;
            left: 0;

            .fury-list-table {
                width: 98% !important;
                float: right !important;
                background: #fff !important;
            }
        }

        .fury-list-table {
            .fury-list-header {
                .fury-list-name {
                    white-space: normal;
                }
            }
        }

        .setup-gridview .expend-view {
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin-top: -1.6em;
            margin-bottom: 0.2em;
            margin-right: 20px;
            float: right;
        }

        .setup-gridview .collapse-view {
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin-top: -1.6em;
            margin-bottom: 0.2em;
            float: right;
        }
    }

    .expand-viewfund-spc {
        .rapid-expend-grid {
            .fury-page-layout-content.grid-spcui {
                padding-right: 10px !important;
            }
        }
    }
    .rapid-pos-btn {
        .notesandcomments {
            .text-area {
                width: 95.4%!important;
                margin-left: 17px !important;
            }
        }
    }


    .setup-form {
        .setup-success-screen {
            margin: 145px !important;
            width: 290px !important;
            text-align: center;
        }
    }

    .setup-gridview {
        margin-top: 40px !important;

        .setup-form {
            .grid-spcui {
                .fury-list-table {
                    // width: 42em !important;
                    background: #fff !important;
                }
            }
        }
        .progress-bar {
            margin-left: -15px !important;

            svg {
                width: 140px;
                height: 140px;
            }
        }
        .setGridHeight {
            height: calc(84vh - 6em - 46px)!important;
        }

        .ag-header-icon.ag-header-cell-menu-button {
            padding: 0.5em 4% 0.5em 96% !important;
        }

        .ag-theme-alpine {
            .ag-status-name-value {
                padding-top: 6px!important;
                padding-bottom: 6px!important;
            }
        }

        .iconGroup {
            margin: 0.6em 1rem !important;
        }
    }
    .step-flow.user-management-flow {
        margin-left: 50px !important;
    }
    .setup-form.formgrid-view .grid-spcui .fury-list-table {
        width: 100% !important;
    }
    .user-mang-expendgrid.rapid-expend-grid{
        width: 99% !important;
        left: 24px !important;
    }
    .customiz-expendgrid.rapid-expend-grid{
        width: 96.5%!important;
        left: 24px!important;
    }
    .edit-window-btn span.ui-text.advinfo-btn{
        margin-left: 0em;
        padding: 0em !important;
    }
    .userentrybtn-center {
        width: 22vw !important;
        margin: auto;
    }
    .fury-list-table {
        .fury-list-header {
            .fury-list-name {
                font-size: 1.2em !important;
                padding-right: 10px !important;
            }
        }
    }
    .rapid-field-icon-processing{
        width: 97.4% !important;
    }
    
    // toggle css 1366px start
    .default-grid .french_gridheader .custodian_theme_btn {
        right: 13.9em !important;
        top: 6.7em !important;
      }
      .default-grid .english_gridheader .custodian_theme_btn {
        right: 13.9em !important;
        top: 6.7em !important;
      }
      .default-grid .english_gridheader .setup_done.custodian_theme_btn {
        right: 4.9em !important;
        top: 6.8em;
      }
      .default-grid .french_gridheader .setup_done.custodian_theme_btn {
        right: 4.9em !important;
        top: 6.8em;
      }
      
      .rapid-expend-grid .french_gridheader .custodian_theme_btn {
        right: 4.9em !important;
        top: -0.6em;
      }
      .rapid-expend-grid .english_gridheader .custodian_theme_btn {
        right: 4.9em !important;
        top: -0.6em;
      }
      .rapid-expend-grid .english_gridheader .setup_done.custodian_theme_btn {
        right: 4.6em !important;
        top: -0.6em;
      }
      .rapid-expend-grid .french_gridheader .setup_done.custodian_theme_btn {
        right: 4.6em !important;
        top: -0.6em;
      }

     // toggle css 1366px end
     .nontrade-addremove-alignicon {
        width: 98.7% !important;
      }
      fury-fs-config-entry {
        .fsCard {
            .setup-process .step-flow .step-work {
                width: 174px !important;
            }
        }
    }
}

@media (min-width: 1024.98px) and (max-width: 1200px) {
    .step-work {
        &:last-child {
            .deactive-view {
                &::after {
                    opacity: 0;
                }
            }
        }
    }

}

@media (min-width: 767.98px) and (max-width: 1024px) {
    .setup-process {
        display: block !important;

        .step-flow {
            border-right: none !important;
            display: flex;
            overflow: scroll;
            width: 95% !important;
            margin: 30px 0 30px 24px !important;

            .step-work {
                width: 288px !important;
                height: 200px !important;
                margin-left: 35px;

                .deactive-view {
                    &:after {
                        transform: rotate(-90deg);
                        bottom: 15px !important;
                        left: 46px !important;
                    }
                }

                .act-view {
                    margin-left: 0px !important;

                    &:after {
                        transform: rotate(-90deg);
                        bottom: 15px !important;
                        left: 46px !important;
                    }
                }

                &:last-child {
                    .deactive-view {
                        &::after {
                            opacity: 0;
                        }
                    }
                }
            }
        }

        .mat-stepper-vertical {
            width: 550px !important;
        }

        .mat-vertical-content span.stickyButton .button {
            display: block;
            bottom: 0% !important;
            position: relative !important;
            right: 2.5em !important;
            left: 0em !important;
        }

        span .mat-raised-button {
            left: 0% !important;
        }
    }
}


@media (max-width:480px) {
    .setup-process {
        display: block !important;

        .step-flow {
            width: 88% !important;
            margin: 30px 0 30px 24px !important;
            border-right:none !important;

            .step-work {
                margin-left: 40px;

                &:last-child {
                    .deactive-view {
                        &::after {
                            opacity: 0;
                        }
                    }
                }

                .act-view {
                    margin-left: 0px !important;
                }
            }
        }

        .setup-progress {
            display: block !important;

            .setup-form {
                .rapid-pos-btn {
                    margin-right: 0px !important;
                }

                span {
                    .mat-raised-button {
                        left: 0% !important;
                    }
                }
            }

            .progress-bar {
                width: 100%;
                text-align: center;
                margin: 0 0 20px 0px !important;
            }
        }

        .splitgroupbtn .splitbtn .mat-icon.notranslate.material-icons-outlined {
            top: -4px !important;
        }
    }

    .setup-process .mat-vertical-content span.stickyButton {
        right: 0em !important;
        display: block;
        bottom: 0% !important;
        position: relative !important;
    }

    .setup-process span .mat-raised-button {
        left: 0% !important;
    }

}

@media (min-width: 1200px) and (max-width: 1440px)
{
    .step-content h1{ font-size: 12px!important;}
   
    body:has(.done-view) .step-content{
        width: 100% !important; max-width: 160px !important; 
}
  body:has(.done-view) .icnset-view, body:has(.act-view) .icnset-view{
    h1{    font-size: 12px;}
        width: 100% !important; max-width: 54px !important;  
}
    }
