

.view-data-collection.rapid-pos-btn .fury-page-layout-card {
    padding-bottom: 0px !important;
}

.rapid-expend-grid {
    .view-data-collection {
        .card {
            width: 98% !important;
            margin-left: 44px !important;
            border-radius: 0px !important;
        }
    }
}

.warningm span {
    color: #000;
}

.processing-status .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    width: 50em !important;
}

.processing-status .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable .p-dialog-content {
    padding: 0em !important;
}

.processing-status .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable .p-dialog-content th {
    text-align: left !important;
    background: #f5f5f5;
}

.setup-process .workflow-header {
    padding: 16px 25px !important;
    border-radius: 0 !important;
    background: #1976d2;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    z-index: 100;
    position: relative;
}

.workflow-headerui {
    .workflownew {
        padding-top: 20px;
        margin-top: 0px;

        .grid-formui {
            .col-lg-3.workflowheadinput {
                padding-left: 0px;
                padding-right: 15px;
            }
        }
    }

    .fury-list-name {
        display: none;
    }

    .mat-header-cell {
        color: #000;
    }

    .p-multiselect, .p-dropdown {
        border-radius: 5px;
        height: 40px;
    }

    .mat-form-field-flex {
        height: 43.3px;
    }

    .mat-table {
        z-index: 10;
    }
}

.rapid-expend-grid {
    .workflow-headerui {
        .fury-list-table {
            width: 100% !important;

            .fury-list-header {
                height: auto;
            }
        }
    }
}

.workflow-headerui {
    .fury-list-table {
        .fury-list-header {
            height: auto;
        }
    }
}


.workflow-headerui {
    .workflownew {
        .workflowheadinput {
            .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float {
                .mat-form-field-label {
                    top: 1.4em !important;
                }
            }
        }
    }
}

.collapsedrow-ui {
    .example-element-detail {
        .actioncontainer {
            padding: 30px 0 0px 0px !important;
        }

        .formcontainer {
            padding: 30px 0 0px 0px !important;
        }
    }
}


.workflow-headerui {
    .actioncontainer {
        .innerExportAlign {
            margin-top: 5px !important;
        }

        span {
            font-size: 24px !important;
            padding-right: 0px !important;

            .mat-icon {
                font-size: 24px !important;
                padding-left: 10px !important;
            }
        }
    }
}

.process_lebel {
    display: inline-flex;
    width: 100%;

    .process_item {
        width: 50%;
    }

    mat-label {
        padding-left: 10px;

        vertical-align: super;
    }
}

.brokerpad {
    padding: 0px 9px 0px 4px !important;
}

.pl-2.pt-2.pb-2.formcheck-box.col-lg-3.workflow-check {
    padding-left: 0px !important;
}

.workflow-headerui {
    .fury-list-table {
        .mat-header-cell {
            padding-left: 21px !important;
        }
    }
}

.workflow-headerui {
    .fury-list-table {
        th.mat-header-cell {
            font-size: 14px !important;
        }
    }
}

.workflow-headerui {
    .fury-list-table {
        th.mat-header-cell:first-of-type {
            padding-left: 28px !important;
        }
    }
}

.setup-grid-form-view .formgrid-view .collapse-view {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: -1.6em;
    margin-bottom: 0.2em; 
    float: right;
}

.fury-default .expand-userentry.gridcard-header .fury-card {
    border-radius: 0px !important;
    box-shadow: none;
}

.workflow-headerui {
    button.mat-focus-indicator.mat-icon-button {
        margin-top: 3px;
    }
}

.input-excutefund.pt-04 {
    .mat-form-field-flex {
        height: 47px;
    }
}

.expand-userentry.gridcard-header {
    .grid-spcui {
        .setGridHeight {
            height: calc(79vh - 5em - 65px) !important;
            width: 100%;
        }
    }
}

.fury-page-layout-content.expand-userentry.gridcard-header.grid-scroll-processing {
    .fury-list-table {
        .ag-center-cols-viewport {
            overflow: inherit;
        }
    }
}

.autoprocess-download-btn {
    img {
        width: 30px;
        margin-right: 10px;
        cursor: pointer;
    }
}

p-header.processing-refresh-btn button span.mat-button-wrapper mat-icon.mat-icon {
    vertical-align: top;
    padding: 0px;
    line-height: 13px;
    font-size: 20px !important;
    display: inline;
}

.default-grid fury-page-layout-content.fury-page-layout-content.pr-1 {
    padding-right: 15px !important;
}

.setup-process span {
    .mat-raised-button.french_nofreebtn {
        right: 55% !important;
        width: auto;
    }
}

.default-grid {
    .fury-list-name.french_gridheader {
        white-space: pre-wrap !important;
    }
}

.hide-advance {
    display: none !important;
}

.show-advance {
    display: block !important;
}

fury-page-layout-content.fury-page-layout-content.trade-view-input {
    padding-left: 0px;
    padding-right: 0px;

    .card.trade-view-card {
        box-shadow: none !important;
        margin-bottom: 0px !important;
        width: 100% !important;
    }
}
fury-page-layout-content.fury-page-layout-content .tableview_desgin.trade-view-input {
    padding-left: 0px;
    padding-right: 0px;

    .card.trade-view-card {
        box-shadow: none !important;
        margin-bottom: 0px !important;
        width: 100% !important;
    }
}
.trade-view-input {
    .mat-vertical-content {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
    }

    .fury-page-layout-content {
        padding-left: 0px !important;
        padding-right: 0px !important;
        border-top: solid 1px #f1f1f1;
    }
}

.setup-process .default-grid .mat-step .trade-view-input:first-child .mat-step-header {
    background-color: #eae6e1;
    line-height: 44px;
}
.setup-process .rapid-expend-grid .mat-step .trade-view-input:first-child .mat-step-header {
    background-color: #eae6e1;
    line-height: 44px;
    margin-top: 7px;
}

.rapid-pos-btn .trade-view-input .splitgroupbtn .mat-button-toggle span .ui-text {
    color: #ffffff;
    font-weight: 500 !important;
}
.rapid-pos-btn .trade-view-input .splitgroupbtn .mat-button-toggle span .ui-text.txt-upr-case {
    padding: 11px;
}
.rapid-pos-btn .trade-view-input .splitgroupbtn .mat-button-toggle {
    background-color: #1976d2;
}

.setup-process .trade-view-input .splitgroupbtn .mat-button-toggle {
    margin-top: -5px !important;
}

fury-page-layout.fury-page-layout.input-card.fury-page-layout-card {
    padding-bottom: 0px !important;
    //border-top: solid 1px #f2f2f2;
}

.card.grid-input-viewform {
    .mat-vertical-content {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px;
    }
}

.rapid-expend-grid .view-data-collection .card.grid-input-viewform {
    margin-top: 0px;
}

.rapid-expend-grid .view-data-collection .card.trade-view-card {
    width: 100% !important;
    margin-left: 24px !important;
}

.setup-process .rapid-expend-grid .input-card .fury-list-table {
    width: 100% !important;
}

.rapid-pos-btn.trade-list-input .input-card .splitgroupbtn {
    height: 36px;
    margin-top: 0px;
}
.card.grid-input-viewform .input-card .actions.btn-cal {
    margin-top: 0;
    right: 0;
    top: -3.9em;
    position: inherit;

    .icon.mat-icon-button.mat-button-base {
        right: 0px;
    }
}
.input-card .grid-input-viewform .actions.btn-cal {
    margin-top: 0;
    right: 0;
    top: -3.9em;
    position: inherit;

    .icon.mat-icon-button.mat-button-base {
        right: 0px;
    }
}

app-grid-filter.oms-fullwidth fury-page-layout.input-card .fury-page-layout-content {
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-top: solid 1px #f1f1f1;
}

app-grid.oms-fullwidth fury-page-layout .fury-page-layout-content {
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-top: 1px solid #f2f2f2 !important;
}

.content.input-trade-upload {
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;

    .mat-form-field-label {
        padding: 4px 10px !important;
        background: #fff;
        top: 20px !important;
    }

    .browse_section {
        padding-left: 0.3em;
    }
}

fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header {
    background-color: #f3f8fd;

    .mat-step-text-label {
        color: #000;
    }
}

.inputupload-brose-section {
    .p-fileupload {
        display: flex;
        justify-content: center;

        .p-fileupload-buttonbar {
            background: none;
            border: none;
            position: absolute;
            z-index: 111111;
            bottom: 30px;
        }

        .p-fileupload-content {
            background: #fafafa;
            border: 2px dashed #cdcdcd;
            border-radius: 0;
            text-align: center;
            padding: 1rem !important;
            width: 100%;
            min-height: 230px;

            img {
                width: 80px;
                margin: auto;
                //cursor: pointer;
            }

            .p-progressbar {
                height: 0px;
            }

            .p-fileupload-files {
                .p-fileupload-row {
                    display: none;
                }
            }

            h3 {
                font-weight: normal;
            }
        }
    }

    .p-fileupload .p-fileupload-buttonbar .p-button {
        border: solid 2px #2196f3;
        border-radius: 5px;
        background: transparent;
        color: #2196f3;
        font-weight: 500;

        .p-button-icon-left {
            display: none;
        }
    }

    .upload-filename {
        position: absolute;
        left: 0%;
        bottom: 14px;
        width: 100%;
    }
}

mat-vertical-stepper.trade-view-header .mat-step-text-label {
    label {
        display: none;
    }
}

.trade-list-input button.mat-focus-indicator.mat-menu-trigger.options.icon.mat-icon-button.mat-button-base {
    margin-right: 0px !important;
}

.setup-grid-form-view .formgrid-view .trade-list-input .setGridHeight {
    height: calc(83vh - 14em - 76px);
}

.content.input-trade-upload.input-space-upload {
    margin-top: 24px !important;
    display: block !important;
}

.content.input-trade-upload.input-space-upload mat-form-field.mat-form-field {
    width: 50%;
}

mat-form-field.mat-form-field.inputlft-spacecient {
    padding-left: 10px;
}

input.fileupload-ui::file-selector-button {
    color: #fff;
    background: none;
    border: solid 1px #fff !important;
    display: none;
}

input.fileupload-ui {
    //position: absolute;
    padding: 4em 0em 0em 0em;
    left: 0em;
    bottom: 0;
    width: 100%;
    text-align-last: center;
}

.mat-vertical-stepper-content .content.rapidinput-tradelist-input {
    padding: 0px 20px !important;
}

.input-numbers.card-btn-view .input-tradelist-category {
    padding-left: 20px;
}

.input-numbers.card-btn-view .input-tradelist-sub_category {
    padding-right: 20px;
}

// .default-grid .view-data-collection .card.grid-input-viewform {
//     margin-left: 30px;
// }

.trpidupload-card-inputspace .input-numbers.card-btn-view .input-tradelist-category {
    padding-left: 0px;
}

.trpidupload-card-inputspace .input-numbers.card-btn-view .input-tradelist-sub_category {
    padding-right: 0px;
}

.setup-process .trade-view-header .mat-step:first-child .mat-step-header {
    padding: 16px 30px !important;
}

.step-work.rapid-input-setupa {
    .step-content {
        width: 100% !important;
        padding: 0 10px !important;
    }
}

.processing-automatedinputui {
    .input-numbers.card-btn-view {
        .mat-form-field-appearance-outline {
            .mat-form-field-wrapper {
                margin-right: 0px;
            }
        }
    }
}

.default-grid .setup-advance-details .mat-stepper-vertical-line:before {
    top: 0px;
}

.processing-automatedinputui {
    .input-numbers.card-btn-view {
        .grid-formui {
            .ml-2 {
                padding-top: 0px !important;
            }

            .ml-3 {
                padding-left: 5px;
            }
        }

        .input-excutefund {
            margin-bottom: 0px;

            .mat-form-field-wrapper {
                padding-bottom: 15px !important;
            }
        }
    }
}

mat-vertical-stepper.autocursor .mat-step-header {
    cursor: auto;
}

.content.adv-content.trpidupload-card-inputspace.mrg-0 {
    margin-top: 0px;
}

.m-b-1 {
    margin-bottom: 1px !important;
}

.non-custodian-main.default_sourcedetialtab .non-custodian-form .mat-step-header[aria-selected="false"] {
    background-color: #f9f9f9;
}

.non-custodian-main.default_sourcedetialtab .non-custodian-form .mat-step-header[aria-selected="false"] .mat-step-text-label {
    color: #000;
}

.non-custodian-main.active_sourcedetialtab .non-custodian-form .mat-step-header[aria-selected="true"] {
    background-color: #f3f8fd;
}

.non-custodian-main.active_sourcedetialtab .non-custodian-form .mat-step-header[aria-selected="true"] .mat-step-text-label {
    color: #000;
}

.exchange-rateupload {
    width: 100%;
    display: flex;
    vertical-align: middle;
    align-items: baseline;
}

.exchange-rateupload .carry_weekendbox {
    margin-left: 20px;
}

.edit-window-btn {
    .fetchexchange-header {
        span.ui-text {
            margin-left: 0em;
        }
    }
}

.fetchexchange-header {
    .splitgroupbtn {
        .splitbtn {
            .mat-icon.notranslate.material-icons-outlined {
                color: #000;
            }
        }
    }
}

.edit-window-btn {
    .fetchexchange-header {
        span.ui-text {
            padding: 0.5em 1em 0.5em 1em !important;
        }
    }
}

button.mat-tooltip-trigger.primary.achive-icon {
    margin-right: 0.5em;
}


.mat-menu-panel.cronjobbtn {
    right: 174px;
}

.mat-menu-panel.fetch_marketdata_headerdropdown {
    width: auto !important;
    top: -3px;
    right: 99px;

}

.fetch-market-datatui {
    display: initial !important;

    .mat-vertical-content {
        //margin-top: 24px;
        padding-left: 0px;
        padding-bottom: 0px;
        padding-right: 0px !important;
    }

    .splitgroupbtn {
        .mat-button-toggle {
            background-color: #fff;
            width: auto;

            span.ui-text {
                color: #000;
                margin-left: 0px;
                text-transform: capitalize;
                font-weight: 500 !important;
                padding: 0.5em 1.7em !important;
            }
        }
    }

    .notranslate.material-icons-outlined {
        color: #000;
        top: 10px !important;
    }

    .mat-step-text-label {
        color: #000;
        font-weight: 500 !important;
    }
}

.gridsetup-modal-view .fetch-market-datatui .mat-step:first-child .mat-step-header .mat-step-text-label {
    padding-right: 0px;
}

.setup-process .fetch-market-datatui span.stickyButton .splitgroupbtn {
    right: -47px;
    top: 10px;
}

.fetch-market-datatui .input-numbers.card-btn-view .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin-top: 24px;
}

.setup-process {
    .fetch-market-datatui {
        .mat-step:first-child {
            .mat-step-header {
                .mat-step-text-label {
                    .mat-button-toggle-group {
                        margin-right: 3.5% !important;
                    }
                }
            }
        }
    }
}

.splitgroupbtn.marketdata-viewbtn {
    .mat-button-toggle {
        background-color: #1976d2;
        width: auto;

        span.ui-text {
            color: #fff;
            padding: 14px !important;
        }
    }

    .notranslate.material-icons-outlined {
        color: #fff;
    }
}

.marketdata-viewbtn .splitgroupbtn .splitbtn .mat-icon.notranslate.material-icons-outlined {
    right: 5px !important;
}

.fetch-market-datatui-option {
    display: initial !important;

    .mat-vertical-content {
        //margin-top: 24px;
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
    }

    .splitgroupbtn {
        .mat-button-toggle {
            background-color: #1976d2 !important;
            width: auto;

            span.ui-text {
                color: #fff !important;
                margin-left: 0px !important;
                text-transform: capitalize;
                font-weight: 500 !important;
            }
        }
    }

    .notranslate.material-icons-outlined {
        color: #fff !important;
        top: 3px !important;
    }

    .mat-step-text-label {
        color: #000 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
    }
}

.fetch-market-datatui-option {
    .mat-step:first-child {
        .mat-step-header {
            background: #f9f9f9 !important;
        }
    }

    .mat-stepper-vertical-line::before {
        border: none;
    }
}

.fetch-market-datatui-option .mat-step .mat-step-header[aria-selected=true] {
    background: rgba(25, 118, 210, 0.05) !important;
    border-radius: 0px;
}

.fetch-market-datatui-option .mat-step .mat-step-header[aria-selected=false] {
    background: #f9f9f9 !important;
    border-radius: 0px;
}

.notranslate.marketdata-btnarrow.material-icons-outlined {
    top: 11px !important;
}

.gridsetup-modal-view .fetch-market-datatui-option .mat-step:first-child .mat-step-header .mat-step-text-label {
    padding-right: 0px !important;
}

.setup-process .fetch-market-datatui-option span.stickyButton .splitgroupbtn {
    right: -21px !important;
    top: 10px !important;
}

.fetch-market-datatui-option .input-numbers.card-btn-view .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin-top: 24px !important;
}

.fetch-date-modalheader {
    .p-dialog .p-dialog-header {
        display: block;
        padding: 16px 27px !important;
        border-radius: 0px;
    }

    .p-dialog {
        left: 0em !important;
        margin-top: 0em !important;
    }
}

.Oms_button.marketdata-gridbbtn {
    .buttononheader {
        position: fixed !important;
        top: 0.6em;
        right: 4em;
    }
}

.Oms_button.marketdata-gridbbtn .buttononheader button:first-child {
    line-height: 36px;
    height: 36px;
}

.edit-window-btn .marketdata-gridbbtn span.ui-text {
    padding: 0em !important;
    margin-left: 0em;
    font-weight: 500;
    vertical-align: bottom;
}

.marketdata-gridbbtn .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    max-width: 60vw !important;
    margin-bottom: 0em;
    min-width: 60vw !important;
    min-height: auto;
    background: #fff;
    left: -1.8em !important;
}

.rapid-pos-btn.custodianst-legacy-grid .splitgroupbtn {
    margin-top: 15px;
}

.setup-process.custodianst-legacy-grid span .mat-raised-button {
    right: 0% !important;
}

.rapid-pos-btn.custodianst-legacy-grid {
    .mat-step-header {
        .mat-step-text-label {
            color: #000;            
            font-size: 18px;
            font-weight: 500;

            span.stickyButton {
                right: 1em !important;
                width: auto;
            }

            .next {
                background: #fff !important;
                color: #000 !important;
                margin-right: -1rem !important;
                width: auto !important;
                margin-top: 15px;
            }
        }
    }
    .inputbtninset{
        right: 33em;
    }
    .mat-step-header {
        background: #f3f3f3;
        border-radius: 0px;
    }
}

.rapid-pos-btn.custodianst-legacy-grid {
    .splitgroupbtn {
        .mat-button-toggle {
            margin-top: -4px !important;

            .mat-button-toggle-label-content {
                line-height: 35px !important;
            }

            span .ui-text {
                color: #000;
                padding-left: 0.8em;
                padding-right: 0em;
                text-transform: uppercase;
                font-weight: 700 !important;
            }

            .mat-menu-item {
                .mat-icon {
                    color: #1d2125;
                }
            }

            span {
                .mat-menu-item {
                    height: 40px !important;
                }
            }
        }

        .splitbtn .mat-icon.notranslate.material-icons-outlined {
            color: #000;
        }
    }
}

.rapid-pos-btn.custodianst-legacy-grid {
    .splitgroupbtn.editbtn-legacycustodian {
        .mat-button-toggle {
            margin-top: -4px !important;

            .mat-button-toggle-label-content {
                line-height: 30px !important;
            }

            span .ui-text {
                padding-right: 1em;
                padding-left: 0.5em;
            }

            .mat-menu-item {
                height: 43px;

                .mat-icon {
                    color: #000;
                }
            }

            span {
                .mat-menu-item {
                    height: 43px !important;
                }
            }
        }        
        .inputbtninset{
            right: 33em;
        }
    }
}
mat-form-field.mat-form-field.textarea-height textarea.mat-input-element {
    height: 11px;
    padding-left: 0em;
}
.border-1-capitalentry{
border-bottom: 1px solid #e0e0e0;
padding-bottom: 5px;
padding-top: 9px;
width: 97.7% !important;
margin: auto;
display: block;
margin-left: 5px;
}

.capital-share-series-modal{
    .mat-header-fix{
        .mat-step-header {
            z-index: 1;
        }
    }
}
.capital-shareclass-modal{   
    .p-dialog{
        max-height: initial !important;
        position: initial !important;
        z-index: 999999;
    }
}

.gridsetup-modal-view-conection:first-child .p-dialog-header {
    display: none !important;
}
.connections-sourcemodal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    max-width: 75vw !important;
    width: 75vw;
}  
.connections-sourcemodal .add_dialog_details .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable{
    min-height: 34rem;
    margin: 0rem;
}
.setup-process span.stickyButton.nofee-btn-performance{
    right: 7em!important;
}
.performacebase-nofee-confirmmodal {
    .p-dialog {
        .p-dialog-header {
            display: block;
            padding: 16px 27px !important;
        }
    }

    .modal-popup {
        padding: 16px 27px !important;
    }
    .p-dialog{
        position: initial;
    }
}
.setup-process .default-grid .view-data-collection.bondinterest-modal .card .fury-list-header, .setup-process .default-grid .view-data-collection.bondinterest-modal .card .fury-list-table{
    z-index: 1;
}
.bondinterest-modal .p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-footer button{
    right: 13px;
    min-width: 40px;
    height: auto;
    top: 0px;
}
.bondinterest-modal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable{
min-width: 32em;
}
.p-dialog{
    .mat-header-fix.headerover-lapping{
        .mat-step-header{
            position: relative !important;
        }
        .mat-vertical-content-container{
            margin-top: 0em!important;
        }
    }
}  
.input-importdesign{
    .fury-list-table{
        .fury-list-header{
            .fury-list-name{
                padding-right: 10px !important;
                margin-top: 0px !important;
            }
        }
    }
}
.french_workflowtree{
    .fury-list-table{
        .fury-list-header{
            .fury-list-name{
                line-height: 20px !important;
            }
        }
    }
}
.route-animations-elements.workflow-treesubheader {

    .p-treetable .p-treetable-footer,
    .p-treetable .p-treetable-thead>tr>th {
        padding: 0.5rem;
    }

    .grid-spcui {
        .setGridHeight {
            height: calc(85vh - 8em - 65px) !important;
            width: 100%;
        }
    }

}
.fury-default .fury-card.workflow-treesubheader{
    border-radius: 0;
}
fury-page-layout-content.fury-page-layout-content.workflow-grid-wid {
    padding-right: 22px;
}
.modalbackdrop-view.executework-flowrun .p-dialog-mask{
    background-color: rgba(0,0,0,.4);
}
.workflow-treesubheader .p-treetable .p-treetable-tbody>tr {
    border-bottom: solid 1px #eae6e1;
}
.workflow-treesubheader .p-treetable .p-treetable-tbody>tr>td {
    border: none;
}
.fury-default .fury-list-header {   
    border-radius: 0px !important;
}
.fury-default .fury-card{
    border-radius: 0px !important;
}
mat-vertical-stepper.inestorgroup-modal.mat-stepper-vertical {
    width: auto !important;
}
.setup-process.grid-expendicon .setup-grid-form-view {
    width: 98%;
}
.card.cardw100.grid-radius {
    border-radius: 0px !important;
    .p-datatable .p-datatable-thead>tr>th{
        height: 30px;        
        padding: 0.2rem 1rem;
    }
    .p-datatable .p-datatable-tbody>tr>td{
        height: 30px;      
        padding: 0rem 1rem;
    }
    .p-checkbox .p-checkbox-box{
        width: 17px;
        height: 17px;
        margin-top: 3px;
    }
}
.content.group-investor-input {
    .mat-form-field {
        margin-right: 10px;
    }

    .mat-form-field-appearance-outline {
        .mat-form-field-wrapper {
            margin-top: 0px;
        }
    }
}
.crypto-marketdata-form button.mat-focus-indicator.create.mat-mini-fab.mat-button-base.mat-primary{
    background-color: #fff !important;
}
span.stickyButton.button-crypto.crypto-marketdata-form .mat-mini-fab mat-icon.material-icons-outlined{
    color: #000;
}
.card.rapid-pos-btn.gridsetup-modal-view.edit-window-btn .rapid-investorgrpupbtn button{
     background-color: rgb(255, 255, 255) !important;
     }
.default-grid .all-grid-expand-collapseicon fury-list{
    display: inline !important;
}
.default-grid .grid-expand-collapseicon .fury-page-layout-content .card {
    width: 99%;
}
.rapid-expend-grid{
    .grid-expand-collapseicon{
        .fury-page-layout-content{
            padding-right: 18px;
            .card {
                width: 100%;
            }
        }
    }
}  

.default-grid .excuteworkflow-grid-expand-collapseicon .workflow-grid-wid .grid-excuteworkflwo{
    width: 100%;
}
.default-grid .excuteworkflow-grid-expand-collapseicon .grid-scroll-processing .grid-excuteworkflwo{
    width: 100%;
}
fury-page-layout-content.fury-page-layout-content.expand-userentry .mapinggrid-collapseicon {
    width: 100%;
}
.default-grid .input-card .view-data-collection.algin-gridexpandicon {
    width: 100%;
}
.rapid-expend-grid .input-card .view-data-collection.algin-gridexpandicon {
    width: 100%;
}
.rapid-expend-grid .input-card .view-data-collection.algin-gridexpandicon-processing {
    width: 99.3%;
}
.rapid-expend-grid .view-data-collection.algin-gridexpandicon-processing .card{
    width: 95.5%!important;
}
.rapid-pos-btn .tableview_desgin.trade-view-input .splitgroupbtn{
    height: auto;
}
.default-grid  .view-data-collection.algin-gridexpandicon-processing {
    margin-right: 0px;
    display: flow-root !important;
}
.setup-process.gridexpand-icon-responsive {
    width: 97.8% !important;
    display: flex !important;
    justify-content: flex-end;
}
.setup-progress.setup-grid-form-view.setup-gridview.expand-viewfund-spc .seq-grid-expandicon {
    width: 100%;
}
.newmodalui-view {
    .moodal-view-toolbar {
        display: grid;
        width: 100%;
        grid-template-columns: 20% 40% 40%;

        .popup-view-toolbar {
            border-left: solid 1px #f4f2f0;
            padding-left: 20px;
            padding-right: 20px;
            .investor-content-view,
            .trade-content-view,
            .portfolio-content-view,
            .accounting-content-view {
                ul {
                    margin: 0px;
                    padding: 0px;
                    background-color: #f9f9f9;

                    li {
                        display: inline-block;
                        width: 47%;
                    }
                }
            }

            .setup-content-view,
            .usermanagement-content-view,
            .customization-content-view {
                margin-bottom: 10px;

                ul {
                    margin: 0px;
                    padding: 0px;
                    background-color: #f9f9f9;

                    li {
                        padding: 10px;

                        .iconset {
                            display: none;
                            align-items: center;
                        }
                    }
                }
            }

            .setup-content-view,
            .usermanagement-content-view,
            .customization-content-view {
                ul {
                    li:hover {
                        padding: 10px;

                        .iconset {
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-end;

                            span {
                                font-size: 16px;
                                font-style: normal;

                                i {
                                    font-style: normal;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .gray-bg {
                    background: #fff9f1;
                }
            }

            .gray {
                color: #646668;
                font-size: 16px;
            }

            .yellow {
                color: #ee8434;
                font-size: 16px;
            }

        }

        .heading {
            h4 {
                background: #1976d2;
                color: #f1f1f1;
                margin: 0px;
                text-transform: capitalize;
                font-size: 16px;
                padding:5px 10px;
                line-height: 1.5em;
            }

            h3 {
                color: #1d2125;
                padding: 0em 0em 0.5em 0em;
                border-bottom: 1px solid #f1f1f1;
                font-size: 18px;
                text-transform: capitalize;
                font-weight: 500;

            }
        }

        .toolbar-modal-twopartli {
            width: 50%;

            a {
                width: 100%;
                text-transform: capitalize;
            }
        }

        .toolbar-modal-onepartli {
            width: 100%;

            a {
                width: 100%;
                text-transform: capitalize;
            }
        }
    }

    .fury-card {
        padding: 0em 1.5em 1em 1.5em;
    }

    .simplebar-content-wrapper {
        height: 100% !important;
        overflow-x: scroll !important;
    }

}
.newmodalui-create {
    .moodal-view-toolbar {
        display: grid;
        width: 100%;
        grid-template-columns: 30% 30% 40%;

        .popup-view-toolbar {
            border-left: solid 1px #f4f2f0;
            padding-left: 20px;
            padding-right: 20px;
            .investor-content-view,
            .trade-content-view,
            .portfolio-content-view,
            .accounting-content-view {
                ul {
                    margin: 0px;
                    padding: 0px;
                    background-color: #f9f9f9;

                    li {
                        display: inline-block;
                        width: 47%;
                    }
                }
            }

            .setup-content-view,
            .usermanagement-content-view,
            .customization-content-view {
                margin-bottom: 10px;

                ul {
                    margin: 0px;
                    padding: 0px;
                    background-color: #f9f9f9;

                    li {
                        padding: 10px;

                        .iconset {
                            display: none;
                            align-items: center;
                        }
                    }
                }
            }

            .setup-content-view,
            .usermanagement-content-view,
            .customization-content-view {
                ul {
                    li:hover {
                        padding: 10px;

                        .iconset {
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-end;

                            span {
                                font-size: 16px;
                                font-style: normal;

                                i {
                                    font-style: normal;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .gray-bg {
                    background: #fff9f1;
                }
            }

            .gray {
                color: #646668;
                font-size: 16px;
            }

            .yellow {
                color: #ee8434;
                font-size: 16px;
            }

        }

        .heading {
            h4 {
                background: #1976d2;
                color: #f1f1f1;
                margin: 0px;
                text-transform: capitalize;
                font-size: 16px;
                padding:5px 10px;
                line-height: 1.5em;
            }

            h3 {
                color: #1d2125;
                padding: 0em 0em 0.5em 0em;
                border-bottom: 1px solid #f1f1f1;
                font-size: 18px;
                text-transform: capitalize;
                font-weight: 500;

            }
        }

        .toolbar-modal-twopartli {
            width: 50%;

            a {
                width: 100%;
                text-transform: capitalize;
            }
        }

        .toolbar-modal-onepartli {
            width: 100%;

            a {
                width: 100%;
                text-transform: capitalize;
            }
        }
    }

    .fury-card {
        padding: 0em 1.5em 1em 1.5em;
    }

    .simplebar-content-wrapper {
        height: 100% !important;
        overflow-x: scroll !important;
    }

}

.moodal-view-toolbar {

    .subhead-bg {
        background: #fff9f1;
        border-radius: 4px;
        margin-bottom: 1em;

        .subheading {
            background: #1976d2;
            color: #f1f1f1;
            margin: 0px;
            text-transform: capitalize;
            font-size: 16px;
            padding:5px 10px;
            line-height: 1.5em;
        }

        ul {
            li {
                display: flex;

                a {
                    width: 100%;
                    text-transform: capitalize;
                }
            }
        }
    }

    .d-block {
        display: block !important;
    }

    .subheading.gray-bg {
        background: #1976d2 !important;
    }
}

.dropdown.viewpop.popupvw.newmodalui-view.open {
    position: fixed;
    bottom: 0px;
    top: 52px;
    right: 0px;
    overflow: scroll;
}
.dropdown.viewpop.popupvw.newmodalui-create.open {
    position: fixed;
    bottom: 0px;
    top: 53px;
    right: 0px;
    overflow: scroll;
}
.newmodalui-view{
    .moodal-view-toolbar{
        .popup-view-toolbar1 {
            border-left: 1px solid #f4f2f0;
            padding-left: 20px;
            padding-right: 20px;
            .setup-content-view{
                margin-bottom: 10px;
                ul{
                    margin: 0;
                    padding: 0;
                    background-color: #f9f9f9;
                    li{
                        padding: 10px;
                        .iconset {
                            display: none;
                            align-items: center;
                            .span{
                                font-size: 16px;
                                font-style: normal;
                            }
                        }
                    }
                  
                }
            }
        }
        .d-block {
            display: block !important;
        }
    }
}

.newmodalui-create{
    .moodal-view-toolbar{
        .popup-view-toolbar1 {
            border-left: 1px solid #f4f2f0;
            padding-left: 20px;
            padding-right: 20px;
            .setup-content-view{
                margin-bottom: 10px;
                ul{
                    margin: 0;
                    padding: 0;
                    background-color: #f9f9f9;
                    li{
                        padding: 10px;
                        .iconset {
                            display: none;
                            align-items: center;
                            .span{
                                font-size: 16px;
                                font-style: normal;
                            }
                        }
                    }
                  
                }
            }
        }
        .d-block {
            display: block !important;
        }
    }
}
.newmodalui-view{
    .moodal-view-toolbar{
        .popup-view-toolbar2 {
            border-left: 1px solid #f4f2f0;
            padding-left: 20px;
            .setup-content-view{
                margin-bottom: 10px;
                ul{
                    margin: 0;
                    padding: 0;
                    background-color: #f9f9f9;
                    li{
                        padding: 10px;
                        .iconset {
                            display: none;
                            align-items: center;
                            .span{
                                font-size: 16px;
                                font-style: normal;
                            }
                        }
                    }
                  
                }
            }
        }
        .d-block {
            display: block !important;
        }
    }
}

.newmodalui-create{
    .moodal-view-toolbar{
        .popup-view-toolbar2 {
            border-left: 1px solid #f4f2f0;
            padding-left: 20px;
            .setup-content-view{
                margin-bottom: 10px;
                ul{
                    margin: 0;
                    padding: 0;
                    background-color: #f9f9f9;
                    li{
                        padding: 10px;
                        .iconset {
                            display: none;
                            align-items: center;
                            .span{
                                font-size: 16px;
                                font-style: normal;
                            }
                        }
                    }
                  
                }
            }
        }
        .d-block {
            display: block !important;
        }
    }
}

fury-fs-investor fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header {
    display: none !important;
}

fury-processing{
    .innerExportAlign{
        .mat-icon{
            overflow: visible !important;
        }
    }
    }