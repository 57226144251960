/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
.fury-backdrop {
  z-index: 800 !important;
  background-color: transparent;
  transition-property: background-color, visibility;
  transition-duration: $backdrop-animation-duration;
  transition-timing-function: $backdrop-animation-timing-function;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  visibility: hidden;

  &.visible {
    visibility: visible;
    background-color: rgba(0, 0, 0, .6);
  }

  &.invisible {
    background-color: transparent;
  }
}
