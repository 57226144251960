/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

input {
  filter: none;
}

.input-numbers .mat-form-field {
  width: 100% !important;
  padding: 0em 2em 0em 2em;
}

// *****  30/06/2021 ****
a,
a:focus,
a:hover {
  text-decoration: none;
}

.text-area .mat-form-field-flex {
  height: auto !important;
  min-height: 47px !important;

  text-area {
    min-height: 20px !important;
  }
}

// .fury-card-content{padding: 0px 0px;}
// .mat-form-field{padding: 0.8em 0em 0.8em 2em;}
.mat-tab-header {
  margin-bottom: 30px;
}

.mat-tab-group {
  padding-bottom: 30px;
}

.mat-mini-fab .mat-button-wrapper {
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tab-body-wrapper {
  overflow: inherit !important;
}

.mat-card-subtitle img {
  height: 2.3rem;
  margin-right: 8px;
  background: #08384a;
  border-radius: 50%;
  padding: 7px;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.7375em 0.5em
}

.mat-datepicker-toggle-default-icon {
  width: 1.5em !important;
}

.mat-form-field-infix {
  border-top: 0.4em solid transparent;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: .75em .75em 0 .75em !important;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -.5em;
}

.sign-space .mat-form-field-wrapper .mat-form-field-infix {
  border-top: 0.5em solid transparent !important;
  border-top: .84375em solid transparent !important;
}

.mat-card {
  height: 130px;
  width: 260px;
  background: #ffffff !important;
  color: #08384a;
  border: solid 1px #ccc;
  box-shadow: 0 3px 1px -2px rgb(82 63 104 / 6%), 0 2px 2px 0 rgb(82 63 104 / 4%), 0 1px 5px 0 rgb(82 63 104 / 4%);

  .mat-card-title {
    font-weight: 400;
  }

}

.fury-default .fury-card {
  min-height: 200px;
}

.example-button-row {
  white-space: nowrap;
  margin: 8px 0px auto auto;
  position: absolute;
  right: 2em;
  z-index: 100000000 !important;
}

.example-edit-buttons {
  display: flex;
  white-space: nowrap;
  margin: 0px 0px auto 1000px;
  position: absolute;
  right: 10px;
  z-index: 100000000 !important;
}

.mat-tab-body-content {
  overflow: inherit !important;
}

.mat-tab-body.mat-tab-body-active {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}

.mat-select-arrow-wrapper {
  vertical-align: bottom !important;
  display: flex;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0em 0em 0.75em 0em !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}

.input-numbers .upload_uispc .mat-form-field {
  margin-top: 80px;
}

:host ::ng-deep .card-btnbox .mat-tab-label {
  height: 4.5rem !important;
  text-transform: uppercase;
  font-size: 1.15rem;
  font-weight: 700;
  color: #030303;
}

.mat-tab-label {
  height: 64px !important;
}

.mat-tab-group .mat-tab-label {
  padding: 0 0.5em !important;
  min-width: 9em !important;
}

.example-button-row .mat-button-base {
  margin: 10px 5px 5px 5px;
  min-width: 3em;
  height: 3em;
  border-radius: 50%;
  line-height: inherit;
  padding: 0px;
  font-weight: normal;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgb(82 63 104 / 6%), 0px 2px 2px 0px rgb(82 63 104 / 4%), 0px 1px 5px 0px rgb(82 63 104 / 4%);
}

.example-edit-buttons .mat-button-base {
  margin: 10px 5px 5px 5px;
  min-width: 3em;
  height: 3em;
  border-radius: 50%;
  line-height: inherit;
  padding: 0px;
  font-weight: normal;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgb(82 63 104 / 6%), 0px 2px 2px 0px rgb(82 63 104 / 4%), 0px 1px 5px 0px rgb(82 63 104 / 4%);
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: rgb(220, 0, 78);
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #1976d2;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background-color: #ffc107;
}

:host ::ng-deep span.material-icons-outlined>title {
  background-color: #009688 !important;
}

[data-md-tooltip] {
  position: relative;
}

[data-md-tooltip]:before {
  content: attr(data-md-tooltip);
  position: absolute;
  bottom: -40px;
  left: 50%;
  padding: 8px;
  transform: translateX(-50%) scale(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: top;
  background: #fff;
  color: #212939;
  border-radius: 5px;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
  box-shadow: 0px 3px 1px -2px rgb(82 63 104 / 6%), 0px 2px 2px 0px rgb(82 63 104 / 4%), 0px 1px 5px 0px rgb(82 63 104 / 4%);
  border: solid 1px #f5f5f5;
}

[data-md-tooltip]:hover:before {
  transform: translateX(-50%) scale(1);

}

.pushpin[data-md-tooltip]:hover:before {
  transform: rotate(-40deg);
}

.pushpin[data-md-tooltip]:before {
  transition: transform 0.3s ease-in-out;
  bottom: -31px;
  left: 22%;
}

.pushpin.pushpinactive[data-md-tooltip]:hover:before {
  transform: rotate(-90deg) !important;

}

.pushpin.pushpinactive[data-md-tooltip]:before {
  bottom: -21px;

}

.modal-scd-head h6 {
  background: #1976d2;
  margin: 0px;
  padding: 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;

}

.modal-scd-head {
  //border: solid 1px #eceaea;
  display: grid;
}

.modal-scd-head .btrackform {
  padding: 1rem !important;
}

.modal-scd-head .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.9em 0 0.9em 0;
}

.bnavtab .p-inputtext {
  margin: 0px 10px;
  padding: 0.6em 0.5em;
  margin: 0.5em;
  margin-top: 0em;
}

.bnavtab.modal-month-field .btracknav {
  align-items: baseline;
}

span i.material-icons {
  font-style: normal;
}

.sidenav-user-icons [data-md-tooltip] {
  position: relative;
}

.sidenav-user-icons [data-md-tooltip]:before {
  content: attr(data-md-tooltip);
  position: absolute;
  bottom: -23px;
  left: 50%;
  padding: 0px 3px;
  transform: translateX(-50%) scale(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: top;
  line-height: 25px;
  background: #fff;
  color: #212939;
  border-radius: 3px;
  font-size: 10px;
  font-family: var(--font-family);
}

.sidenav-user-icons [data-md-tooltip]:hover:before {
  transform: translateX(-50%) scale(1);
}


$seamless-green: #009688;
$seamless-blue: #1976d2;
$seamless-white: #ffffff;
$seamless-yellow: #ffc107;
$seamless-red: #dc004e;
$seamless-darkgray1: #212121;
$seamless-darkgray2: #303030;
$seamless-darkgray3: #424242;
$seamless-grayhover: #5e5d5d;
$seamless-graytextcolor: rgba(0, 0, 0, 0.87);

.login_blue_btns {
  background-color: $seamless-blue !important;
  color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -moz-box-shadow: 0 0 0 30px white inset !important;
  -o-box-shadow: 0 0 0 30px white inset !important;
  padding-left: 3px !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -moz-box-shadow: 0 0 0 30px white inset !important;
  -o-box-shadow: 0 0 0 30px white inset !important;
  padding-left: 3px !important;
  ;
}

.example-button-row .p-checkbox {
  display: inline-flex !important;
  text-align: center;
  vertical-align: middle;
}

button.tab-menu-right-btn {
  display: inline-flex;
  position: absolute;
  float: right;
  right: 1em;
  margin-top: 1em;
  z-index: 100000000;
}

.tab-menu-calculate button.mat-focus-indicator.mat-raised-button {
  position: absolute;
  right: 4em;
  top: 0.7em;
}

svg.sidenavsvg {
  width: 1.5em;
  fill: #fff;
}

svg.mat-icon {
  width: 1.5em
}

.fury-default .sidenav-item .sidenav-item-link.active>svg.sidenavsvg {
  fill: #212939;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.42) !important;
}

.mat-radio-button .mat-radio-label .mat-radio-label-content {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.mat-checkbox-inner-container-no-side-margin {
  margin-top: -4px !important;
}

.p-inputnumber {
  width: 100% !important;
  padding: 0em 2em 0em 0em;

  input {
    height: 3.1rem;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

*:focus {
  outline: none;
  box-shadow: none !important;
}



.example-button-call {
  white-space: nowrap;
  margin: 0px 0px auto 1000px;
  position: absolute;
  right: 30px;
  z-index: 100000000 !important;
}

.example-edit-call button {
  margin: 10px 8px 8px 8px;
  min-width: 45px;
  height: auto;
  border-radius: 50%;
  line-height: inherit;
  padding: 0px;
  font-weight: normal;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgb(82 63 104 / 6%), 0px 2px 2px 0px rgb(82 63 104 / 4%), 0px 1px 5px 0px rgb(82 63 104 / 4%);
}


.mat-menu-panel {
  position: relative;
  top: 14px;
  width: 15rem !important;
  right: 14px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 10px 0px rgb(0 0 0 / 4%) !important;
}

.mat-menu-item:hover:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-menu-item-highlighted:not([disabled]) {
  background: rgb(199 231 243);
}

.mat-select-arrow {
  position: relative;
  // bottom: 6px;
}

.selectctry .mat-form-field-appearance-fill .mat-form-field-infix {
  padding-top: 0.5em !important;
}

.mat-option.mat-active {
  background: rgb(199 231 243);
  // overflow-x: scroll;
}

.mat-select-panel.mat-primary.mat-select-search-panel {
  overflow-x: visible !important;
  display: grid;

  .mat-option {
    overflow: visible !important;
  }
}


.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgb(199 231 243);
  // overflow-x: auto;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {

  background: rgb(199 231 243);
}

// .mat-form-field-appearance-legacy .mat-form-field-wrapper {
//   width: 320px;
// }
// comment on 30/6 21

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {

  color: white;
}


.icon.mat-icon-button.mat-button-base {

  right: -15px;
}

.mat-button-wrapper {
  padding: 7px 0 !important;
  padding-left: 24px;
  padding-right: 24px;
  font-family: var(--font-family);
  font-weight: 400;
}

button.mat-focus-indicator.login_blue_btns span.mat-button-wrapper {
  padding: 0px !important;
}

.mat-tab-label,
.mat-tab-link {
  height: 4.5em;
}

.singleInput .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  width: 100%;
}

.twoInput .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  width: 50%;
}

.threeInput .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  width: 33.33%;
}

.tableview_desgin table.mat-table {
  overflow-y: scroll;
  display: block;
  min-height: calc(100vh - 86vh);
  text-align: inherit;
}

.tableview_desgin th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 0 20px !important;
  white-space: nowrap;
  border: 1px solid #f7f7f7;
  // width: 10%;
}

.p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.inputNumber {
  span {
    padding: 0em 0em 0em 0em;

    input+span {
      margin: 0 0px 0 -16px;

      button {
        background: #d0d5d8;
        border: 1px solid #c1c2c2;
        width: 14px !important;
        height: 14px;
        padding: 0 !important;
        margin: 0 -10px 5px 0px;
        display: flex;
        align-items: center;

        span {
          font-size: 10px;
          padding-top: 2px;
          margin: 0 0px 1px 0
        }
      }
    }

  }

}

.upload-scrol-remove fury-list {
  overflow: inherit !important;
}

.actions .p-checkbox.p-component.p-checkbox-checked {
  position: relative;
  top: 6px;
}

.actions .p-checkbox-label.p-checkbox-label-active {
  position: relative;
  top: 6px;
}

.fury-card-content .example-button-row .p-checkbox {

  position: relative;
  bottom: 20px;
}

.actions button {
  z-index: 9;
  // background: none;
  // box-shadow: none;
  // border: none;
}

.actions.oms-list-btns {
  display: inline-flex;
  z-index: 111;
  align-items: center;
  margin-top: 0px !important;

  .countClass {
    padding: 0 0.7em !important;
    white-space: nowrap;
  }

  .create {
    margin-top: 0;
  }

}

.actions.oms-list-btns .pre_btn {
  margin: 5px 10px;
  padding: 9px 10px;
  background: $seamless-blue;
}

.p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {

  left: 9em;
  margin: 0px auto;
  z-index: 101;
  max-width: 76vw;
}

.Dialog_width_Tyni .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  width: 20vw;
  min-width: 20vw;
}

.Dialog_width_Medium .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  width: 60vw;
}

.Dialog_width_Small .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  width: 30vw;
}

.Dialog_width_Large .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  width: 80vw;
}

.Dialog_width_Tyni .p-dialog .p-dialog-content {
  min-height: 0em;
}

.p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
  background: transparent;
  color: #ffffff;

}

.p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header .p-dialog-header-icon:last-child:hover {
  box-shadow: 0 0 5px #212939;
  border-radius: 50%;
  -webkit-animation: pop 0.3s 2;
  animation: pop 0.3s 2;
  border: 2px solid #fff;
}

.p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-footer button {

  min-width: 45px;
  height: auto;
  top: 10px;

}

.p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header {

  background: #212939;
}

.p-dialog .p-dialog-content {
  min-height: 10em;
  padding-bottom: 0em !important;
}

// #mat-select-0-panel{
//   position: relative;
//   left: 25px;
//   top: 10px;
//   margin-left: 0.4em !important;}
.ag-theme-alpine .ag-header {
  height: 30px !important;
  min-height: 30px;
}

.fury-list-table .ag-theme-alpine .ag-ltr .ag-header-select-all {
  margin-right: 1em;
  margin-top: 0.15em;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  height: 100% !important;
  top: calc(50% - 50%) !important;
}

.ag-cell {
  border-right: none;
}

.p-dialog .p-dialog-header .p-dialog-title {

  color: white;
  font-family: var(--font-family);
  font-weight: 500 !important;
}

//expire modal
.expireModal .p-dialog .p-dialog-header {
  background: #1976d2 !important;
}

.oms-trade-texthead {
  position: relative;
  top: 0.8em;
  left: 0em;
  display: inline-block;
  margin: 0.8em 0;

}

.mat-select-trigger {
  height: auto;
}

.mat-menu-item .mat-icon {
  color: rgba(0, 0, 0, 0.54);
  padding: 7px;
  width: auto;
  height: auto;
  border-radius: 50%;
  font-size: 20px;
  margin-right: 0px !important;
}

.mat-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-menu-panel {
  min-height: inherit !important;
  width: 15rem !important;
}

// #mat-select-0-panel{left: 9px !important;}
.p-calendar .p-datepicker {
  min-width: auto !important;
}

.p-datepicker table td {
  padding: 0px !important;
}

.confirmdialogc {
  .p-dialog {
    .p-dialog-content {
      min-height: 0.5em;
    }
  }

}

.fury-default {
  fury-list {
    display: inherit;
  }
}

h1 {
  word-break: break-all;
}

.mat-form-field-wrapper {
  padding-bottom: 1.2em;
  min-height: 60px;
}

table.amtColReport.ireport-table-align .heading-bgs {
  background: #6b6b6b;
  color: #fff;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.scrollHeight .heading-bgs th {
  color: #fff !important;
  font-weight: normal;
  padding: 0.2em 1em;
}

.reportDiv table,
table.amtColReport.ireport-table-align {
  border-collapse: collapse;
}

table.amtColReport.ireport-table-align td:first-child {
  text-align: left !important;
  padding: 8px 10px !important;
}

.amtColReport td+td {
  text-align: right;
  padding-right: 10px !important;
}

.tab-content {
  padding: 1rem;
}

.tab-content .card-body {
  padding: 0.5em 0.5rem 1.15em 0.5em;
}

.reportDiv.fundbox-report.blcsheet_ui_ch {
  margin: 0 auto;
  overflow: scroll;
  display: grid;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inherit;
  vertical-align: middle;
}

.amtColReport {
  color: #666;
}

.scroll-size::-webkit-scrollbar {
  height: 7px !important;
}

.scroll-size::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px #213140 !important;
  box-shadow: inset 0 0 0px #213140 !important;

}

.scroll-size::-webkit-scrollbar-thumb {
  height: 20px !important;
  background-color: #213140 !important;
}

.reportDiv td,
.reportDiv th {
  padding: 8px 10px 8px 0 !important;
  vertical-align: middle !important;
  line-height: 18px;
  color: #666;
}

.amtColReport td {
  white-space: nowrap;
  word-break: word-wrap;
}

.card-body.card-vi .scroll-size {
  padding: 0em 0em 0em 0em;
  width: 90%;
  margin: auto;
  margin-bottom: 2em;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.reportDiv tr:nth-child(even) {
  background-color: #fff !important;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.panel-body.eod-checks.row.txt-left {
  text-align: left;
}

.panel-body.eod-checks {
  overflow: scroll;
  border: none !important;
}

// balance sheet ui start //
.nested-header .p-accordion-tab {
  margin-left: 40px;
}

.accordion-reprt .amtColReport .cntnt td:first-child {
  padding-left: 140px;
}

.panel-title>a {
  display: block;
  position: relative;
}

.panel-title>a:after {
  content: "\f078";
  /* fa-chevron-down */
  font-family: 'FontAwesome';
  right: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(-90deg);
  float: left !important;
  padding-left: 5px;
  padding-top: 5px;
}

.panel-title>a[aria-expanded="true"]:after {
  content: "\f077";
  /* fa-chevron-up */
  -webkit-transform: rotate(180deg);
  transform: rotate(-180deg);
}

.panel-default>.panel-heading {
  color: #666;
  background-color: #fdfdfd;
  background: #fdfdfd;
  -webkit-box-shadow: 1px 1px 5px 1px rgba(232, 232, 232, 1);
  -moz-box-shadow: 1px 1px 5px 1px rgba(232, 232, 232, 1);
  box-shadow: 1px 1px 5px 1px rgba(232, 232, 232, 1);
  border-radius: 5px;
}

.reportDiv tr:nth-child(even) {
  background-color: #fafafa !important;
}

.amtColReport .cntnt td {
  padding: 8px 23px !important;
}

.panel-default {
  border: none !important;
  margin-top: 10px;
  box-shadow: none;

}

.amtColReport .cntnt td:first-child {
  padding-left: 80px !important;
}

.amtColReport .cntnt td {
  padding: 8px !important;
}

.amtColReport .cntnt td:first-child {
  padding-left: 140px !important;
}

.amtColReport td+td {
  text-align: right;
}

.amtColReport td+td {
  padding-right: 10px !important;
}

.amtColReport td {
  white-space: nowrap;
  word-break: word-wrap;
}

table#exportthis {
  width: 100%;
}

.panel-body {
  padding: 0px !important;
}

.nested-header .p-accordion-tab {
  margin-left: 40px;
}

.panel-body {
  padding: 15px 0 !important;
}

.panel-default>.panel-heading {
  color: #333;
  background-color: #f5f5f5 !important;
  border-color: #ddd;
}

.panel-heading {
  padding: 10px 15px !important;
}

.panel-title {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
}

.panel-title>a[aria-expanded=true]:after {
  content: "\f077";
  transform: rotate(-180deg);
}

.panel-title>a:after {
  content: "\f078";
  font-family: FontAwesome;
  right: 0;
  transform: rotate(-90deg);
  float: left !important;
  padding-left: 5px;
  padding-top: 5px;
}

.collapse:not(.show) {
  display: none;
}

.panel-default>.panel-heading {
  color: #666;
  background: #fdfdfd;
  box-shadow: 1px 1px 5px 1px #e8e8e8;
  border-radius: 5px;
}

.amtColReport .cntnt td {
  padding: 8px !important;
}

.amtColReport td+td {
  text-align: right;
  padding-right: 10px !important;
}

.amtColReport .cntnt td {
  padding: 8px 23px 8px 0;
}

.amtColReport .cntnt td {
  padding: 8px 23px;
}

.amtColReport .cntnt td:first-child {
  padding-left: 80px !important;
}

.amtColReport .cntnt td:first-child {
  padding-left: 140px;
}

.amtColReport .finalTotal td,
.amtColReport .total td {
  font-weight: 700;
}

.accordion {
  .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }

  .card:not(:first-of-type) {
    .card-header:first-child {
      border-radius: 0;
    }
  }

  .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

[role="tab"] {
  .btn {
    width: 100%;
    text-align: left;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.accordion {
  &>.card {
    overflow: hidden;
  }
}

.p-dialog .p-dialog-content {
  padding: 1.5em !important;
}

.mat-form-field-wrapper {
  margin-bottom: 0em !important;
}

.p-dialog .p-dialog-footer {
  padding-top: 0px !important;
}

// 07/03/2022 

.total-val {
  border-bottom: solid 1px #f5f5f5;
}

.amtColReport td+td {
  text-align: right;
  padding-left: 10px !important;
}

tr.total td {
  font-weight: 700;
  padding-left: 10px !important;
}

td.sudrania-lineBorderSingle {
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}

.p-accordion .p-accordion-content {
  border: none !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  box-shadow: 1px 1px 5px 1px #e8e8e8;
  background: #f5f5f5 !important;
  color: #495057 !important;
  border: none;
}

.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-radius: 0px !important;
  border: none !important;
  box-shadow: 1px 1px 5px 1px #e8e8e8;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  background: #f5f5f5 !important;
  box-shadow: 1px 1px 5px 1px #e8e8e8;
  border: none !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: 1px 1px 5px 1px #e8e8e8 !important;
}

// 08/03/2022
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0px !important;
  padding: 1rem 0rem;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 1em !important;
}

fury-eod-checks-sheet {
  .p-accordion-tab {
    width: auto !important;
    padding: 1.0em 0em 0.2em;
  }

  .p-accordion-header-link {
    width: max-content;
    padding: 1.2em 0em 0.2em;
  }
}

// balance sheet ui end //

@media (max-width:1024px) {
  .input-numbers .grid-formui {
    .col-lg-4 {
      width: 50% !important
    }
  }

  .example-edit-buttons {
    top: 5em;
    right: 20px;
  }

  .config-panel-toggle {
    bottom: 20px !important;
  }

  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    width: 40em !important;
  }

  .tabLinks .mat-tab-header {
    width: 100%;
  }

  .btrackform.form-input-set .align-forms {
    display: grid !important;
  }

  .btrackform.form-input-set .align-forms mat-form-field {
    margin-right: 0em;
    width: 100% !important;
  }

  .modal-scd-head {
    display: inherit !important;
  }

  .btrack .btrackform {
    display: inherit !important;
  }

  .btracknav {
    display: initial !important;
  }

  .btrack .btrackform mat-form-field {
    width: 100%;
  }

  .p-dialog .p-dialog-content {
    padding: 0 1rem 2rem 1rem !important;
  }

  .modal-scd-head .btrackform {
    padding: 0px !important;
  }

  .reportDiv.blcsheet_ui_ch {
    overflow: scroll;
    display: grid;
  }

  .reportDiv tr {
    border-bottom: 1px solid #e1e8ed;
  }
}

@media (max-width:992px) {
  .input-numbers .grid-formui {
    .col-lg-4 {
      width: 50% !important
    }
  }

  .example-edit-buttons {
    top: 5em;
    right: 20px;
  }

  .config-panel-toggle {
    bottom: 20px !important;
  }

  .page_container {
    .next_links {
      ul {
        li {
          width: 45% !important;
        }
      }
    }
  }

  .page_container {
    .tabLinks {
      .tabprocess {
        width: 100% !important;
      }
    }
  }

  .fury-page-layout-header {
    height: 170px;
  }

  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    left: 0em !important;
    top: 0%;
  }

  .tabLinks .mat-tab-header {
    width: 100%;
  }

  .btrackform.form-input-set .align-forms {
    display: grid !important;
  }

  .btrackform.form-input-set .align-forms mat-form-field {
    margin-right: 0em;
    width: 100% !important;
  }

  .modal-scd-head {
    display: inherit;
  }

  .btrack .btrackform {
    display: inherit;
  }

  .btracknav {
    display: initial;
  }

  .btrack .btrackform mat-form-field {
    width: 100%;
  }

  .p-dialog .p-dialog-content {
    padding: 0 1rem 2rem 1rem !important;
  }

  .modal-scd-head .btrackform {
    padding: 0px !important;
  }

  .form-input-set .align-forms {
    display: grid !important;
  }

  .reportDiv.blcsheet_ui_ch {
    overflow: scroll;
    display: grid;
  }

  .reportDiv tr {
    border-bottom: 1px solid #e1e8ed;
  }

  .mat-button-toggle-standalone,
  .mat-button-toggle-group {
    margin-right: 10px !important;
  }
}

@media (max-width:767px) {
  .input-numbers {
    .grid-formui {
      .col-lg-4 {
        width: 100% !important
      }
    }
  }

  .upload-ui-migration {
    .fury-list-header {
      width: 100%;
      flex-wrap: wrap;
      padding-top: 15px !important;
      height: auto !important;
      padding-bottom: 15px !important;

      .fury-list-name {
        width: 100%;
        border-right: 0px !important;
        line-height: inherit !important;
        display: none;
      }

      .fury-list-name+div {
        width: 100%;
        // border: solid 1px #ccc;
        border-radius: 5px;

        // margin-top: 15px;
        .fury-filter-input {
          border-radius: 5px;
          font-family: var(--font-family);
        }

        .fury-filter-input-icon {
          margin-left: 5px;
        }
      }
    }
  }

  .mat-divider {
    li.list-group-item {
      width: 94% !important;
    }
  }

  .uploadBtn {
    margin-top: 10px !important;
    flex-wrap: wrap;

    p-fileUpload {
      margin-left: 0px !important;
    }

    .mat-form-field {
      width: 60%;
    }

    .example-button-row {
      margin-top: -8px !important;
      align-self: flex-end;
    }
  }

  .example-button-row {
    right: 5px;
    top: 5px;
  }

  .example-edit-buttons {
    top: 5em;
    right: 20px;
  }

  .config-panel-toggle {
    bottom: 20px !important;
  }

  .fury-page-layout-header {
    height: 170px;
  }

  .page_container {
    .next_links {
      ul {
        li {
          width: 100% !important;
        }
      }
    }
  }

  .page_container {
    .tabLinks {
      .tabprocess {
        width: 100% !important;
      }
    }
  }

  .orderListCheck {
    p-checkbox {
      margin-right: 0px !important;
    }
  }

  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    left: auto;
    top: 15%;
    width: 80% !important;
  }

  .mat-tab-header {
    width: 80%;
  }

  .tabLinks .mat-tab-header {
    width: 100%;
  }

  .reportDiv.blcsheet_ui_ch {
    overflow: scroll;
    display: grid;
  }

  .reportDiv tr {
    border-bottom: 1px solid #e1e8ed;
  }
}

@media (max-width:480px) {
  .fury-list-table {
    .fury-list-header {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  .actions.oms-list-btns {
    margin-left: -4px !important;
  }

  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    left: auto;
    top: 10%;
    width: 80% !important;
  }

  .fury-page-layout-header {
    height: 170px;
  }

  .mat-tab-header {
    width: 80%;
  }

  .tabLinks .mat-tab-header {
    width: 100%;
  }

  .reportDiv.blcsheet_ui_ch {
    overflow: scroll;
    display: grid;
  }

  .reportDiv tr {
    border-bottom: 1px solid #e1e8ed;
  }
}

@media (max-width:360px) {
  .fury-list-table {
    .fury-list-header {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  input.fury-filter-input {
    width: 8.8rem;
  }

  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    left: auto;
    top: 15%;
    width: 80% !important;
  }

  .fury-page-layout-header {
    height: 170px;
  }

  .mat-tab-header {
    width: 80%;
  }

  .tabLinks .mat-tab-header {
    width: 100%;
  }

  .reportDiv.blcsheet_ui_ch {
    overflow: scroll;
    display: grid;
  }

}

.p-multiselect {
  width: 100%;
  background: transparent !important;
}

.p-multiselect-panel {
  margin-left: 0.6em;
  width: 10%;
  z-index: 99999999 !important;
}

.p-multiselect-label {
  padding: 0.75rem 0.75rem !important;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #2196F3;
}

.p-dropdown {
  width: 100%;
  background: transparent !important;
}

.p-dropdown-panel {
  margin-left: 0.6em;
  width: 10%;
  z-index: 99999999 !important;
}

.p-dropdown-label {
  padding: 0.75rem 0.75rem !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

.my_card {
  mat-tab-header {
    width: calc(100% - 15rem);
  }
}

.mat-form-field-label {

  font-size: .3 rem !important;
  margin-bottom: 5rem !important;
  height: 2rem;
}

.search-wrapper {
  input {
    color: rgba(0, 0, 0, 0.54);
    font-weight: normal !important;
    font-family: var(--font-family);
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon.eot?1fdxv8');
  src: url('../assets/fonts/icomoon.eot?1fdxv8#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?1fdxv8') format('truetype'),
    url('../assets/fonts/icomoon.woff?1fdxv8') format('woff'),
    url('../assets/fonts/icomoon.svg?1fdxv8#icomoon') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  // speak: never; 
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-batch-report:before {
  content: "\e900";
}

.icon-create:before {
  content: "\e901";
}

.icon-faq:before {
  content: "\e902";
}

.icon-plus:before {
  content: "\e903";
}

.icon-settings:before {
  content: "\e904";
}

.icon-upload:before {
  content: "\e905";
}

.icon-view:before {
  content: "\e906";
}

.icon-workflow:before {
  content: "\e907";
}

.simplebar-content-wrapper {
  height: 95% !important;
}

.example-button-row .mat-button-base {
  position: relative;
  width: 0px;
  right: 0em;
  padding-left: 0px;
  height: 40px;
  min-width: 40px;
  padding-bottom: 2px;
  background: #1976d2;
}

.button-text .mat-button-base .material-icons {
  color: #ffffff;

}

.button-text .mat-button-base span.mat-button-wrapper {
  color: #283144;
  font-weight: 600;
  position: relative;
  left: 14px;
}

.button-text .mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary.mat-button-disabled {
  background-color: #212939;
}

.button-text .mat-button-base span.material-icons-outlined {
  // font-size: 17px;
  right: 9px;
  position: relative;
}


.p-fileupload-files .p-button.p-button-icon-only {
  display: none;
}

.skeleton-style {
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  float: left;
}

button.mat-focus-indicator.create.mat-mini-fab.mat-button-base.mat-primary {
  background-color: #1976d2 !important;
}

.newhome {
  .p-dialog-header {
    background: #fff !important;
    border-bottom: 1px solid #ccc !important;
    padding: 10px !important;

    .p-dialog-title {
      color: #000;
      padding-left: 20px;
    }
  }
}

/**** document page css for choes button**/

.documentTable {
  padding: 20px 20px;

  .chofiles .mat-figure {
    display: block !important;
  }

  .chofiles mat-label {
    margin-bottom: 8px;
    display: block;
  }

  .chofiles input {
    height: 2em;
  }
}




.login-footer {
  width: 100%;
  padding: 10px 30px;
  position: absolute;
  bottom: 0;
  display: grid;
  ;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 15px;
  // background: rgba(125, 121, 121, 0.1);

  .copyright {
    text-align: left;
    font-size: 12px;
    color: #5888b7;
    font-weight: 400;

    a {
      color: #5888b7;
      text-decoration: none;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .contact_datils {
    text-align: right;
    font-size: 14px;
    color: #5888b7;

    span {
      margin-left: 15px;
      font-weight: 400;
      font-size: 12px;
    }

    span.ml-0 {
      margin-left: 0;
    }

    a {
      color: #5888b7;
      font-size: 12px;
      text-decoration: none;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }

  }


}

.notifcatin {
  padding: 10px 24px 10px 24px;
  border-bottom: 1px solid #ccc;

  .labels {
    font-size: 12px;
    font-family: var(--font-family);
  }

  .mat-progress-bar {
    height: 9px !important;
    border-radius: 10px;
    margin-top: 3px;

  }
}

.fury-light .setting_faq {
  background: #fff;
}


.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.75em !important;
  margin-top: 0em !important;
}

fury-page-layout-content .card {
  margin-top: -0.5em;
  box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%), 0 4px 18px 3px rgb(0 0 0 / 12%);
}

.title {
  color: #fff;
}

.fury-page-layout-card {
  padding-bottom: 0px;
}

.card {
  margin: 24px;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;

  .heading {
    font: var(--font-title);
  }

  .subheading {
    font: var(--font-subheading-1);
  }

  .content {
    margin-top: 24px;
    margin: 240px/2 0;

    .checkbox {
      margin-top: 24px/2;
    }
  }

  .actions {
    margin-top: 24px;
    padding: 24px 24px/3 0;
  }

  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, .06),
  0 1px 4px rgba(0, 0, 0, .08) !important;
}

.mat-stepper-vertical {
  width: 700px;
}

// :host ::ng-deep .p-multiselect {
//     width: 40rem !important;
//     max-width: 40rem !important;
// }
.splitgroupbtn {
  right: 0em;
  float: right;
  border-radius: 4px;
  background-color: #3978fd;
  height: 2.6rem;

  .mat-button-toggle {
    color: white;
    background-color: #1976d2;
    margin-bottom: -4px;
    margin-top: -4px;
  }

  .mat-button-toggle:hover {
    background-color: #156fc7;
  }

  .splitbtn {
    width: 2.357rem;

    .mat-icon.notranslate.material-icons-outlined {
      position: relative;
      top: 0px;
      right: 11px;
    }
  }

  .mat-focus-indicator.mat-menu-item {

    padding-left: 0px;
    padding-right: 0px;
  }

  .mat-button-toggle {
    span.ui-text {
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer;
      padding: 11px 11px;
    }

    .mat-menu-item .mat-icon {
      color: #ffffff;
      padding-left: 0px;
    }
  }
}

.next {
  background-color: #1976d2;
  color: #fff;
  margin-right: 1rem !important;
}

.toolbar-button {
  &:hover {
    background: #f5f5f5;
  }
}

.cardrow {
  width: 100%;
  padding: 20px 0px;
  grid-gap: 50px;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row: 1 / 3;
  -moz-column-width: 18em;
  -webkit-column-width: 18em;
  -moz-column-width: 18em;
  column-width: 287px;
  column-gap: 5%;
  -moz-column-gap: 5%;
  -webkit-column-gap: 5%;
  -moz-column-gap: 5%;
  margin-top: -4.87em !important;
  height: auto !important;

  .tradcard {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    cursor: pointer;
    display: inline-block;
    margin: 1.5rem 0;

    width: 100%;

    h2 {
      background-size: 7%;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
      border-radius: 8px 8px 0px 0px;
      font-size: 16px;
      margin: 0px;
      padding: 1.8em;
      font-weight: 500;
      background-size: 11%;

    }

    .bodytext {
      .text {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ul {
        margin: 0;
        padding: 0;
        min-height: 65px;
        margin-bottom: 0.5em;

        li {
          display: flex;
          font-family: var(--font-family);
          padding: 0px 1.8em;
          height: 50px;
          font-size: 1em;
          background: linear-gradient(to right, #f5f5f5 50%, white 50%);
          background-size: 200% 100%;
          background-position: right bottom;
          transition: all .3s ease-out;
          list-style: none;
          justify-content: space-between;
          align-items: center;

          .iconset {
            display: block;
            white-space: nowrap;
            align-items: center;

            span {
              font-size: 22px;
              margin-left: 10px;
              transition: all .3s ease-out;
              vertical-align: middle;
              line-height: 20px;
            }
          }

          &:hover {
            background-position: left bottom;

            .iconset {
              display: block;
              white-space: nowrap;
            }
          }

        }
      }
    }

    &:hover {
      box-shadow: 0 9px 20px 0 rgba(0, 0, 0, .21)
    }
  }
}

.Portfoliocard {
  &:nth-child(1) h2 {
    background-image: url('./../assets/img/security-master.png'), url('./../assets/img/security-master.png'), url('./../assets/img/security-master.png');
  }

  &:nth-child(2) h2 {
    background-image: url('./../assets/img/bond.png'), url('./../assets/img/bond.png'), url('./../assets/img/bond.png');
  }

  &:nth-child(3) h2 {
    background-image: url('./../assets/img/market-data.png'), url('./../assets/img/market-data.png'), url('./../assets/img/market-data.png');
  }

  &:nth-child(4) h2 {
    background-image: url('./../assets/img/corporate.png'), url('./../assets/img/corporate.png'), url('./../assets/img/corporate.png');
  }

  &:nth-child(5) h2 {
    background-image: url('./../assets/img/pnl.png'), url('./../assets/img/pnl.png'), url('./../assets/img/pnl.png');

    span {
      text-transform: none !important;
    }
  }

  &:nth-child(6) h2 {
    background-image: url('./../assets/img/dividend.png'), url('./../assets/img/dividend.png'), url('./../assets/img/dividend.png');
  }

  &:nth-child(7) h2 {
    background-image: url('./../assets/img/irr.png'), url('./../assets/img/irr.png'), url('./../assets/img/irr.png');
    text-transform: uppercase !important;
  }

  // &:nth-child(5) h2{ background:#f5f5f5 url('./../assets/img/other-process.png') no-repeat 94% center; background-size: 15%;}
}

.accoutcard {
  &:nth-child(1) h2 {
    background-image: url('./../assets/img/je-posting.png'), url('./../assets/img/je-posting.png'), url('./../assets/img/je-posting.png');
    text-transform: none !important;
  }

  &:nth-child(2) h2 {
    background-image: url('./../assets/img/income-allocation.png');
  }

  &:nth-child(3) h2 {
    background-image: url('./../assets/img/journal-entry.png'), url('./../assets/img/journal-entry.png'), url('./../assets/img/journal-entry.png');
  }

  &:nth-child(4) h2 {
    background-image: url('./../assets/img/performance.png'), url('./../assets/img/performance.png'), url('./../assets/img/performance.png');
  }

  &:nth-child(5) h2 {
    background-image: url('./../assets/img/sidepocket.png'), url('./../assets/img/sidepocket.png'), url('./../assets/img/sidepocket.png');
  }

  &:nth-child(6) h2 {
    background-image: url('./../assets/img/performance.png'), url('./../assets/img/performance.png'), url('./../assets/img/performance.png');
  }

  &:nth-child(7) h2 {
    background-image: url('./../assets/img/other-process.png'), url('./../assets/img/other-process.png'), url('./../assets/img/other-process.png');
  }

  &:nth-child(8) h2 {
    background-image: url('./../assets/img/other-process.png'), url('./../assets/img/other-process.png'), url('./../assets/img/other-process.png');
  }
}

.tradecard {
  &:nth-child(1) h2 {
    background-image: url('./../assets/img/trade-process.png'), url('./../assets/img/trade-process.png'), url('./../assets/img/trade-process.png');
  }

  &:nth-child(2) h2 {
    background-image: url('./../assets/img/open-tax.png'), url('./../assets/img/open-tax.png'), url('./../assets/img/open-tax.png');
  }

  &:nth-child(3) h2 {
    background-image: url('./../assets/img/close-tax.png'), url('./../assets/img/close-tax.png'), url('./../assets/img/close-tax.png');
  }

  &:nth-child(4) h2 {
    background-image: url('./../assets/img/tax-process.png'), url('./../assets/img/tax-process.png'), url('./../assets/img/tax-process.png');
  }

  &:nth-child(5) h2 {
    background-image: url('./../assets/img/other-process.png'), url('./../assets/img/other-process.png'), url('./../assets/img/other-process.png');
  }

}

.adminent {
  &:nth-child(1) h2 {
    background-image: url('../assets/img/ent-enterprise.png'), url('../assets/img/ent-enterprise.png'), url('../assets/img/ent-enterprise.png');
  }

  &:nth-child(2) h2 {
    background-image: url('../assets/img/corporate.png'), url('../assets/img/corporate.png'), url('../assets/img/corporate.png');
  }

  &:nth-child(3) h2 {
    background-image: url('../assets/img/ent-others.png'), url('../assets/img/ent-others.png'), url('../assets/img/ent-others.png');
  }

  &:nth-child(4) h2 {
    background-image: url('../assets/img/manager-report-delivery.png'), url('../assets/img/manager-report-delivery.png'), url('../assets/img/manager-report-delivery.png');
  }

}

.investorico {
  &:nth-child(1) h2 {
    background-image: url('../assets/img/investorico.png'), url('../assets/img/investorico.png'), url('../assets/img/investorico.png');
  }

}

// .form_datetime div + button{ display: none;}
.mat-button-toggle-label-content {
  line-height: 3.2em !important;
  padding: 0px 0px !important;
}

.p-float-label input:focus~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label,
.p-float-label .ng-invalid.ng-dirty+label {
  top: 0rem !important;
  font-size: 12px !important;
  background: #fff;
  padding: 2px 9px;
  color: #1976d2 !important
}

.pi-chevron-down:before {
  content: "\e902" !important;
  width: 0px;
  height: 0px;
  // border-left: 5px solid transparent;
  // border-right: 5px solid transparent;
  // border-top: 5px solid #757575; 
  // position: absolute;
}

path.st0 {
  fill: #444444 !important;
}

.st1 {
  fill: #1161BF !important;
}

.st2 {
  fill: #0000CC !important;
}

.st3 {
  fill: #1E1E1E !important;
}


#sroanimate {
  width: 25em;
}

.innerchart {
  -webkit-animation-name: sroinnerchart;
  -moz-animation-name: sroinnerchart;
  animation-name: sroinnerchart;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /* transform-origin: 0% 0%; */
  /* width: 37em; */
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes sroinnerchart {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes srocircle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.outercircle {
  -webkit-animation-name: srocircle;
  animation-name: srocircle;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /* transform-origin: 0% 0%; */
  /* width: 37em; */
  transform-origin: center;
  transform-box: fill-box;
}

// AG Grid customization according to theme START
.ag-theme-alpine .ag-root-wrapper,
.ag-theme-alpine .ag-header {
  border-color: #f4f4f4;
}

.ag-header-cell-text {
  color: rgba(0, 0, 0, .54);
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family);
}

.ag-theme-alpine .ag-row:hover {
  background-color: rgba(0, 0, 0, .04);
}

.ag-theme-alpine .ag-header-cell-resize::after {
  background: none;
}

.ag-theme-alpine .ag-root-wrapper,
.ag-theme-alpine .ag-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ag-theme-alpine .ag-row-odd {
  background: var(--ag-background-color, #fff);
}

.ag-cell-value,
.ag-group-value,
.p-dialog-content,
.content,
.p-datatable .p-datatable-tbody>tr>td,
.p-treetable .p-treetable-tbody>tr>td {
  font-family: var(--font-family);
}

// AG Grid customization according to theme END
// .mat-stepper-next.next {background: #1976d2;}
.card .actions.btn-cal {
  margin-top: 0;
  position: absolute;
  right: 0;
  top: -3.9em;
}

table.tableheader-remove thead {
  display: none;
}

.actions.workflownew {
  width: 100%;
  padding-top: 6px;
}

.mat-table.cdk-table .mat-header-row {
  top: -1px;
  z-index: 111;
}

.card .actions.btn-cal {
  z-index: 2;
}

#formstiky {
  overflow: initial;
}

.mat-stepper-vertical {
  border-radius: 12px;
}

.mat-step-header {
  position: sticky !important;
  top: 0;
  background: #fff;
  z-index: 1;
}

.mat-step-header:nth-child(1) {
  top: 0px;
  border-radius: 5px 5px 0px 0px;
  ;
}

.mat-step-header:nth-child(2) {
  top: 80px;
}

.mat-step-header:nth-child(3) {
  top: 150px;
}

.mat-button-toggle-disabled .mat-button-toggle-button {
  cursor: not-allowed !important;
}

.colorheader {
  background-color: #1976d2 !important;
  color: white !important;
  border-radius: 2px 2px 0px 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  left: .3235em !important;
}

// .fury-dark .mat-button-toggle-standalone, .fury-dark .mat-button-toggle-group{margin-right: 28px !important;}
.fury-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.fury-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffffff;
}

.cardrow .tradcard h2 {
  text-transform: lowercase;
  background-size: 12%;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: center right;
  background-position-x: 94%;
  background-blend-mode: multiply;
}

.cardrow .tradcard h2:first-line {
  text-transform: capitalize;
}

.clc-dailog .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  left: 0em;
}

.p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  left: 9em;
  margin: 0px auto;
  z-index: 101;
  min-width: 30em;
}

.p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header {
  background: #1976d2 !important;
}

input[type=file]::file-selector-button {
  border: 2px solid #1976d2 !important;
  padding: .2em .4em;
  border-radius: .2em !important;
  background-color: #1976d2;
  transition: 1s;
  color: #fff;
  cursor: pointer;
}

.splitgroupbtn .mat-focus-indicator.mat-menu-item {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0 0;
}

.sign-space a.caption {
  opacity: 1 !important;
}

.colorheader.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: white
}

.colorheader.mat-step-header .mat-step-icon-selected,
.colorheader.mat-step-header .mat-step-icon-state-done,
.colorheader.mat-step-header .mat-step-icon-state-edit {
  background-color: white !important;
  color: black !important;
}

span.stickyButton {
  position: absolute;
  right: 2.4em;
  top: 25%;
  text-align: right;
  width: auto;

  .mat-button-toggle-standalone,
  .mat-button-toggle-group {
    margin-right: 0;
  }
}

.colorheader .splitgroupbtn .mat-button-toggle {
  color: rgb(46, 45, 45);
  background-color: rgb(248, 239, 239);
  margin-bottom: -4px;
  margin-top: -4px;
}

.colorheader .splitgroupbtn .mat-button-toggle span.ui-text {
  color: rgb(46, 45, 45);
}

.colorheader .splitgroupbtn .mat-button-toggle .mat-menu-item .mat-icon {
  color: rgb(46, 45, 45);
}

.colorheader .mat-stepper-next.next {
  background-color: rgb(248, 239, 239) !important;
  color: rgb(46, 45, 45) !important;
}

.colorheader.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  cursor: not-allowed;
}

.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  cursor: not-allowed;
}

.blue_bg {
  background: #1976d2 !important;

  th {
    color: #fff;
  }
}

.table_text .p-datatable .p-datatable-thead>tr>th {
  font-weight: 400;
}

.p-datatable .p-datatable-thead>tr>th {
  height: 56px;
  background: #f5f5f5 !important;
  font-size: 12px;
}

.card-footer {
  color: #424242;
}

.outline_btn {
  color: #1976d2;
  background-color: transparent;
  background-image: none;
  border: 1px solid #1976d2;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  font-family: var(--font-family);
  border-radius: .25rem;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  height: 36px;
  margin-top: 0px;
  cursor: pointer;

  &:hover {
    background: #1976d2;
    color: #fff;
    transition: all 0.3s;
  }

}

// .tableheader-remove .example-element-row td:nth-child(1) {width:5%; padding-right: 0px !important;}
// .tableheader-remove .example-element-row td:nth-child(2) {width:20%;  padding-left: 12px !important;}
// .tableheader-remove .example-element-row td:nth-child(3) {width:20.5%;}
// .tableheader-remove .example-element-row td:nth-child(4) {width:20.25%;}
.input-numbers.card-btn-view .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0px 10px 0px 0px;
}

.input-numbers.card-btn-view .workflowheadinput .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin-right: 0px !important;
}

.input-numbers.card-btn-view .mat-form-field:nth-child(1) {
  padding-left: 0em !important;
  padding-right: 0em !important;
  z-index: 0;
}

.workflownew .mat-flat-button.mat-primary,
.workflownew .mat-raised-button.mat-primary,
.workflownew .mat-fab.mat-primary,
.workflownew .mat-mini-fab.mat-primary {
  vertical-align: text-top;
  margin-top: 0.1em;
}

.example-detail-row .grid-formui {
  padding-left: 2.5em !important;
}

.workflownew .mat-flat-button.mat-primary,
.workflownew .mat-raised-button.mat-primary,
.workflownew .mat-fab.mat-primary,
.workflownew .mat-mini-fab.mat-primary {
  margin-top: 0.32em
}

.batch-modal .p-dialog-content {
  overflow-y: inherit;
}

.batch-modal .p-multiselect-panel {
  margin-left: 0em;
}

.batch-modal .p-dialog .p-dialog-content {
  padding-top: 2em;
}

.batch-dailog-box .p-multiselect {
  width: 90%;
  ;
  margin-right: 1em;
  margin-left: 0.3em;
}

.batch-dailog-box .mat-form-field {
  width: 90.3%;
  margin-top: 3%;
}

.row.batch-dailog-box span.material-icons-outlined {
  cursor: pointer;
  font-size: 31px;
  vertical-align: middle;
}

.workflownew .mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0px;
}

.pl-2.pt-2.pb-2.workflow-check {
  padding-top: 16px;
  padding-left: 7px;
  width: auto;
}

// .fury-default fury-list .mat-row:hover {
//   background: transparent;
// }
.sio,
.sro {
  font-size: 11px;
  color: #fff;
  font-weight: 400;
  padding: 4px 7px;
  border-radius: 4px;
  border: 1px solid #fff;
}

.dropdown.createpop.open>.sidenavbttxt {
  opacity: 0;
}

.grid-formui .col-lg-3.workflowheadinput {
  width: 33% !important;
  padding-left: 15px;
  padding-right: 0px;
}

.grid-formui .col-lg-3.workflowheadinput.custominput {
  width: 25% !important;
  margin-top: 0.8em;
}

app-grid.oms-fullwidth fury-page-layout .fury-page-layout-content {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.mat-vertical-content {
  padding: 24px;
}

.user-agreement {
  display: flex;
  // position: relative;
  justify-content: flex-end;
  order: 2;
  margin-top: 2%;
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  border-radius: 4px !important;
  margin-right: -20%;
}

.grid-formui .col-lg-3.workflowheadinput .p-multiselect-label {
  padding: 0.5rem 1.4em !important;
  font-family: var(--font-family);
}

.grid-formui .col-lg-3.workflowheadinput .p-dropdown-label {
  padding: 0.5rem 1.4em !important;
  font-family: var(--font-family);
}

.grid-formui .col-lg-3.workflowheadinput .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.7em 0 0.7em 0.5em;

  .mat-form-field-label-wrapper {
    top: -1.2em;
    margin-left: -0.6em;
  }
}

.grid-formui .col-lg-3.workflowheadinput .p-float-label {
  font-family: var(--font-family);
  margin-left: 0.3em;
  font-size: 11px !important;
  margin-top: -4px;
  padding-top: 7px;
}

.grid-formui .col-lg-3.workflowheadinput .mat-form-field-label-wrapper .label-font {
  padding: 0 5px 0 15px;
}

.fury-default fury-list .mat-row:hover label.p-float-label {
  background: #f5f5f5
}

td.mat-column-expandedDetail {
  background: #f8f9fa;
}

.grid-formui .workflowheadinput label.p-float-label {
  background: white;
  padding: 0 10px;
}

.grid-formui .workflowheadinput .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.8em !important;
}

span.agfile_download {
  color: #73dc73;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

span.agvisibility {
  color: #212939;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

span.agclear {
  color: red;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

span.agtoggle_off {
  color: #212939;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

span.agtoggle_on {
  color: #212939;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

span.agomsvisibility {
  color: #212939;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

.material-icons-outlined.aggrid_view {
  color: #212939;
  font-weight: 500;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

.material-icons-outlined.agedit_note {
  color: #293040;
  font-weight: 600;
  cursor: pointer;
  font-size: 26px;
  margin-top: 14px;
  padding: 0px 5px;
  vertical-align: sub;
}

.material-icons-outlined.agomscancel {
  color: red;
  font-weight: 600;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

.material-icons-outlined.agdeleteall {
  color: red;
  font-weight: 600;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

.material-icons-outlined.rundailyprocess {
  color: green;
  font-weight: 600;
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

.material-icons-outlined.viewrowdatavisibility {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

.material-icons-outlined.viewrowfile_download {
  color: rgba(122, 0, 0, 0.54);
  cursor: pointer;
  font-size: 20px;
  margin-top: 14px;
  padding: 0px 5px;
}

// .workflow-label label.p-float-label {
//   background: #f5f5f5 !important;
// }
td.mat-column-expandedDetail .p-float-label {
  background: #f8f9fa;
}

.translatedrop.toolbar-button:hover select {
  background: #f5f5f5 !important;
}

.card .content {
  margin-top: 24px;
}

.pl-2.pt-2.pb-2.workflow-check.addBackUnduePerfFee {
  width: auto;
}

.left_attachment {
  padding-left: 5px !important;
}

.right_attachment {
  padding-left: 5px !important;
}

.right_attachment.pl-2,
.left_attachment.pl-2 {
  padding-left: 5px !important;
}

.leftwriting_attachment {
  padding-left: 7px !important;
}

.Rightwriting_attachment,
.leftwriting_attachment {
  padding-left: 7px !important;
}

.leftwriting_attachment {
  padding-left: 7px !important;
}

.checkmargin {
  margin-top: 0px !important;
}

.setGridHeight button {
  transform: translateY(0%) !important;
}

.setGridHeight button span img {
  padding-top: 15px !important;
}

.cardrow .tradcard:hover h2 {
  -webkit-transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -moz-transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -o-transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  background-size: 15%;
  transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  color: #1976d2;
  background-color: #ecf3f9;
  background-blend-mode: exclusion;
}

.workflownew {
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    top: 21px !important;
  }
}

.uploaddatarefresh {
  background-color: transparent !important;
  text-align: center;
  position: relative;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  align-items: center;
  text-align: center;
  align-content: center;
  color: blue;
  border-style: none;
}

.refreshNorml {
  background-color: transparent !important;
  text-align: center;
  position: relative;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  align-items: center;
  text-align: center;
  align-content: center;
  color: gray;
  border-style: none;
}

.refreshSpin {
  background-color: transparent !important;
  text-align: center;
  position: relative;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  align-items: center;
  text-align: center;
  align-content: center;
  border-style: none;
}

.complete-red {
  .ag-cell-wrapper .ag-cell-value {
    color: red;
  }

  .uploaddatarefresh {
    color: red;
    cursor: not-allowed
  }
}

.complete-gray {
  .ag-cell-wrapper .ag-cell-value {
    color: black;
  }

  background-color : rgb(228, 220, 220) !important;
}

.complete-green {
  .ag-cell-wrapper .ag-cell-value {
    color: green;
  }

  .uploaddatarefresh {
    color: green;
    cursor: not-allowed
  }
}

//START SDI-42 WorkFlow Tree Column Color Code
.successDisposition {
  color: green;
}

.errorDisposition {
  color: red;
}

.uploadDisposition {
  color: blue;
}

.downDisposition {
  color: blue;
}

//END SDI-42 WorkFlow Tree Column Color Code

.divScroll {
  margin-bottom: 8px;
  color: blueviolet;
  height: 200px;
  overflow-y: scroll;
}

.hide_dialog {
  animation: cssAnimation 0s 2s forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}

fury-sidenav.collapsed .sidenav fury-scrollbar.fury-scrollbar.sidenav-items .setting_faq span.sidenavbttxt {
  opacity: 0;
}

fury-sidenav.expanded .sidenav fury-scrollbar.fury-scrollbar.sidenav-items .setting_faq span.sidenavbttxt {
  opacity: 1;
}

.workflowheadinput .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.fury-dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  top: 2.2em !important;
  left: 0.5em;
}

.workflowheadinput .mat-form-field-label-wrapper .label-font {
  padding: 0 0px 0 10px;
}

.p-component:disabled {
  cursor: not-allowed !important;
}

// .mat-select-panel:not([class*=mat-elevation-z]) {
//   box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 10px 0px rgb(0 0 0 / 4%);
//   min-width: calc(100% + 12px) !important;
//   margin-left: 12px !important;
//   top: 2em !important;
//   position: relative;
// }
.mat-select-panel-wrap {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 10px 0px rgb(0 0 0 / 4%);
  min-width: calc(100% + 12px) !important;
  margin-left: 12px !important;
  top: 2em !important;
  position: relative;
  width: 10vw;
  // overflow: auto;
}

.mat-select-search-inner {
  width: 100% !important;
}

.padL10 {
  mat-radio-group {
    margin-top: 0px !important;
    margin-left: 10px;
    width: 100%;
    display: block;
  }
}

.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  padding-left: 0.75em;
}

.mat-select-value-text {
  padding-left: 0.75em;
}

.reportcount {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5em;
  font-family: var(--font-family);
}

.fury-dark .label-font {
  padding: 0 0.75em;
  color: #929292;
}

.reportcount {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5em;
  font-family: var(--font-family);
}

.p-inputnumber input {
  padding-left: 1.06em !important;
}

.p-multiselect-label {
  padding-left: 1.06em !important;
}

.p-dropdown-label {
  padding-left: 1.06em !important;
}

button.blueColor u {
  color: #1976d2;
}

.btn-logout,
.btn-stay {
  margin: 0 5px;
  padding: 8px 15px;
  background: #1976d2;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.sessionmodal .p-dialog-header-icon.p-dialog-header-close {
  display: none !important;
}

.login_body {
  overflow: hidden !important;



}

.login-footer1 {
  font-family: (var(--font-family));
  font-size: 11px;
  grid-template-columns: 1fr;
  position: relative;
  text-align: center !important;
  display: initial !important;
  bottom: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

.login-page-component .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0) !important;
}

svg {

  #Rectangle_685,
  #Rectangle_688,
  #Rectangle_686,
  #Rectangle_687,
  #Rectangle_689,
  #Rectangle_690,
  #Rectangle_691 {
    fill: #003750;
  }
}


.table-scroll .p-datatable-scrollable-header-box {
  overflow-x: scroll !important;
}

.p-tabview-btn button.p-button.p-component.p-ripple {
  border-radius: 0px !important;
}

.p-tabview-btn button.p-disabled {
  border: 1px solid #495057;
  cursor: not-allowed !important;
  color: #495057;
  background: #fff;
  border-left: 0px;
}

.executework-flowrun .p-dialog-content {
  padding: 1em 1.5em 2em 1.5em !important;
}

.executework-flowrun .p-dialog-footer {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
}

.custom_calende_css .p-datepicker table td {
  padding: 0rem !important;
}

.executework-flowrun .p-dialog-content mat-label,
.executework-flowrun span.mat-checkbox-label,
span.stickyButton .splitgroupbtn.mat-button-toggle-group.mr08 {
  margin-right: 0.8em;
}

.executework-flowrun .p-dialog .p-dialog-footer {
  background: #fff;
  display: flex !important;
  position: relative;
  justify-content: flex-end !important;
}

// .executework-flowrun .p-dialog .p-dialog-content{
//   min-height: 5em !important;
// }
.executework-flowrun table.mat-table {
  margin-top: 1em;
  width: 100%;
}

.executework-flowrun th.mat-header-cell {
  background: #f4f4f4;
}

.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem !important;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  word-break: break-all !important;
}

.p-fileupload .p-fileupload-row>div {
  padding: 0rem 1rem !important;
}

.p-fileupload-row>div {
  width: auto !important;
}

.executework-flowrun .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  min-width: 46em;

  .p-calendar {
    display: inline-flex;
    position: relative;
    width: 100%;
    padding: 10px 0;
  }

  .mat-checkbox-layout {
    //margin-left: -63px;
    padding: 10px 0;
    display: flex;
  }
}

.Dialog_width_Large {
  width: 80vw;
}

.Dialog_width_Medium {
  width: 60vw;
}

.Dialog_width_Small {
  width: 30vw;
}

.text-align-right div span {
  margin-left: auto;
}

.Investor_portal .p-multiselect,
.Investor_portal .p-dropdown {
  width: 99%;
  background: transparent !important;
  left: .3235em !important;
}

.Investor_portal .p-multiselect .p-multiselect-trigger,
.Investor_portal .p-dropdown .p-dropdown-trigger {
  width: 3.344rem !important;
}

.notesandcomments div#fund mat-form-field .mat-form-field-wrapper {
  width: 82%;
  margin: auto !important;
  float: none;
}


.dt_widh {
  width: 100%;

  .mat-form-field-flex {
    background: #fff;
  }
}

.p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-footer button {
  height: auto;
}

.content-container {
  overflow-y: auto !important;
  overflow-x: hidden;
}

// .mat-drawer.mat-drawer-side
// {
//   z-index: 1 !important;
// }

.uploadbtn_alignment .splitgroupbtn {
  height: 36px !important;
  align-items: center !important;
  line-height: 5;
  margin-right: 0px;
}

// System Processing Play Icon Run & Cancel button
.runcancel_button .p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-footer button {
  height: 36px !important;
  width: 6em !important;
  text-align: center;
}

.runcancel_button .p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 1.5rem 1.5rem 2rem 1.5rem !important;
  min-height: 0 !important;
}

fury-card.fury-card.basic-forms.route-animations-elements .mat-linkexprot {
  position: absolute;
  right: 90px;
  top: 11px;
}

.mat-menu-panel.matmenu-dropdown {
  right: 0px;
}

nav.mat-tab-nav-bar.mat-tab-header.matheader-spc {
  margin-bottom: 1.2em;
}

nav.mat-tab-nav-bar.mat-tab-header.itegrityheader-spc {
  margin-bottom: 0px;
}

.input-append.date.form_datetime.custom_calende_css.sequence-spc {
  margin-left: 1em;
}

.example-button-row.tab-menu-calculate.downloadbtn {
  margin-top: 3px;
}

.card.lookup-spc {
  margin: 0px 20px;
}

.leftwriting_attachment.otp-verifie .label-font {
  padding-left: 0px !important;
}

.status-verifie {
  padding-left: 0px !important;
}

.status-verifie .p-togglebutton.p-button.p-highlight {
  margin-top: 10px !important;
}

.p-fileupload.p-fileupload-advanced h3 {
  margin: 0px;
}


// Enterprises doc management input fields 
.category_input .categoryfund_input .mat-form-field-appearance-fill .mat-form-field-infix {
  padding-top: 0em !important;
}

// .Enterprises manager-accounting/performance Analytics Investor-Input alignment overlap issue
.analytics_input {
  .p-multiselect {
    cursor: pointer;
    display: flex !important;
    position: inherit;
    user-select: none;
  }

  .p-dropdown {
    cursor: pointer;
    display: flex !important;
    position: inherit;
    user-select: none;
  }

  .p-float-label label {
    position: absolute;
    pointer-events: none;
    top: 50%;
    margin-top: -1.3rem !important;
    transition-property: all;
    transition-timing-function: ease;
    line-height: 1;
    white-space: nowrap;
  }
}

.analytics_input {

  .p-float-label input:focus~label,
  .p-float-label input.p-filled~label,
  .p-float-label textarea:focus~label,
  .p-float-label textarea.p-filled~label,
  .p-float-label .p-inputwrapper-focus~label,
  .p-float-label .p-inputwrapper-filled~label {
    margin-top: -8px !important;
  }
}

// END Enterprises manager-accounting/performance Analytics Investor-Input alignment overlap issue

.gen-password {
  display: flow-root;
  margin-left: -3.5em;

  p {
    margin: 0em !important;

    ul {
      margin: 0px;
      padding-inline-start: 1.2em;
    }
  }
}

.confirm_undo {
  .p-dialog .p-dialog-content {
    min-height: 6em !important;
  }
}

.cardrow .tradcard .bodytext ul li .iconset span:hover {
  color: #212939;
  box-shadow: 0 0 5px #212939;

  border-radius: 50%;

  animation: pop .3s 2;
  //  padding:0.1rem ;
}

@keyframes pop {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

p-table {
  height: 0vh !important;

  cdk-virtual-scroll-viewport.cdk-virtual-scroll-viewport.p-datatable-virtual-scrollable-body {
    height: calc(100vh - 42.5vh) !important;
  }
}

p-table.p-table-sourcedetails .p-datatable-scrollable-body {
  overflow-y: scroll;
  height: auto;
  min-height: calc(100vh - 85vh);
  max-height: calc(100vh - 60vh);
}

// OMS Approved order Popup Design issue on desktop
.omstable_text {
  .p-datatable .p-datatable-tbody>tr>td {
    border-width: thin !important;
    border-right: 1px solid #e9ecef !important;
  }
}

.omstable_text .p-datatable .p-datatable-thead>tr>th {
  font-weight: 400;
  border-right: 1px solid #e9ecef !important;
}

// Processing Page Configure and Execute Detailed View Run Status (Play icon)Modal Popup Button and input alignment issue fixed
.runcancel_button .dialogbox_input .mat-form-field {
  display: inline;
  position: relative;
}

.dialogbox_input .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.workflowicotog {
  display: contents !important;

  .mat-button-toggle-button {
    background: #fff;
    width: 0em;

    &:hover {
      background: #f9f8fa;

      .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
        opacity: 0;
      }
    }
  }
}

.invest-1 {
  padding-top: 3px !important;
}

.border-1 {
  border-bottom: #e0e0e0 1px solid;
  padding-bottom: 5px;
  padding-top: 9px;

}


// Workflow tree input calender popup issue
.mat-datepicker-content-touch .mat-calendar {
  width: 290px !important;
  height: 360px !important;
}

// Notes and comments Dialog box width & height
.Dialog_width_ExtraSmall {
  .p-dialog .p-dialog-content {
    min-height: 5vw !important;
    padding-bottom: 0em !important;
  }

  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    left: 9em;
    margin: 0px auto;
    z-index: 101;
    min-width: 18vw !important;
    text-align: center;
  }

}

// Report Notification Dialog box width & height
.Dialogbox_small {
  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    width: 20vw !important;
  }

  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    min-width: 25em !important;
  }

  .p-dialog .p-dialog-content {
    min-height: 6em !important;
    padding-bottom: 0em !important;
  }
}

.description-input {
  padding-left: 0em !important;
  padding-right: 0em !important;
}

.create-order-input label.p-float-label {

  padding-left: 10px;
}

.p-treetable.treetable_text table {
  table-layout: auto !important;
}

.p-treetable .p-treetable-wrapper table {
  table-layout: auto !important;
  overflow-x: auto;

  td:first-child {
    //width:calc(100vw - 65vw);
    display: flex;
  }
}

.workflow_run-c-files .p-datatable-gridlines.p-datatable.p-component {
  padding: 1em 0em 1em 0em;
}


// configure-execute-entry input placeholder alignment 
.configure-execute .mat-form-field-appearance-outline .mat-form-field-infix {
  margin-left: -8px !important;
}

.configure-execute.edit-form-margin .mat-form-field-appearance-outline .mat-form-field-infix {
  margin-left: 0px !important;
}

// configure-execute-entry input placeholder alignment end

//  Investor Create icon Next button color issue on blue header
.next_btn {
  .colorheader button.mat-focus-indicator.next.mat-raised-button.mat-button-base {
    background-color: #f8efef;
    color: #2e2d2d;
  }
}

// Investor Group Create icon Save button color issue on blue header 
.btn_save {

  .colorheader button:disabled,
  button[disabled] {
    background-color: white !important;
  }

  .colorheader span.ui-text {
    color: black !important;
    text-transform: uppercase;
    font-weight: 500;
  }
}

// Investor Create icon Fetch Button and input alignments issue
.createca_discapital {
  .newbtn button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary {
    height: 33px;
    line-height: 33px;
    margin: 0px 0 0 6px !important;
    // border-radius: 4px !important;
  }

  .createca_ttaxable {
    clear: both;
    width: 100%;
    display: table-cell;
  }
}

// Investor Create icon Fetch Button and input alignments issue End

// .portfolio_body{

//    .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing { 
//   opacity: 1; 
//   background: rgba(0, 0, 0, 0.4);
// } 

//  .cdk-overlay-connected-position-bounding-box .mat-menu-panel { 
//   position: fixed; 
//   left: 50%; 
//   top: 40%; 

// } 
// }
// Run cash recon multi & run cash recon & Run Top recon input alignment issue
.newfundalign {
  padding-left: 5px !important;
  padding-right: 10px;
  margin-bottom: 20px;
}

.newfund_align {
  padding-left: 5px !important;
  padding-right: 10px;
  padding-top: 0px !important;
}

.runcash_recon {
  padding-left: 12px;
  margin-left: 8px;
}

.newfund_align {

  .p-multiselect,
  .p-dropdown {
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    user-select: none;
    height: 46px !important;
    border-radius: 6px !important;
  }
}

.runc_recon {
  padding-top: 0px !important;
}

.runc_recon {

  .p-multiselect,
  .p-dropdown {
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    user-select: none;
    height: 46px !important;
    border-radius: 6px !important;
  }
}

// END Run cash recon multi & run cash recon & Run Top recon input alignment issue

//  Fetch market data failed custom table  
market-data-button input {
  background-color: var(--ag-background-color, #fff);
  min-width: 40%;
  height: 40%;
  vertical-align: middle;
}

.customHeaderMenuButton {
  float: left;
  margin: 0px 5px 0px 0px !important;
  background-color: #1976d2;
  height: 30px;
  color: #fff;
  border: 0;
  border-radius: 3px;
}

app-custom-header {
  display: flex;
  align-items: baseline;
}

.market-gridview input {
  height: 35%;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  min-height: 2.3em;
  font-size: 13px;
  padding: 0.5em;
}

app-custom-header h6 span.material-icons-outlined {
  vertical-align: middle;
  font-size: 16px;
}

// END Fetch market data failed custom table  

.execute.disable button.p-button.p-component.p-ripple {
  cursor: not-allowed;
}

.agfocus_body .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-focus.ag-cell-range-selected.ag-cell-range-selected-1.ag-cell-range-single-cell {
  background: none !important;
  border: none !important;
}

.row.batch-dailog-box .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 0.5em 0;
}

.row.batch-dailog-box .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0.25em 0;
  padding-top: 1em;
}

p-button.closex button {
  margin-right: 0;
  background: #e9ecef;
  color: #495057;
  border-radius: 50%;
  border: none;
  position: absolute;
  right: 1em;
  top: 0.6em;
  width: 1.9em !important;
  height: 1.9em;
}

.breadcrumbinfo .p-dialog-content {
  height: calc(100vh - 30vh);
}

.breadcrumbinfo .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  width: calc(100vw - 54vw);
  height: auto;
  top: 0em;
  background: #fff;
}

.breadcrumbinfo .p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header {
  display: none !important;
}

.introducings.breadcrumbinfo .p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header {
  display: flex !important;
}

.breadcrumbinfo .p-dialog .p-dialog-content {
  background: #fff !important;
  padding: 0em !important;
  height: calc(100vh - 15vh);
  overflow-x: hidden;
}

#formstiky span.stickyButton {
  right: 4.4em;
}

.instructionmodal {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  height: 0em;

  #formstiky {
    margin-left: 0% !important;
    margin-top: 0px;
    margin-bottom: 0em !important;
  }

  .mat-stepper-vertical {
    width: 46vw;
    border-radius: 0px;

    .mat-step-header {
      background: #f3f3f3;
      border-radius: 0px;
      border-top: 2px solid #fff;
    }

    .mat-vertical-content-container {
      background: #fbfbfb;
    }
  }

  .instructioniframe {
    width: 100%;
    display: flex !important;
    justify-content: center !important;

    iframe {
      height: calc(100vh - 64vh);
    }

    video {
      height: calc(100vh - 64vh);
    }
  }

  .instructiontable {
    width: 100%;
    text-transform: initial;
    overflow: scroll;
  }
}

.stickyButton .mat-icon.infoico.closex {
  right: 0em;
  top: 0.5em;
  color: #fff;
  font-size: 20px;
}

.breadcrumbinfot {
  width: 100%;
  margin-top: 1em;

  thead {
    background: #f5f5f5;

    th {
      border: 1px solid #e7e7e7;
      padding: 1em !important;
      font-weight: 600;
      color: #495057;
    }
  }

  td {
    border: 1px solid #e7e7e7 !important;
    padding: 1em !important;
    white-space: normal;
  }
}

.breadcrumbinfot.first1 tbody {
  display: none;
}

// Doc Management input category text alignment issue 
.doccategory_input {
  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.75em !important;
    margin-top: 0em !important;
    margin-left: 10px !important;
  }
}

// Doc Management input category text alignment issue 

// Investor Portal user create icon investor input alignment issue
.investorp_user {

  .p-multiselect,
  .p-dropdown {
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    user-select: none;
    margin-left: -20px;
    border-radius: 6px;
    width: -webkit-fill-available;
    margin-right: 10px;
    height: 47px;
  }

  .p-float-label>label {
    left: -5px;
  }
}

// END Investor Portal user create icon investor input alignment issue

// Enterprises manager Doc management category & fund input placeholder text color issue
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  z-index: 1;
  position: absolute;
  top: 23px !important;
  // background: #424242;
  width: auto;
  color: #1976d2 !important;
  height: auto;
  padding: 2px 4px;
}

// END Enterprises manager Doc management category & fund input placeholder text color issue

// Accounting setting Asset based fees create icon input alignment issue
.assetb_fees {
  padding-left: 0 !important;
  width: 47.33% !important;
  margin-left: 7px !important;
  padding-top: 0 !important;
}

// END  Accounting setting Asset based fees create icon input alignment issue
// Accounting setting Hurdle /Tier Incentive Setup create icon input alignment & checbox issue
.hpi_hurderbase {
  margin-left: 7.33em !important;
}

.hti_setup {
  white-space: nowrap;
}

// END Accounting setting Hurdle /Tier Incentive Setup create icon input alignment & checbox issue

// Trade Reconciliation Run trade recon & Run multi recon input alignment issue
.rt_recon {
  padding-top: 0 !important;
  margin-left: -17px !important;
}

.rm_recon {
  margin-top: 24px;
}

.rmr_recon {
  margin-top: -4.5em;
}

.rmr_saccount {
  padding-left: 5px !important;
  padding-top: 0 !important;
  padding-right: 10px !important;
}

// END Trade Reconciliation Run trade recon & Run multi recon input alignment issue
.padding .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

.forgot_body {
  overflow: hidden;
}

.generate_body {
  overflow: hidden;
}

.workflow-scroll .fury-list-header {
  position: sticky;
  top: -0.1em;
  z-index: 1;
  // box-shadow: 0 0px 10px 0 rgb(0 0 0 / 12%);
}

.workflow-scroll {
  #brokerAccount {
    padding-bottom: 20px;
  }

  #brokerAccountRunTax {
    padding-left: 5px;
    padding-right: 2em;
  }

  #custodianBrokerList {
    padding-right: 2em;
  }

  #fundlist {
    padding-top: 0px;
  }
}

.report-delivery-card .mat-tab-body-wrapper {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

// button.btn-stay {
//   position: relative;}
fury-recon-trade-manually fury-list:first-child {
  margin-bottom: 5em !important;
}

.col-lg-3.downloadfund {
  display: flex;
  position: relative;
  top: 0.7em
}

.bouncebox {
  // animation-duration: 2s;
  // animation-iteration-count: infinite;

  transform-origin: bottom;
  // position: absolute !important;
  margin-left: auto;
  max-width: 238px;
  margin-top: -2.6em;

}

.text-left {
  text-align-last: left;
}

.text-right {
  text-align: right
}

//   .icobounce {
//     animation-name: icobounce;
//     animation-timing-function: ease;
// }

//   .icobounce {
//     // animation-name: icobounce;
//     // animation-timing-function: ease;
// }
// @keyframes icobounce {
//     0%   { transform: scale(1,1)    translateY(0); }
//     10%  { transform: scale(1,1) translateY(0); }
//     30%  { transform: scale(1,1) translateY(-15px); }
//     50%  { transform: scale(1,1)    translateY(0); }
//     100% { transform: scale(1,1)    translateY(0); }
// }
.queryt-custom {
  // border: solid 1px #f5f5f5;
  border-collapse: inherit;
  border-spacing: 0;
  width: 100%;
  font-family: var(--font-family);
  border-right: none;
  border-left: none;

  thead {
    th {
      background: #f7f7f7;
      padding: 1em;
      // border: 1px solid rgba(0, 0, 0, 0.08);
      color: #212121;
      text-align: left;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .accordionh {
    background: #eef8ff !important;
  }

  .mainhead>th {
    background: #1976d2;
    color: #fff;
    padding: 1em 0.5em 1em 0.5em;
    border-left: 1px solid #2261a0;
  }

  .submainhead>th {
    background: #f7f7f7;
    color: #212121;
    border-bottom: 1px solid #fff;
    ;
  }

  .subhead>th {
    background: #f7f7f7;
    color: #212121;
  }

  .subheadtotal>th {
    background: lightyellow;
  }

  tbody {
    td {
      border: 1px solid #f5f5f5;
      color: #333;
      padding: 10px;
      box-shadow: none;
      text-shadow: none;
      background: none;
      background-color: #fff;
    }

  }
}

.querycardinner {
  margin: 4em;
  margin-top: 1em;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #283144;
  color: #fff;

  h4 {
    font-weight: 600;
    padding-left: 1em;
  }
}

.btrack {
  .btrackform {
    //display: flex;
    margin-top: 1em;

    mat-form-field {
      margin-right: 1em;
    }
  }

}

.bdetailsp {

  white-space: normal;
  width: 100%;
}

.btracknav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 0em 0.5em 0em;

}

.queryt-custom .p-datatable-scrollable-header-box {
  padding-right: 0px !important;
  //overflow-x: auto;
}

.queryt-custom .p-datatable table {
  table-layout: auto !important;
}

.h2twoline {
  display: inherit;
  width: 16em;
  line-height: 2em;
  text-transform: capitalize;
}

.capital-text {
  text-transform: capitalize;
}

.right {
  text-align: right
}

.red-text {
  color: red;
}

.black-text {
  color: black;
}

.accordion {
  .add {
    float: right;
  }

  .remove {
    float: right;
  }
}

.querypt3 {
  padding-top: 3px;
}

.queryinvest {
  padding-left: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 24px !important;
  padding-right: 24px !important;
}

.roundTurnPerMillion {
  padding-left: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 24px !important;
  padding-right: 10px !important;
}

mat-form-field.mat-form-field.multiemail .mat-form-field-infix {
  padding: 0.2em 0em;
}

.paddingt5 {
  padding-top: 10px;
}

.introductionp {
  padding-top: 2em;

  iframe {
    height: 100vh;
  }
}

.intromodal {
  .p-dialog-content {
    min-height: 25em;
    padding: 0em 1.5em !important;
  }

  .introductionv {
    padding-top: 0em;
    // padding-bottom:2em;
    display: flex;
    flex-direction: row;

    .heading {
      padding-top: 1em;
      font-size: 14px;
      font-weight: 500;
      font-family: var(--font-family);
      background: #f4f4f4;
      margin: 0px;
      margin-top: -1em;
      text-align: center;
      padding-bottom: 0.5em;
      width: 96.5%;
    }

    iframe {
      padding: 1em;
      padding-bottom: 0em;
      padding-left: 0em;
    }

    video {
      padding: 1em;
      padding-bottom: 0em;
      padding-left: 0em;
    }
  }

}

.intromodalseamless {
  .p-dialog-content {
    min-height: 25em;
  }

  .introductionv {
    padding-top: 2em;
    // padding-bottom:2em;
    display: flex;
    flex-direction: row;

    // min-width: 62rem;
    .heading {
      padding-top: 1em;
      font-size: 14px;
      font-weight: 500;
      font-family: var(--font-family);
      background: #f4f4f4;
      margin: 0px;
      margin-top: -1em;
      text-align: center;
      padding-bottom: 0.5em;
      width: 96.5%;
    }

    iframe {
      padding: 1em;
      padding-bottom: 0em;
      padding-left: 0em;
    }

    video {
      padding: 1em;
      padding-bottom: 0em;
      padding-left: 0em;
    }
  }
}

fury-sidenav.collapsed.expanded {
  .simplebar-content-wrapper {
    height: calc(100vh - 14vh) !important;
    background: transparent;
  }

  .setting_faq {
    position: sticky !important;
  }

}

fury-sidenav.collapsed {
  .simplebar-content-wrapper {
    height: 100% !important;
  }

  .setting_faq {
    position: sticky !important;
  }
}

.workflowicotog span.mat-button-toggle-label-content {
  padding-left: 0px;
}

mat-button-toggle-group.mat-button-toggle-group.workflowicotog.pl-3.mat-button-toggle-group-appearance-standard .mat-icon {
  // padding-left: 7px !important;
  font-size: 31px;
}

mat-button-toggle-group.mat-button-toggle-group.workflowicotog.innerexport.mat-button-toggle-group-appearance-standard .mat-icon {
  font-size: 31px
}

// li.p-multiselect-item.p-ripple span {
//   white-space: pre-line;
//   line-break: anywhere;
// }
span.stickyButton.button-crypto mat-icon {
  width: 16px;
  height: 16px;
}

span.stickyButton.button-crypto mat-icon.material-icons-outlined {
  font-size: 16px;
}

.p-multiselect-items-wrapper {
  display: flex;
}

.p-dropdown-items-wrapper {
  display: flex;
}

.mat-option-text {
  text-overflow: inherit !important;
  overflow: initial !important;
}

.mat-titleheader {
  background: #1976d2;
  padding: 1.5em;
  margin: 0px;
  position: relative;
  display: flex;
  margin-top: -1.35em;
  margin-left: -1.35em;
  margin-right: -1.35em;
  color: #fff;
  font-size: 1.25rem;
  text-transform: capitalize;
  font-family: var(--font-family);
  font-weight: 500;
}

.mat-radio-button .mat-radio-label {
  margin-bottom: 0.5em;
}

.row.twopart-input mat-form-field {
  width: 46%;
  margin-right: 3em !important;
}

.row.twopart-input mat-form-field:last-child {
  width: 46%;
  margin-right: 0em !important;
}


.ml0-mr0 {
  margin-right: 10px;
  margin-left: 10px;
}

.mt0 {
  margin-top: 0;
}

.ml1 {
  margin-left: 1px;
}

.bgwhite {
  background: #fff;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.queryth {
  background: #283144;
  margin: 0 0px !important;
  padding: 8px 10px;
  color: #fff;
  font-weight: 600;
}

.casummer {
  background: #fff;
  margin: 0px;
  border-bottom: none;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px 10px;
}


.mat-option.mat-active,
.mat-option:focus:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled) {
  background: #c7e7f3;
  // overflow-x: auto;
  // padding-top: 12px;
}

.w-100 {
  width: 100%;
}

.p-multiselect-items-wrapper {
  .p-multiselect-items {
    width: 100%;
  }
}

.p-dropdown-items-wrapper {
  .p-dropdown-items {
    width: 100%;
  }
}

.nots {
  padding: 10px;
  margin-left: 35px;
  margin-bottom: 25px;
}

.toastcust {
  width: auto;
  max-width: calc(100vw - 69vw);
}

.p-toast {
  z-index: 100007 !important;
}

.splitgroupbtn .splitbtn .mat-icon.notranslate.material-icons-outlined {
  right: 0px !important;
}

.splitgroupbtn .mat-button-toggle span.ui-text {
  font-weight: normal !important;
}

.batchpad {
  padding-left: 1em;
}

#repoQuantity,
#netConsideration,
#repoRate,
.tradeprice,
.principalAmount {
  padding-bottom: 0.2em;
  margin-bottom: 0em !important;
}

.p-dialog .p-dialog-footer {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  text-transform: capitalize;
  white-space: nowrap;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-transform: capitalize;
  white-space: nowrap;
}

// .p-multiselect-item {
//   overflow: visible !important;
// }
.p-multiselect-items-wrapper .p-multiselect-items {
  display: grid !important;
}

.p-dropdown-items-wrapper .p-dropdown-items {
  display: grid !important;
}

// .p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover{
//   overflow-x: auto;
// }
.lookup_body .mat-step-header {
  position: relative !important;
}

// .ag-theme-alpine .ag-ltr .ag-cell {
//   border-right: solid 1px #dde2eb !important;
// }
.ag-header-cell {
  border-right: 1px solid #dde2eb;
}

.dailyprocess_body .ag-row .ag-cell:nth-child(3) {
  display: flex;
  justify-content: center;
}

.brokerpad {
  padding: 0px 10px 0px 5px !important;
}

.mat-select-panel {
  min-width: 100% !important;
}

.report-batchissue .batch-dailog-box .mat-form-field {
  margin-top: 0px;
}

.report-batchissue .row.batch-dailog-box .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0em !important;
  padding-top: 0px;
}

.report-batchissue .batch-dailog-box .p-multiselect {
  margin-right: 0em;
  margin-bottom: 1em;
}

.report-batchissue .batch-dailog-box .p-dropdown {
  margin-right: 0em;
  margin-bottom: 1em;
}

.report-batchissue .row.batch-dailog-box .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.8em 0em;
}

.report-batchissue .mat-form-field-appearance-outline .mat-form-field-flex {
  padding-right: 0em;
}

// .login_body .mat-input-element{
//   padding-left: 0em !important;}

// .report-batchissue .mat-select{width: 73%;}
#status {
  span {
    display: flex;
    align-items: center;
  }

  label {
    margin-right: 10px;
  }

  .p-button {
    padding: 0.1rem 1rem 0.2rem 1rem;
  }
}

.other-comment {
  mat-form-field {
    margin: 0 15px;
  }
}

.lookup-table {
  table {
    margin-top: -0.5em;
    box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%), 0 4px 18px 3px rgb(0 0 0 / 12%) !important;
  }
}

.login_body svg.spinner.center {
  margin-left: -4em !important;
}

.cardrow .tradcard .bodytext ul li:hover .iconset {
  display: block;
  white-space: nowrap;
  //display: flex;
  align-items: center;
  //padding-top: 3px;
}

.removeSecondRow tbody tr:nth-child(3) {
  display: none;
}

input.mat-input-element,
.mat-form-field-infix textarea.mat-input-element {
  padding-left: 0.75em;
}

input.mat-input-element.inputpad10 {
  padding-left: 0em;
}

.mat-select-placeholder,
.p-float-label label {
  padding-left: 10px;
}

.ag-header-icon.ag-header-cell-menu-button {
  // opacity: 1 !important;
  display: inline-flex;
  position: absolute;
  padding: 0.5em 4% 0.5em 96%;
  right: 0em;
  top: 0em;
  cursor: pointer;
}

.ag-cell-value,
.ag-group-value,
.ag-cell-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-tabview-btn button.p-button.p-component.p-ripple {
  border-radius: 0px !important;
}

.p-tabview-btn button.p-disabled {
  border: 1px solid #495057;
  cursor: not-allowed !important;
  color: #495057;
  background: #fff;
  border-left: 0px;
}

button.mat-focus-indicator.processbtnd.mat-raised-button.mat-button-base.mat-primary {
  margin: 0em 0em 0em 0.5em !important;
}

.fury-page-layout-header.test fury-breadcrumbs {
  width: 100% !important;
}


.ifram-card {
  margin-top: 20px !important;
}

// Trades page Reset & Run open taxlots view icon card height issue
.mcustomheight {
  .breadcrumbinfo .p-dialog .p-dialog-content {
    height: calc(100vh - 45.4vh);

  }
}

.splitgroupbtn .mat-button-toggle .mat-menu-item .mat-icon {
  padding: 0px 0px 4px 10px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  display: none !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  width: 90%;
  margin: 0 0 0 0 !important;
  word-break: break-word;
}

.batchedit {
  .p-dialog-header-icon {
    display: none !important;

    &:hover {
      display: none !important;
    }
  }

  .refresh {
    position: absolute;
    right: 1.2em;
    top: 0.85em;
    color: #fff;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 5px #212939;
      border-radius: 50%;
      -webkit-animation: pop .3s 2;
      animation: pop .3s 2;
      border: 2px solid #fff;
      font-size: 1.6rem;
      top: 0.85em;
    }
  }
}

.reportingsvg {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  //margin-top: calc(100vh - 50em);
  cursor: pointer;
  padding: 2em 4em;
  // width: 75%;
  flex-direction: column;
  text-decoration: none;
}

h3.svgclicktext {
  font-size: 1.6em;
  text-transform: none;
  text-decoration: auto;
  margin: 0;
  font-style: italic;
  font-weight: 800;
  font-family: 'open sans';
  text-align: center;
  margin-bottom: 1.6em;
}

h3.svgclicktext a {
  text-decoration: none;
}

h3.svgclicktext.ortext {
  text-transform: lowercase;
}

h3.svgclicktext span {
  color: #1976d2;
  font-size: 1.2em;
  text-decoration: none;
  text-transform: none;
}

h3.svgclicktext span:hover {
  color: #1262b1;
}

//START Iframe issue reported chrome extension
.kw-iframe.kw-iframe-dropdown {
  transform: translate(24.7em, -4.9em) !important;
}

.fury-card-content.cryptointegrate {
  padding: 10px 24px 10px 24px;

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      left: 0em !important;
    }
  }
}

.fetchlivedata {
  .mat-form-field-outline {
    left: 0em !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper,
  .p-dialog .p-dialog-footer button {
    margin: 0 0px 0 0 !important;
  }
}

.time-container {
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 1em 0em !important;
  }

  input.mat-input-element {
    padding-left: 0em !important;
  }
}

.inputdate-field {
  .datefields-view {
    width: 100%;

    .mat-form-field-flex {
      background-color: transparent !important;
    }

    .mat-button-wrapper {
      padding: 0px 0 !important;
    }
  }
}

.inputdate-field .datefields-view:first-child {
  margin-right: 1.5em;
}

.inputdate-field .datefields-view {
  width: 50%;
}

.datefields-view {
  padding-left: 0;
}

.dynamicinput .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

//END Iframe issue reported chrome extension
.p-multiselect-label,
.p-dropdown-label {
  padding: 0.70rem !important;
  padding-left: 1.06em !important;
}

.buttononheader {
  position: absolute !important;
  top: 1.2em;
  right: 6em;

  button {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.87) !important;

    &:first-child {
      margin-right: 1em;
    }
  }
}

.fetchmarket .mat-stepper-vertical-line::before {
  border-left-style: none;
}

// fee detail form label fixed top and remove placeholder
.fee_details label.mat-form-field-label {
  z-index: 999;
  position: absolute;
  top: 23px !important;
  width: auto;
  color: #1976d2 !important;
  height: auto;
  padding: 2px 4px;
  transform: translateY(-1.59375em) scale(0.75);
}

.fee_details .label-font {
  background: #fff;
}

.fee_details .mat-input-element:focus::placeholder {
  color: transparent !important;
}

// fee detail form label fixed top and remove placeholder css end
.setting_faq.setting_faq_top {
  color: #fff;
  position: relative;
  width: auto;
  height: 56px;

  .toolbarsetting {
    background: #283144;
    padding-left: 1em;

    .sidenavbttxt {
      padding-left: 0.7em;
      font-size: 15px;
    }
  }

  .dropdown.popupvw {
    min-width: calc(100vw - 0px);
    margin-top: 3.7em;
    transform-origin: top right;
  }
}

.setting_faq.setting_faq_top .toolbarsetting.arrow.open:after {
  right: 1.9em;
  top: 3.95em;
  border-top: 8px solid #1976d2;
  transform: rotate(0deg);
  position: absolute;
  left: 4.5em
}

.toolbarsetting.arrow.open {
  background: #1976d2 !important;
  color: #fff !important;
}

.setting_faq.setting_faq_top .toolbarsetting.arrow.open .sidenavbttxt {
  opacity: 1 !important;
}

.setting_faq.setting_faq_top fury-toolbar-setting.toolbar-button {
  background: #283144;
}

.setting_faq_top .toolbar-button .mat-button.mat-button-base.button.toolbarsetting.arrow {
  height: 56px;
  width: 10em;
  border-radius: 0px;
}

.popup_checkbox {
  position: fixed;
  bottom: 0px;
  background: #dfe6ed;
  padding: 8px 30px;
  left: 0px;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
}

.mat-tooltip {
  white-space: pre-line;
  max-width: 280px !important;

}

.timestamp {
  margin-bottom: 1.2em;

  .p-calendar {
    width: 96%;
    height: 3.11em;
  }

  .p-datepicker.p-component.p-datepicker-timeonly {
    width: 100%;
  }
}

.rapid-pos-btn .timestamp {
  .p-calendar {
    width: 100%;
  }

  span.p-float-label {
    margin-right: 10px !important;
  }
}

.timestamp-footer {
  width: 100%;
  display: flex;
  justify-content: right;

  button {
    background: #1976d2;
    color: #fff;
    border: 0;
    padding: 3px 10px;
    border-radius: 2px;
  }
}

.wd90 {
  width: 90% !important;
}

.wd10 {
  width: 10% !important;
  padding: 0 10px !important;
}

.procss-log-grid {
  .actions.oms-list-btns {
    padding: 1em;
    justify-content: flex-end;
    display: flex;

    .countClass2 {
      margin-top: 0em !important;
      display: inline;
      float: right;
      font-weight: 500;
      padding: 0.7em;
      font-family: var(--font-family);
      padding: 0 0.7em !important;
      white-space: nowrap;
    }
  }
}

.processlogcal .md-drppicker.ltr {
  position: relative;
  left: 0px !important;
  right: 0px !important;
  width: 90%;
}

.processlogcal .datepick {
  border: 1px solid #e0e4e7;
  height: 42px;
  width: 96%;
  border-radius: 5px;
  margin-bottom: 20px;
}

.processlogcal {
  position: relative;
}

.processlogcal .date-range-picker-icon {
  margin-left: auto;
  z-index: 1;
  position: absolute;
  right: 22px !important;
  top: 15px;
  left: auto;
}

.exportDataSet {
  margin-right: 5px;
  position: absolute;
  right: 1.5em;
  top: 1em;
  z-index: 99999999;
}

.md-drppicker {
  z-index: 9999;
  overflow: auto; // also try overflow-y;
}

ngx-daterangepicker-material {
  z-index: 9999;
  overflow: auto; // also try overflow-y;
}

.inputbtninset.cancel {
  right: 26em;
  width: 1em !important;
  padding: 0px 0px !important;
}

.inputbtninset {
  position: absolute;
  right: 28em;
  top: 2.4em;
  width: 0.2em !important;
  padding: 0px 0px !important;

  button {
    width: 19px;
    height: 19px;

    .mat-button-wrapper {
      padding: 4px 0 !important;
      line-height: 0px;
      display: block;

      .material-icons-outlined {
        font-size: 14px;
      }
    }
  }
}

.inputunderlabel {
  position: absolute;
  top: 5.8em;
  font-size: 11px;
  left: 19.4em;
  color: red;
}

.inputunderaccountlabel {
  position: absolute;
  top: 13em;
  font-size: 10px;
  // left: 19.4em;
  color: red;
}

//Custodian Plus Button Start
.inputbtninset1.cancel {
  right: 5em;
  width: 1em !important;
  padding: 0px 0px !important;
}

.inputbtninset1 {
  position: absolute;
  right: 6em;
  top: 2.4em;
  width: 0.2em !important;
  padding: 0px 0px !important;

  button {
    width: 19px;
    height: 19px;

    .mat-button-wrapper {
      padding: 4px 0 !important;
      line-height: 0px;
      display: block;

      .material-icons-outlined {
        font-size: 14px;
      }
    }
  }
}

.inputunderlabel1 {
  position: absolute;
  top: 5.8em;
  font-size: 11px;
  left: 36.4em !important; //custodian connection Pending label fix
  color: red;
}

.inputunderaccountlabel {
  position: absolute;
  top: 13em;
  font-size: 10px;
  // left: 19.4em;
  color: red;
}

//Custodian Plus Button End
.redbutton {
  background-color: red !important;
  color: #fff;
}

// .intromodal button.p-dialog-header-icon.p-dialog-header-close {
//   display: none;
// }
.accountdialog button.p-dialog-header-icon.p-dialog-header-close {
  position: absolute;
  right: 1.2em;
  top: 0.5em;
}

.p-table-sourcedetails td:first-child {
  padding-left: 1rem !important;
}

.p-table-sourcedetails {
  th {
    white-space: normal;
    padding: 0rem 0rem 0rem 0.4rem !important;
  }

  td {
    border-left: none !important;
    border-right: none !important;
    padding: 1rem 0rem 1rem 0.6rem !important;
  }

  .mat-form-field {
    display: block;
  }

  .p-datatable-scrollable-header-box {
    padding-right: 0px !important;
  }

  .p-datatable-scrollable-body {
    overflow-y: auto !important;
    overflow-x: auto !important; //*Horizontal scroll applied in source details table - Non Custodian
  }
}

.cron-editor-container {
  .day-order-in-month {
    margin-right: 0.5em;
    width: 5em !important;
  }

  .months {
    margin-top: 1em;
    width: 6.6em !important;
  }

  .advanced-cron-editor-label {
    margin-top: 1em;
    display: inline-block;
  }

  .timeFormControl {
    width: 3em !important;
    display: inline;
    margin-left: 0.5em !important;
  }

  .week-days {
    width: 7em !important;
  }
}

.mat-select-arrow {
  margin: 5px 4px 0px 4px !important;
}

.popup_checkbox {
  position: fixed;
  bottom: 0px;
  background: #dfe6ed;
  padding: 8px 30px;
  left: 0px;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
}

.clearfix {
  clear: both;
}

.popup_checkboc_top {
  right: 4em;
  position: absolute;
  top: 1.35em;
  color: white;

  .mat-checkbox-frame {
    border-color: #fff;
  }
}

// START Text center alignment use *.servicetextcenter*
.servicetextcenter {
  font-weight: 500;
  font-style: italic;
  text-align: center;
}

// END Text center alignment *.servicetextcenter*
// Start Material Month and Year Input alignment - Use *.labeliconalign* in service ts file
.labeliconalign {
  span.mat-form-field-label-wrapper {
    top: -1.1em;
  }

  .mat-form-field-infix {
    padding: 0.45em 0 !important;
  }

  .mat-form-field-suffix {
    top: 0.5em !important;
  }

  input.mat-input-element {
    margin-top: 0.6em;
  }
}

.intromodal button.p-dialog-header-icon.p-dialog-header-close {
  display: none;
}

p-dialog.intromodal.deliveryModal .p-dialog-content {
  min-width: 60rem !important;
}

p-dialog.intromodal.deliveryModal.onlyPdf .p-dialog-header-close {
  display: block !important;

  .p-dialog-header-close-icon {
    margin-left: 0.3em;
  }
}

// END Material Month and Year Input alignment - Use *.labeliconalign* in service ts file

// START PrimeNG Fund multiselect input alignment with sibling - Use *.pmulti-labeliconalign* in service ts file
.pmulti-labeliconalign {
  p-multiselect {
    .p-multiselect.p-component {
      top: -0.1em;
    }
  }
}

.red-text {
  color: red;
}


.fee_details .label-font {
  background: #fff;
}

.fee_details .mat-input-element:focus::placeholder {
  color: transparent !important;
}

// fee detail form label fixed top and remove placeholder css end
.warningm {
  top: auto;
  margin: -2em 1em 0.75em 0;
  position: relative;
  font-size: 10px;
  color: red;

  span:first-child {
    font-weight: 500;
  }
}

.custodianentry {
  .mat-vertical-content {
    padding: 0px 24px 24px 24px !important;
  }

  #sourceInfoForm {
    padding-top: 0px !important;
  }
}

// START Execute Workflow Status First Child Right Border Hide 
// .oms-fullwidth.executeworkflowgrid .ag-header-cell:first-child {
//   border-right: none !important;
// }

// END Execute Workflow Status First Child Right Border Hide
//START Label font size equal to primeng
.primenglabel .label-font {
  font-size: 16px !important;
}

//END  Label font size equal to primeng
// .mat-drawer-content{ z-index: auto!important;}
// START Connection UI Dynamic Width Mat-step 
.mat-stepper-vertical.dynamicwidth {
  width: 100% !important;

  .mat-vertical-content-container {
    margin: 0px !important;
  }

  .p-datatable .p-datatable-tbody>tr {
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
  }
}

//END Connection UI Dynamic Width Mat-step
//START Auto Suggestion - Mention List FSA 
.mention-menu::-webkit-scrollbar {
  background-color: #ffffff !important;
}

mention-list {
  z-index: 99999;
  position: fixed !important;
  width: auto !important;
  margin-left: 4em;
  font-family: var(--font-family);

  .mention-item {
    // border-bottom: 1px solid #70707033 !important;
    background: linear-gradient(to right, white 100%);
    background-position: right bottom;
    transition: all 0.3s ease-out;
    padding: 0.2em 0.5em 0.2em 1em !important;

    :hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }

  .mention-menu {
    width: 350px !important;

    li {
      padding: 5px 0px 5px 0px;
    }
  }

  .mention-active {
    a {
      background-color: #ffffff !important;
      color: #000000 !important;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    line-height: 18px;

    span {
      &:first-child {
        font-size: 14px;
        font-weight: 500;
      }

      &:nth-child(2) {
        font-size: 10px;
      }
    }
  }

  .dropdown-menu.scrollable-menu.mention-menu {
    overflow-x: hidden !important;
    z-index: 99999999 !important;
    border-radius: 8px !important;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%) !important;
  }
}

//END Auto Suggestion - Mention List FSA 

//START Atreyx input custodium message under input
.inputunderlabel1.inputunderlabel2 {
  left: 36.4em !important;
}

// END Atreyx input custodium message under input
//START Processing workflow Input & Placeholder & Calendra icon alignment issue
.workflow-input-align {
  top: 5px !important;

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    padding: 0px 9px !important;
  }

  .mat-datepicker-toggle button {
    top: 5px !important;
  }

  label {
    padding: 2px 14px !important;
  }
}

//END Processing workflow Input & Placeholder & Calendra icon alignment issue

// login page logo
.fury-dark {
  .sudrania-logo.dark {
    display: block !important;
  }

  .sudrania-logo.default {
    display: none !important;
  }
}

// START Processing Run data import Workflow Tooltip background color & width manage
.toolTip-color {
  .ag-tooltip {
    background-color: #717171;
    color: #ffffff;
    border-radius: 3px;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #545454;
    line-break: anywhere;
    max-width: 30em;
    min-width: 5em;
    width: auto;
  }
}

// END Processing Run data import Workflow Tooltip background color & width manage
//START Header Formidium banner update 
.formidium-img {
  display: flex;

  .formidium-default-img {
    width: 9.5em;
    align-self: center;
    padding-right: 15px;
  }

  .formidium-dark-img {
    display: none;
    width: 9.5em;
    align-self: center;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.loderimg {
  position: absolute !important;
  left: 47% !important;
  width: 8em !important;
  top: 31% !important;
}

//START Header Formidium banner update 
//Start Processing Status WOrkflow tree 
.executeworkflowgrid {
  .actions.oms-list-btns.uploadbtn_alignment {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .countClass {
      order: 1;
    }

    .exportbtnicon {
      order: 2;
    }

    .dataimportsearch {
      position: absolute;
      left: 10em;
    }
  }

}

// End Processing Status WOrkflow tree
//Start Custodian entry Rapid
.wrkflwmapping {
  span {
    padding-left: 0px !important;
  }
}

//End Custodian entry Rapid
// START Ag Grid user custom column alignment issue 
.ag-right-aligned-header .ag-header-cell-label {
  flex-direction: row;
}

// END Ag Grid user custom column alignment issue

//START system manager roles and Modules btn alignment
.statusbtn {
  padding-left: 7px !important;

  span {

    display: flex;

    align-items: center;

    label {
      margin-right: 10px;
    }
  }
}

.passPhraseMessage span {
  color: red;
  font-size: 12px;
  padding-left: 5px;
}

//END system manager roles and Modules btn alignment

// Processing  Data Import procerd all and cancel all button popup 
.dialog_width {
  .p-dialog.p-component.p-dialog-draggable.p-component.p-dialog-draggable.p-dialog-resizable.p-dialog {
    min-width: 38em !important;
  }

  .calendar-icon {
    .mat-form-field-appearance-outline .mat-form-field-suffix {
      top: 0.75em !important;
    }
  }
}

.executework-flowrun.fulwithmodal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
  min-width: 90em !important;
  left: 7.6rem !important;
  position: relative !important;
  top: 0 !important;
  bottom: 0 !important;
}

@media (max-width: 1399.98px) {

  .executework-flowrun.fulwithmodal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    min-width: 80em !important;
    left: 7.6rem !important;
  }
}

@media (max-width: 1199.98px) {
  .executework-flowrun.fulwithmodal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    min-width: 70em !important;
    left: 7.6rem !important;
  }
}

@media (max-width: 991.98px) {
  .modal-left-part {
    width: 100% !important;

    .startdate {
      width: 49%;
      display: inline-block;
    }

    .enddate {
      width: 49%;
      display: inline-block;
      margin-left: 2%;
    }
  }

  .modal_right {
    width: 100% !important;
    margin-bottom: 30px;
  }

  .executework-flowrun.fulwithmodal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    min-width: 98% !important;
    left: 0rem !important;
  }
}

@media (max-width: 575.98px) {
  .modal-left-part {
    width: 100% !important;

    .startdate {
      width: 100%;
      display: inline-block;
    }

    .enddate {
      width: 100%;
      display: inline-block;
    }

    .inputupload-brose-section .p-fileupload .p-fileupload-content img {
      width: 30px;
    }

    .inputupload-brose-section .p-fileupload .p-fileupload-content h3 {
      font-size: 14px;
    }

    .file_multi-uploadbtn .p-fileupload-buttonbar {
      top: 95px !important;
    }

    .inputupload-brose-section .p-fileupload .p-fileupload-content {
      min-height: 170px;
    }
  }

}

.executework-flowrun.fulwithmodal .p-dialog .p-dialog-footer {
  background: #fff;
  display: flex !important;
  position: relative;
  /* justify-content: flex-end!important; */
  width: 100%;
  float: right;
  right: 0;
  align-self: flex-end;
  margin-top: -60px;
}

.executework-flowrun.fulwithmodal .p-dialog .p-dialog-content {
  padding-bottom: 0 !important;
  min-height: calc(100vh - 30rem);
}

.file_multi-uploadbtn {
  h3 {
    margin-bottom: 55px;
  }

  .p-fileupload-buttonbar {
    top: 133px !important;
    bottom: auto !important;
  }

  .file_name {
    margin-top: 50px;

    .upload-filename {
      position: static !important;
    }

  }
}

// START Reports Financial statement fund basic real Estate fund input & datepicker alignment issue fixed
.fs-datepicker {
  .mat-datepicker-toggle button {
    top: 5px !important;
  }

  .mat-form-field-flex {
    align-items: unset !important;
  }
}

// END Reports Financial statement fund basic real Estate fund input & datepicker alignment issue fixed

// START Financial statement notes to account edit icon popup box scrollbar hide
.fs_scrollbar {
  .p-dialog-content {
    overflow-y: hidden !important;
  }
}

// END Financial statement notes to account edit icon popup box scrollbar hide
// START run_compliance_files upload popup
.run_c_uploadpopup {
  .p-dialog-content {
    height: 250px;
  }

  table {
    tr {
      overflow-y: auto;
    }

    td {
      border-bottom: 1px solid #f5f5f5;
    }
  }
}

// END run_compliance_files upload popup
// START Double asterisk mark issue fixed in UAT & prod only
span.mat-placeholder-required.mat-form-field-required-marker {
  display: none !important;
}

// END Double asterisk mark issue fixed in UAT & Prod only
.simplebar-scrollbar::before {
  background: rgba(245, 245, 245, 0.30);
}

.actrialblance-scroll {
  .p-datatable-resizable>.p-datatable-wrapper {
    overflow: unset !important;
  }
}

//START system manager roles and Modules btn alignment
.statusbtn {
  padding-left: 7px !important;

  span {

    display: flex;

    align-items: center;

    label {
      margin-right: 10px;
    }
  }
}

.passPhraseMessage span {
  color: red;
  font-size: 12px;
  padding-left: 5px;
}

//END system manager roles and Modules btn alignment

.JEDetailsPointer .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
  cursor: text;
}

// START Investor  Statement Design CSS 
.templateinput {
  margin-top: 15px;
}

.is-distribution-radio {
  border-top: 1px solid #dbdbdb;
  // border-bottom: 1px solid #dbdbdb;
  background: #fcfcfc;
  padding: 10px 1px;

  .label-font {
    display: flex;
  }

  .mat-form-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    place-content: space-between;

    // mat-label{
    //   margin-top: 1.5em !important;
    // }
    mat-radio-group {
      margin-top: 0px !important;
    }

    .mat-radio-button {
      padding: 0 25px;
    }
  }
}

.manage-search {
  .mat-icon {
    font-size: 41px;
  }
}

.sidenav-toolbar-icon {
  display: none;
}

fury-sidenav.collapsed {

  // .defaulttheme{ margin-left: -17px;}
  .sidenav-toolbar {
    padding-left: 17px;
  }

  .sidenav-toolbar-icon {
    display: block !important;

    img {
      width: 40px;
      margin-top: 4px;
    }
  }
}

fury-sidenav.collapsed.expanded {
  .sidenav-toolbar-icon {
    display: none !important;
  }
}

.isd-complete-red {
  .ag-cell {
    color: red !important;
    display: flex;
    align-items: center;
  }
}

.isd-complete-green {
  .ag-cell {
    color: green !important;
    display: flex;
    align-items: center;
  }
}

.isd-complete-gray {
  .ag-cell {
    color: gray !important;
    display: flex;
    align-items: center;
  }
}

.w70pr {
  width: 70%;
  padding: 0px 0px;
  float: left;
  display: inline-block;
}

.w30pr {
  width: 30%;
  padding: 0 15px;
  float: left;
  display: inline-block;
}

.w30pr.wremark {
  clear: both;
  margin-left: 40%;
}

.w60pr {
  width: 60%;
  padding: 0 15px;
  float: left;
  display: inline-block;
}

.h60pr {
  mat-form-field.mat-form-field.textarea-height textarea.mat-input-element {
    height: 50px;
    padding-left: 0em;
  }
}

.w10pr {
  width: 10%;
  float: left;
  display: inline-block;
}

.isd-protected-btn {
  float: right;
  margin-top: -2em;
  overflow: overlay;
}

.isdModal .p-dialog-maximized {
  height: 100%;
  left: 105px !important;
  max-height: 100%;
  top: 0 !important;
  transform: none;
  transition: none;
  max-width: 82.5vw !important;

}

.isdModal {
  .p-dialog-header-maximize {
    .p-dialog-header-maximize-icon {
      color: #fff !important;
    }

    &:hover {
      .p-dialog-header-maximize-icon {
        color: #000 !important;
      }
    }
  }

}

.isdEmailTemplate {
  background: lightgray;
  color: #1d2125;

}

.isd-inv-protected-btn {
  float: right;
  margin-top: -2em !important;
  overflow: overlay;
}


.isdclose {
  button {
    top: 0 !important;
  }
}

.isdapproval {
  margin-right: -10px;
  display: flex;
}

.isd-doclist {
  cursor: pointer;
}

.isduploadpopup {
  display: flex;
  align-items: center;

  .isdupload {

    .p-fileupload.p-fileupload-advanced {
      display: flex;
      flex-direction: row;

      .isduplaod {
        display: flex;
        flex-direction: row;
        order: 1;

        img {
          width: 2.8em;
          height: 2.5em;
        }

        h3 {
          text-align: center;
          font-size: 12px;
          width: 100%;
          margin-left: 15px;
        }
      }
    }

    .p-fileupload-buttonbar {
      width: 30%;
      background: none !important;
      border: 1px solid #eae6e1 !important;
      border-left: none !important;
      height: auto;
      order: 2;
    }

    .p-fileupload-content {
      .p-progressbar {
        display: none;
      }

      .p-fileupload-files {
        .p-fileupload-row {
          margin-top: 5px;
        }

        order:2
      }

      display: flex;
      width: 100%;
      height: 100%;
      padding: 14px !important;
      border: 1px solid #eae6e1 !important;
      border-right: none !important;
      color: #1d2125 !important;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      order:1
    }

    .form-btns {
      display: flex;
      place-content: space-evenly;
    }
  }

}

.manage-search {
  .mat-icon {
    font-size: 41px;
  }
}



.p-table-isdbatchdetails {
  th {
    white-space: normal;
    padding: 0rem 0rem 0rem 0.4rem !important;
  }

  td {
    border-left: none !important;
    border-right: none !important;
    padding: 1rem 0rem 1rem 0.6rem !important;
  }

  .mat-form-field {
    display: block;
  }

  .p-datatable-scrollable-header-box {
    padding-right: 0px !important;
  }

  .p-datatable-scrollable-body {
    overflow-y: auto !important;
    overflow-x: auto !important; //*Horizontal scroll applied in batch details table
  }
}

// END Investor  Statement Design CSS 


// START Setting Panel hover list and icon style change 
.createpopup.custom-setting-panel {
  .popuporow {
    ul {
      li {
        .iconset {
          span {
            margin-left: 0.6em !important;
          }
        }

        span {
          display: flex;
          align-items: center;

          a.text {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            text-decoration: none;
            white-space: nowrap;
            margin-left: 10px;
          }

          .pushpin {
            font-size: 15px;
            transform: none;
            padding-right: 5px;
            color: #646668;
          }

          .pushset {
            .pushpin.pushpinactive {
              color: #ee8434;
            }
          }
        }

        &:hover {
          background: #ee8434;

          a {
            color: #fff;

            &:hover {
              color: #fff;
            }
          }

          span.pushpin {
            color: #fff !important;
            // &:hover{
            //   transform: scale(1.3);
            // }
          }

          .iconset {
            span {
              color: #fff;

              &:hover {
                transform: scale(1.9);
              }
            }
          }
        }
      }
    }
  }
}

// END Setting Panel hover list and icon style change 


// START Custom Mat-tooltip approved 
.mat-tooltip {
  border-radius: 0px !important;
  padding: 1px 4px !important;
  opacity: 1;
  font-size: 11px;
  background: #646668;
  margin: 0px 0px 0px 14px !important;
}

.mat-tooltip-panel-below.mat-tooltip-panel {
  margin-top: 10px;
}

// END Custom Mat-tooltip approved 
#feeRate2 {
  clear: both;
}

.pl_5 {
  padding-left: 5px;
  margin-top: -6px;
}

// START AG Grid Approved height 
.ag-header-cell-text {
  font-size: 14px;
}

.ag-header,
.ag-status-bar,
.ag-header-row,
.ag-row {
  height: 30px !important;
  min-height: 30px !important;
  align-items: center;
}

.ag-row-inline-editing.ag-row-editing.ag-row {
  height: 30px !important;
  min-height: 30px !important;
  align-items: center;
  background-color: #fff !important;
}

.ag-theme-alpine .ag-row-editing.ag-row:hover {
  background-color: #fff !important;
}

.ag-cell-inline-editing {
  height: 30px !important;
  min-height: 30px !important;
}

// END AG Grid Approved height 
// START Batch log red info icon placement issue 
.batchdetailserror {
  background-color: transparent !important;
  cursor: pointer;
  color: red;
  border-style: none;

  span {
    font-size: 21px;
    margin: 15px 5px;
  }

  span.agvisibility {
    margin: 15px 0px;
  }
}

// END Batch log red info icon placement issue 

// START Real Estate
.JEDetailsPointer .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
  cursor: text;
}
.gridbillpayable fury-page-layout-header , .furyheadernone fury-page-layout-header{
  display: none;
}

.card.cardw75 {
  width: 75% !important;

  .mat-stepper-vertical {
    width: 100% !important;

  }

  .columnw25 {
    width: 25% !important;
  }

  .columnw8 {
    width: 80% !important;
  }

  .img-alignment {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .img-alignment.columnw5 {
    width: 5%;
    align-items: flex-start !important;
    white-space: nowrap !important;
    margin-top: 1%;
  }

  .img-alignment.addmoret {
    margin: 0% 0% 2% 5%;
  }

  .minusentityico {
    margin: 0.5em 0 0 -0.7em !important;
  }

  .columnw95 {
    width: 95% !important;
  }

  .billtransaction {
    display: inline-flex;
    width: 100%;
  }

  .w29 {
    width: 29.8% !important;
  }

  .w30 {
    width: 30% !important;
  }

  .w35 {
    width: 35% !important;
  }
}

// START White BUtton common for popup blue header with splitgroupbtn
.dialogwithwhitebtn {
  width: 100%;

  .stickyButton {
    .splitgroupbtn {
      .mat-button-toggle {
        color: #1d2125 !important;
        background-color: #fff;

        span.ui-text {
          margin: 0;
          color: #1d2125;
        }
      }
    }
  }

  .mright-10 {
    margin-right: 10px !important;
  }

  .mat-step-icon {
    display: none;
  }

  .mat-step-text-label {
    color: #fff !important;
  }
}

.total-amount-input {
  align-items: end;

  input {
    text-align: end;
    height: 40px;
  }
}

//END  White BUtton common for popup blue header with splitgroupbtn

// Global Element - Mat Stepper Header Custom close icon - For Form Submission
.dialogcustomcloseicon {
  position: absolute;
  right: 1.6em;
  top: 1em;
  z-index: 2;
  color: #fff;
  cursor: pointer;
}

//END  Global Element

// END Real Estate
// Seamless RE CSS 
.paymentgrid {
  height: calc(85vh - 5em - 300px) !important;
  line-height: 0px;

  .ag-full-width-container {
    .ag-row {
      max-height: 21.4em;
      height: 40vh !important;

      .ag-theme-alpine .ag-details-row {
        padding: 15px 30px 15px 30px !important;
      }
    }
  }

  view-btn-cell-renderer {

    .agvisibility,
    .agclear {
      display: none !important;
    }

    .agvisibility.agpayments {
      display: inline-block !important;
    }
  }
}

.paymentsview {
  .agclear {
    display: none !important;
  }
}

.payment-input {
  width: 50% !important;
}

.queryt-custom {
  .total-payment {
    font-weight: 700;
  }
}

// END Seamless RE CSS 

// START Toolbar Button Custom Design 
.button.toolbar-btn-mini {
  background-color: #1976d2 !important;
  color: #fff;

  &:hover {
    background-color: #1976d2 !important;
    box-shadow: 0 3px 5px -1px #0000000f, 0 6px 10px #0000000b, 0 1px 18px #00000009;

    span.toolbar-button {
      color: #fff !important;

      &:hover {
        background: none;
        transform: scale(1.5);
      }
    }
  }

  &:active {
    background-color: #1976d2 !important;
  }
}

.toolbar {

  fury-toolbar-fullscreen-toggle,
  .note,
  .duplicateico {
    border: none !important;
    border-right: 0px !important;
    border-left: 0px !important;
  }

  .duplicateico {
    line-height: normal;
    height: auto;
    padding: 0em 1.8em;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
  }

  .note {
    line-height: normal;
    height: auto;
    padding: 0em 1.8em;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
  }

  .translatedrop {
    border-right: 1px solid rgba(0, 0, 0, 0.08) !important;
  }

  .toolbar-button {
    &:hover {
      background: none !important;
    }
  }

  fury-toolbar-training-videos.toolbar-button.toolbar-upload {
    border-right: none !important;
    padding: 0px 15px 0px 15px !important;
    border-left: 1px solid #fafafa;
    border-left-width: 1px !important;
    border-left-style: solid !important;
    border-left-color: rgba(0, 0, 0, 0.08) !important;
  }

  .toolbar-create {
    border-right: none !important;
    padding: 0px 15px !important;
    border-left: none !important;
  }

  .toolbar-upload {
    border: none !important;

    img {
      width: 24px !important;
      margin-top: -3px;
    }
  }

  .toolbar-view {
    border-left: none !important;
    padding: 0px 15px !important;
  }

  &:hover {
    background: none;
  }

  .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}

.button.toolbar-btn-mini.active {
  background-color: #1976d2 !important;
  box-shadow: 0 3px 5px -1px #0000000f, 0 6px 10px #0000000b, 0 1px 18px #00000009;

  span.toolbar-button,
  img {
    color: #fff !important;
    transform: scale(1.5);
  }
}

// END Toolbar Button Custom Design 

// START Custom Toggle Button with Inner Text 
.Customtexttoggle {
  --width: 8em;
  --height: calc(var(--width) / 5);
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer;
  top: 5px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: #fff;
    border: 2px solid #fff;
    box-shadow: inset 0 0 5px #646668;
    transition: all 0.4s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 0px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #0fb7f8;
      box-shadow: 0px 1px 3px #646668;
      transition: all 0.4s ease-in-out;
    }
  }

  .labels {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    transition: all 0.4s ease-in-out;

    &::after {
      content: attr(data-off);
      position: absolute;
      right: 2.2em;
      color: #1d2125;
      opacity: 1;
      transition: all 0.4s ease-in-out;
      top: -4px;
      font-weight: 600;
      font-size: 12px;
    }

    &::before {
      content: attr(data-on);
      position: absolute;
      left: 1em;
      color: #1d2125;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      top: -4px;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.Customtexttoggle input:checked+.slider {
  background-color: #fff;
  border: 2px solid #fff;
  box-shadow: inset 0 0 5px #646668;
}

.Customtexttoggle input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.Customtexttoggle input:checked~.labels::after {
  opacity: 0;
}

.Customtexttoggle input:checked~.labels::before {
  opacity: 1;
}

.french-allinputmodal {
  .Customtexttoggle {
    --width: 13em;
    --height: calc(var(--width) / 8);

    .labels:after {
      right: 1em;
    }
  }
}

// END Custom Toggle Button with Inner Text 
// FS AUTOMATION NOTES MAT PANEL Design FIXED - DO NOT REMOVE THIS CODE
.notes-to-account {
  mat-expansion-panel-header {
    padding: 0 24px !important;
    height: 48px !important;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    transition: height 225ms cubic-bezier(0.4, 0, 0.2, 1);

    .mat-expansion-indicator {
      margin-top: 5px !important;
    }

    span.agvisibility {
      margin-top: 4px !important;
    }
  }
}

//END FS AUTOMATION NOTES
// START Font family change
.p-component,
h1,
h2,
h3,
h4,
h5,
h6,
label,
.label-font,
a.caption,
p,
b,
.p-multiselect,
.p-dropdown,
.nav-tabs li a,
.ag-status-panel-total-row-count span,
.title,
.link,
.inputmodal li,
.fury-title,
.style-name,
.gen-password ul li,
.ag-side-button-label,
.ag-column-select-column-label,
.ag-picker-field-display,
.ag-text-field-input,
.ag-filter-apply-panel-button,
.ag-menu-option-text,
.moodal-view-toolbar ul li a,
.title-tip {
  font-family: var(--font-family) !important;
}

.elevation {
  label {
    white-space: nowrap;
  }
}

//  END Font family change
// bank reconciliation  Memo Entry calendar change
.account-calendra-icon {
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 10px !important;
  }
}

// END bank reconciliation  Memo Entry calendar change
.dialog-box {
  .Dialog_width_Small .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    margin-left: 38px !important;
  }
}



//START p-multiselect dropdown tooltip//
.p-multiselect-items {
  display: block !important;
  position: relative;

  .p-multiselect-item {
    overflow: visible !important;


    .title-tip {
      font-family: var(--font-family);

      .mytooltip {
        z-index: 999999;
        background: #646668;
        position: absolute;
        left: 36px;
        top: 30px;
        color: #fff;
        padding: 1px 5px 3px 5px;
        bottom: auto;
        max-width: 247px;
        white-space: normal;
        display: none;
        font-size: 12px;
        line-height: 13px;
        word-wrap: break-word;

        &::after {
          position: absolute;
          border-radius: 4px;
          top: -3px;
          left: 5px;
          z-index: 99999;
          content: '';
          margin-left: 0px;
          width: 0;
          height: 0;
          border: 6px dashed transparent;
          border-bottom: 6px solid #646668;
          border-top: 0px solid #646668;
          font-size: inherit;
        }



      }


    }

    &:hover .mytooltip {
      display: block;
    }
  }

}

ul.p-multiselect-items p-multiselectitem:nth-last-child(1) li:hover .title-tip .mytooltip {
  background-color: #646668;
  top: auto;
  bottom: 25px;

  &::after {
    border-bottom: 0px solid #646668;
    border-top: 6px solid #646668;
    top: unset;
    bottom: -3px;
  }
}

ul.p-multiselect-items p-multiselectitem:nth-child(1) li:hover .title-tip .mytooltip {
  background-color: #646668;
  bottom: auto;
  top: 30px;

  &::after {
    border-top: 0px solid #646668;
    border-bottom: 6px solid #646668;

    top: -3px;
  }
}

.p-dropdown-items {
  display: block !important;
  position: relative;

  .p-dropdown-item {
    overflow: visible !important;

    .title-tip {
      font-family: var(--font-family) !important;

      .mytooltip {
        z-index: 999999;
        background: #646668;
        position: absolute;
        left: 36px;
        top: 30px;
        color: #fff;
        padding: 1px 5px 3px 5px;
        bottom: auto;
        max-width: 247px;
        white-space: normal;
        display: none;
        font-size: 12px;
        line-height: 13px;
        word-wrap: break-word;

        &::after {
          position: absolute;
          border-radius: 4px;
          top: -3px;
          left: 5px;
          z-index: 99999;
          content: '';
          margin-left: 0px;
          width: 0;
          height: 0;
          border: 6px dashed transparent;
          border-bottom: 6px solid #646668;
          border-top: 0px solid #646668;
          font-size: inherit;
        }
      }
    }

    &:hover .mytooltip {
      display: block;
    }
  }
}

ul.p-dropdown-items p-dropdownitem:nth-last-child(1) li:hover .title-tip .mytooltip {
  background-color: #646668;
  top: auto;
  bottom: 25px;

  &::after {
    border-bottom: 0px solid #646668;
    border-top: 6px solid #646668;
    top: unset;
    bottom: -3px;
  }
}

ul.p-dropdown-items p-dropdownitem:nth-child(1) li:hover .title-tip .mytooltip {
  background-color: #646668;
  bottom: auto;
  top: 30px;

  &::after {
    border-top: 0px solid #646668;
    border-bottom: 6px solid #646668;
    top: -3px;
  }
}

.p-inputtext {
  font-family: var(--font-family) !important;
}

.matselectn {
  position: relative;

  .customtooltip {
    z-index: 999999;
    background: #646668;
    position: absolute;
    left: 7px;
    bottom: auto !important;
    color: #fff;
    padding: 1px 5px 3px 5px;
    top: 38px;
    max-width: 270px;
    white-space: normal;
    display: none;
    height: auto;
    font-size: 12px;
    border-radius: 2px;

    line-height: 14px;

    &::after {
      position: absolute;
      border-radius: 4px;
      top: -9px;
      left: 5px;
      z-index: 99999;
      content: '';
      margin-left: 0px;
      width: 0;
      height: 0;
      border: 6px dashed transparent;
      border-bottom: 6px solid #646668;
      font-size: inherit;
    }
  }

  &:hover {
    .customtooltip {
      display: block;
    }
  }
}

.matselectn:nth-last-child(1):hover .customtooltip {
  background-color: #646668;
  top: auto;
  bottom: 27px !important;

  &::after {
    border-bottom: 0px solid #646668;
    border-top: 6px solid #646668;
    top: unset;
    bottom: -3px;
  }
}

.matselectn:nth-last-child(1):hover .customtooltipedit {
  bottom: auto !important;
  top: 30px;
  word-wrap: break-word;

  &:after {

    border-top: 0px solid #646668;
    border-bottom: 6px solid #646668;
    top: -3px;
    bottom: unset;
  }
}

// START Hotspot Talk/Contact to us bottom UI 
.hotspottalk .bootm-modal {
  height: 79vh !important;
}

// Start before login chat hubspot 
// .login_body .hotspottalk .bootm-modal{ height:87vh !important;}
// .login_body fieldset.form-columns-2 .hs-form-field{width:59% !important;}
// End before login chat hubspot 
.hotspottalk {

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: 10px 10px red;
  }

  .form-columns-1 .hs_description .hs-fieldtype-textarea {
    width: 100% !important;
    border-radius: 4px;
  }

  .form-columns-2 input {
    width: -webkit-fill-available;
    width: 100% !important;

    label {
      margin-left: 15px;
    }
  }

  input {
    border-radius: 4px;
  }

  .form-columns-1 .hs-input {
    width: 100% !important;
  }

  // .modal-inner{overflow: hidden;}
  fieldset.form-columns-1 {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .submitted-message p {
    font-size: 24px;
    text-align: center;
  }

  .hs-form-required {
    color: red;
    margin-left: 4px;
  }

  .hs_description {
    .hs-form-required {
      margin-left: 0px;
    }
  }

  .hs_error_rollup {
    display: none;
  }

  .hs-form-booleancheckbox-display {
    display: block;
    text-align: justify;
    margin-bottom: 0.25rem;
  }

  .legal-consent-container .field.hs-form-field {
    width: 100%;
  }

  li.hs-form-booleancheckbox {
    input {
      width: auto !important;
    }

    p {
      text-align: justify !important;
      display: inline;

      a {
        text-decoration: underline;
      }
    }

    // .hs-form-required {} 
  }

  fieldset.form-columns-2 .hs-form-field {
    width: 50% !important;
  }

  label.hs-error-msg,
  label.hs-main-font-element {
    color: red !important;
    font-size: 13px !important;
  }

  input {
    padding: 5px;
    border: 1px solid #64666860;
  }

  textarea {
    padding: 5px;
    border: 1px solid #64666860;
  }

  // .input{margin-right: 4px !important;}
  .hs-dependent-field {
    .input {
      margin-right: 14px !important;
    }
  }

  .hs_submit.hs-submit {
    margin-right: 14px !important;
  }

  ul.inputs-list {
    list-style: none;
    list-style-type: none;
    padding-inline-start: 0px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-top: 0px;
    margin-bottom: 5px;

    input {
      margin-top: 5px;
    }
  }

  .bootom-button {
    position: absolute;
    bottom: 15px;
    right: 30px;
    border-radius: 999rem;
    bottom: 15px;
    background-color: #1976d2 !important;
    color: #f0ebeb !important;
    width: 50px;
    height: 50px;
    z-index: 10;
    border: none;
    outline: none;
    cursor: pointer;

    img {
      width: 30px !important;
      height: 30px !important;
      max-width: 42px;
    }
  }

  .bootm-modal {
    box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%) !important;
    border-radius: 0rem !important;
    // box-shadow: 0 4px 12px 0 rgb(104 115 125 / 15%) !important;
    position: fixed !important;
    bottom: 0px;
    right: 0;
    left: unset !important;
    bottom: 0px !important;
    height: 83vh !important;
    width: 440px !important;
    transform: none !important;
    background: #fff;
    z-index: 10;
    position: absolute;
    z-index: 999;
    border: 1px solid #a7a7a7;

    // fieldset
    // { width: 100%;

    //   .hs-input, .hs_firstname{
    //     width: 100%;
    //   }
    //   input{
    //     width: 100%;
    //   }

    // }

    .modal-inner {
      padding: 0px 0px 0px !important;
    }

    .modal-inner {
      &:nth-child(2) {
        height: 100%;
        overflow-x: scroll;
        padding-bottom: 0px !important;
      }

      .sticky-header {
        height: 85px;
        position: relative;

        .help-heading {
          background: #1976d2;
          font-size: 18px;
          color: #fff;
          margin-bottom: 0px;
          font-weight: 600;
          text-align: center;
          padding: 22px !important;
          text-align: left;
          border-radius: 0;
          margin-top: 0px;
        }

        .contct-sud {
          padding: 10px 11px;
          font-size: 21px;
          opacity: 1;
          background: #1976d2;
          z-index: 999;
          color: #fff;
          border-radius: 0px;
          right: -375px;
          top: -68%;
          position: relative;
          border: none;
          cursor: pointer;
        }
      }

      .contact-button-form {
        margin-left: 0 !important;
        padding: 0 20px;
        overflow-y: auto;
        margin-bottom: 6rem;
      }

      .heade-sec {
        background: #1976d2;
        text-align: center;
        padding: 22px !important;
        border-radius: 0;
      }
    }

    .hs-button.primary.large {
      border-radius: 0;
      cursor: pointer;
      display: inline-block;
      font: 600 18px/1.3125rem 'Open Sans' !important;
      margin: 0;
      min-width: 130px;
      padding: 8px 21px 7px !important;
      text-align: center;
      text-decoration: none;
      transition: background-color 0.2s, border 0.2s, color .2s !important;
      width: 200px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: #1976d2 !important;
      border: 2px solid #1976d2 !important;
      color: #fff !important;
      font-size: 18px !important;
      width: 100%;
    }
  }

  li.hs-form-booleancheckbox .hs-form-booleancheckbox-display {
    input {
      width: auto !important;
    }
  }
}

    // END Hotspot Talk/Contact to us bottom UI
    // START P-Datatable column overflow with normal wrap applied to resize the columns 
    p-table.table_text.omstable_text.acctrialbalance .p-datatable-wrapper table tbody.p-datatable-tbody tr.cntnt td{
white-space: normal;
    }
        // END P-Datatable column overflow with normal wrap applied to resize the columns 

// Waterfall Configuration
.col-lg-3.gp-catchup {
  .p-inputnumber input {
    padding: 0px 10px !important;
  }
}

.col-lg-3.lp-catchup {
  .p-inputnumber input {
    padding: 0px 10px !important;
  }
}

.waterfall-input {
  padding-right: 10px !important;
  padding-left: 5px !important;
  padding-top: 0px !important;
}

button.btn-batch-apply.btn-rejected.mat-raised-button.mat-button-base.mat-primary {
  background-color: red;
  cursor: pointer;
}

.consolidate-input {
  //padding-right: 10px !important;
  padding-left: 5px !important;
  padding-top: 0px !important;
}

// START Sidenave button text break
.sidenave-textbrake {
  span {
    white-space: pre-line !important;
    line-break: auto !important;
  }
}
// START API Module Table
.apiModuleTable .p-datatable .p-datatable-thead>tr>th {
  padding: 1rem 1.3rem !important;
}
// END Sidenave button text break


  // START API Module Table
.apiModuleTable .p-datatable .p-datatable-thead>tr>th {
  padding: 1rem 1.3rem !important;
}

// pre.code_response {
//   font-weight: 400;

//   .number-line {
//     color: #adadaf;
//   }
//   .string {
//     color: #95c602;
//   }
//   .number {
//     color: #f2b619;
//   }
//   .boolean {
//     color: #0097f1;
//   }
//   .null {
//     color: #727990;
//   }
//   .key {
//     color: #000;
//   }
// }
// END API Module Table

.compoundJElistgrids{
  height: calc(85vh - 5em - 300px) !important;
    
    .ag-full-width-container{
      line-height: 0px;
      height: 450px;
      .ag-full-width-row {
        height: 300px !important;
        .ag-theme-alpine .ag-details-row {
          padding: 15px 30px 15px 30px !important;          
        }
        .ag-cell{
          line-height: 26px !important;
        }
      }
    }
  
  }
.accounting-db {
  float: right !important;
  margin-top: -60px !important;

}

.accounting-dash1 {
  margin-bottom: 12px !important;
}

//START  API-Documentation
.api-doc {
  .toolbar {
    position: absolute;
    width: 100%;
  }
}

.api-doc .popupwid-toolbar.col-1.sidemenu {
  top: 70px !important;
}

body:has(#apidocument) .content {
  overflow-y: hidden;
}

// END API-Documentation

//START Help & Resource toolbar icon css 
body :has(.trainingvideos-popup.open) {
  .search-wrapper {
    display: none !important;
  }

  fury-sidenav {
    display: none !important;
  }

  .hrefdochead {
    display: flex !important;
    position: absolute;
    padding: 1.4rem 4.98rem;
    // background: #212939;
    margin-left: -0.2rem;

    img {
      width: 12rem;
    }
  }
}

.dropdown.popupvw.trainingvideos-popup {
  min-width: 100% !important;
}

.trainingvideos-popup {
  .subheading {
    border-end-end-radius: 15px;
    border-start-end-radius: 15px;

    li {
      a {
        font-size: 12px;
      }
    }
  }

  .popupwid-toolbar.col-1 {
    padding: 15px 0px;
  }
}

fury-toolbar-training-videos.toolbar-button.toolbar-upload {
  background: none;
}

body:has(.dropdown.viewpop.uploadpop.popupvw.newmodalui-create.trainingvideos-popup.open) .hotspottalk {
  display: none !important;
}

button.btn-batch-apply.btn-rejected.mat-raised-button.mat-button-base.mat-primary {
  background-color: #FF6700;
  cursor: pointer;
}

//END Help & Resource toolbar icon css 
mat-button-toggle.splitbtn.cursorbtndrop button.mat-button-toggle-button {
  cursor: pointer !important;
}

//Income allocation waterfall report

fury-income-allocation fury-page-layout-content.fury-page-layout-content mat-vertical-stepper .mat-vertical-content-container .content fury-page-layout-content {
  overflow-x: auto !important;
}

// START Sidenave Input Button Trade dropdown alignment issue fixed
body:has(fury-input app-trade-entry) .p-dropdown-panel {
  margin-left: 2px !important;
}

// END Sidenave Input Button Trade dropdown alignment issue fixed

.wash-sale-btn {
  #exportbtn-washsale {
    margin-right: 0em !important;
    margin-left: 0.8em !important;
  }
}

// END Sidenave Input Button Trade dropdown alignment issue fixed

// START Schedule Configuration add Icon space issue fixed with separated line
body:has(fury-fs-schedule-entry app-dynamic-form) .multi-form {
  margin-bottom: 15px !important;
  padding-top: 0px;
  border-top: 1px solid rgba(112, 112, 112, .2);
  display: grid;
}

// END Schedule Configuration add Icon space issue fixed with separated line
.textAligncenter .ag-cell {
  display: flex;
  align-items: center;
}

// START tax-setup chartofaccount 
.taxSetup-popup {
  .p-dialog {
    position: initial !important;
    margin-top: 10rem !important;
  }

}

// fury-chart-of-accounts {
//   .gridsetup-modal-view.edit-window-btn.tax-classf {
//     fury-chart-of-accounts-entry {
//       .mat-vertical-content-container {
//         margin-top: 30px !important;
//       }
//     }
//   }
// }

p-dialog:has(.tax-classf.taxSetup-popup).gridsetup-modal-view .p-dialog-mask.p-component-overlay.p-dialog-mask-scrollblocker,
p-dialog.tax-classf.taxSetup-popup {
  z-index: 999999 !important;

  ag-grid-angular.ag-theme-alpine.setGridHeight .ag-row:hover .ag-cell-wrapper span {
    color: #1976d2;
  }

  .p-dialog-header {
    padding: 1rem !important;
  }

  .p-dialog-content {
    padding: 0rem 1rem !important;
  }
}

.tax-agsearch {
  display: flex;
  position: absolute;
  left: 15px;
  top: 15px;
}

fury-chart-of-accounts p-dialog.Dialog_width_Small.confirm_undo.dailogheader-block .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable,
fury-default-chart-of-account-entry {
  left: 0px;

  .p-dialog-content {
    padding: 2rem 1rem !important;
  }
}


.tax-classf.taxSetup-popup .taxSetup-popup .p-dialog {
  position: relative;
  margin: 0rem 0rem !important;
  left: 0px !important;
  min-width: auto !important;
}

// END tax-setup chartofaccount 


// START SAH-2996 - Overlapping issue - Resolved by component selector
#specificInvestor:has(.mat-checkbox-checked) {
  clear: inline-end !important;
  display: inline-block !important;
  float: none !important;
  position: unset !important;
}

fury-connect-custodian-investor-entry div#description {
  display: inline-block;
  position: relative;
}

.as_row:has(.col-lg-12) {
  width: 100% !important;
  float: left;
}

.as_row:has(.col-lg-6) {
  width: 50% !important;
  float: left;
}
.as_row:has(.col-lg-4) {
  width:33.33%  !important; float: left;
}
.as_row:has(.col-lg-3) {
  width: 25% !important; float: left;
}
.as_row .col-lg-12, .as_row .col-lg-6, .as_row .col-lg-4, .as_row .col-lg-3 {
  width: 100% !important;
  float: none !important;
}

// END SAH-2996 - Overlapping issue - Resolved by component selector
// MOnthly COlumnr Report Button 
fury-monthly-columnar-report,
fury-schedule-k1-report,
fury-tax-balance-sheet {
  mat-icon.mat-icon {
    color: #1d2125 !important;
  }

  button.mat-button-toggle-button {
    border-left: 1px solid #ccc !important;
  }
}


fury-toolbar-create.toolbar-button.toolbar-create {
  border-left: 1px solid #00000014 !important;
}

body:has(fury-toolbar-create) .toolbar fury-toolbar-training-videos.toolbar-button.toolbar-upload {
  padding: 0 15px 0 15px !important;
}

body:has(fury-toolbar-training-videos.toolbar-button.toolbar-upload) fury-toolbar-create.toolbar-button.toolbar-create {
  border-left: none !important;
  padding-left: 0px !important;
}

// START Grid Status button center alignment
fury-over-all-status-button i {
  -webkit-transform: translateY(-5%) !important;
  -ms-transform: translateY(-5%) !important;
  transform: translateY(-5%) !important;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 30px;
}

.btm-ml3 {
  padding-right: 10px !important;
  padding-left: 5px !important;
  margin-top: -4px !important;
}

// END Grid Status button center alignment

// Start SM-5098
.alias-modal {
  width: 100%;

  th {
    text-align: left;
  }

  td {
    width: 50%
  }
}

// END SM-5098

// START Reconcilation create form Input alignment
fury-account-summary-create #reconcileMonth {
  .mat-form-field-flex {
    height: 46px !important;
  }

  .mat-form-field-suffix {
    top: 0.55em;
  }
}

  // START All card,form and Grid border Radious 0
.tradcard, .card, .step-work, .rapid-input-setupa, .adminentn{
  border-radius: 0px !important;
  }
  // END All card,form and Grid border Radious 0
  fury-tax-automation-add-configure .step-content h1 {
    white-space: normal !important;
  }
  fury-tax-automation-add-configure .step-content h1 {
    white-space: normal !important;
  }
  
  //End tax Automation add-configure design 
  
  // start generatePassword
  .generatePass {
    margin-left: 0.5rem !important;
  }
  // END generatePassword
  .as_row:has(#fairValueHeader) .col-lg-4 {
    width: 100% !important;
    float: none !important;
  }
  .as_row:has(#fairValueHeader) .col-lg-4#fairValueHeader {
    width: 33.33% !important;}
//Start fury-dashboard
#fury-dashboard {

  .fury-card {
    border-radius: 8px !important;
  }

  .fury-default .fury-card .fury-card-actions {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .fury-card .fury-card-actions .section:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .fury-card .fury-card-content {
    padding: 0 24px 24px !important;
  }

  .mat-card {

    width: auto !important;
    height: auto !important;
  }

  fury-donut-chart-widget .label {
    background: none !important;
    color: rgba(0, 0, 0, 0.54) !important;
    width: auto !important;

  }


  fury-audience-overview-widget .label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0px 0px 6px 0px !important;
    margin: unset !important;
    background: unset !important;
    width: 100% !important;
    // font-weight: 500 !important;
    font-size: 15px;
  }

  fury-audience-overview-widget .gain.success {
    color: #7cb342 !important;
  }

  fury-audience-overview-widget .property.active .label {
    color: rgba(0, 0, 0, 0.87);
    // font-weight: 500;
    font-size: 15px;
  }

  fury-audience-overview-widget .fury-card-content {
    padding: 0 24px 24px !important;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  fury-audience-overview-widget .gain.warn {
    color: #f44336 !important;
  }

  fury-audience-overview-widget .mat-button span {
    //  font-weight: 500 !important;
  }

  fury-sales-summary-widget {
    .indicator.success {
      color: #7cb342 !important;
    }

    .indicator.indicator.warn {
      color: #f44336;
    }

    .card-content .sections {
      padding: unset !important
    }

    // .fury-card-header .fury-card-header-heading-group .fury-card-header-heading {
    //   font-weight: 500 !important;
    // }

    .label {
      color: #0000008a;
      padding: 0px 0px 6px 0px !important;
      margin: unset !important;
      background: unset !important;
      width: 100% !important;
      // font-weight: 500 !important;
    }

    .fury-card .fury-card-header-subheading {
      color: rgba(0, 0, 0, 0.54) !important;
    }

  }

  fury-quick-info-widget .label {
    color: white;
    padding: 0px 0px 0px 0px !important;
    margin: 7px 5px !important;
    background: unset !important;
    width: 100% !important;
    // font-weight: 500 !important;
  }

  fury-advanced-pie-chart-widget .label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0px 0px 6px 0px !important;
    margin: unset !important;
    background: unset !important;
    width: 100% !important;
    // font-weight: 500 !important;
  }

  fury-advanced-pie-chart-widget {
    .legend {
      color: rgba(0, 0, 0, 0.54) !important;
      font-weight: 300 !important;
    }

    .legend .legend-items,
    .legend .total-label,
    .legend .total-value {
      //  font-weight: 500 !important;
    }
  }
}

//End fury-dashboard

//start investor cancel for disregardedState
body:has(.disregardedState) .as_row:has(#disregardedStateCancel) {
  position: absolute;
  bottom: 16.2rem;
  right: -1rem;
}

//End investor cancel for disregardedState

//start tax cancel for disregardedState
body:has(.disregardedStatetax) .as_row:has(#disregardedStatetaxCancel) {
  position: absolute;
  bottom: 16.4rem;
  right: -2rem;
}

//End tax cancel for disregardedState

//Configured Tax Return height issue
fury-tax-automation-add-configure .mat-form-field-wrapper {
  height: 61px !important;
  padding-bottom: 1.2em !important;
}

//fs-640 auto suggest list css
.tribute-container {
  background: white !important;
  border-radius: 3px !important;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f !important;

  ul {
    background: white !important;
  }
}

//fs-640 auto suggest list css
// Iframe title name & download icon start
.iframe-title {
  position: relative;

  .title-change {
    position: absolute;
    top: 11px;
    left: 50px;
    height: 34px;
    line-height: 34px;
    background: #323639;
    width: 100%;
    max-width: 377px;
    color: #fff;
  }

  .download {
    position: absolute;
    top: 15px;
    right: 80px;
    height: 34px;
    line-height: 34px;
    background: #323639;
    width: 36px;
    height: 36px;
    color: #fff;
    cursor: pointer;
    border-radius: 100%;
  }

  #title {
    color: #323639;
  }
}


@media (max-width: 1600px) {
  .iframe-title .download {
    top: 14px;
    right: 7%;
  }

  .iframe-title .title-change {
    top: 13px;
    left: 63px;
    max-width: 377px;
  }
}

@media (max-width: 1400px) {
  .iframe-title .download {
    top: 13px;
    right: 8%;
    width: 26px;
    height: 26px;
  }

  .iframe-title .title-change {
    top: 8px;
    left: 51px;
    max-width: 355px;
  }
}

// Iframe title name & download icon end
//Configured Tax Return height issue
fury-tax-automation-add-configure .mat-form-field-wrapper {
  height: 61px !important;
  padding-bottom: 1.2em !important;
}
//fs-640 auto suggest list css
.tribute-container {
  background: white !important;
  border-radius: 3px !important;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f !important;

  ul {
    background: white !important;
  }
}

//fs-640 auto suggest list css

fury-compound-journal-entry,fury-recurring-transaction-entry,fury-payment-data-create,fury-nontradeactivity-entry,fury-receipts-data-create{
.dialogwithwhitebtn .mright-10{
  margin-right: -30px !important;
  margin-top: 10px;
}

.dialogcustomcloseicon {
  position: absolute;
  right: 1em;
  top: 0.7em;
  z-index: 2;
  color: #fff;
  cursor: pointer;
}
}
fury-bills-create,fury-vendor-cashexpense,fury-invoice-create,fury-cash-invoice{
  .dialogwithwhitebtn .mright-10{
    margin-right: -30px !important;
    // margin-top: 10px;
  }
  
  .dialogcustomcloseicon {
    position: absolute;
    right: 1em;
    top: 0.7em;
    z-index: 2;
    color: #fff;
    cursor: pointer;
  }
  .topGap .mat-vertical-content{
    padding-top: 0px !important;
  }
}
// Taxation Vendor form input alignment issue
fury-vendors-entry-tax {
  .mat-form-field-wrapper {
    min-height: 61px !important;
  }
}

// Taxation Vendor form input alignment issue

// Taxation Vendor form input alignment issue fixed when select Subject to 1099 checkbox
fury-vendors-entry-tax .container-fluid #subjectTo1099.col-lg-4 {
  float: none !important;
  width: 50%;
  display: inline-block;
}

// Taxation Vendor form input alignment issue fixed when select Subject to 1099 checkbox

// START p-dropdown panel with plus icon  
.p-float-label:has(.p-dropdown-open) .addSelectOptions {
  pointer-events: none;
}
// END  
//merge Pdf start
.mergePdfModel {
  display: flex;
  align-items: center;

  .isdupload {

    .p-fileupload.p-fileupload-advanced {
      display: flex;
      flex-direction: row;

      .isduplaod {
        display: flex;
        flex-direction: row;
        order: 1;

        img {
          width: 2.8em;
          height: 2.5em;
        }

        h3 {
          text-align: center;
          font-size: 12px;
          width: 100%;
          margin-left: 15px;
        }
      }
    }

    .p-fileupload-buttonbar {
      width: 30%;
      background: none !important;
      border: 1px solid #eae6e1 !important;
      border-left: none !important;
      height: auto;
      order: 2;
    }

    .p-fileupload-content {
      .p-progressbar {
        display: none;
      }

      .p-fileupload-files {
        .p-fileupload-row {
          margin-top: 5px;
        }

        order:2
      }

      display: flex;
      width: 100%;
      height: 100%;
      padding: 14px !important;
      border: 1px solid #eae6e1 !important;
      border-right: none !important;
      color: #1d2125 !important;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      order:1
    }

    .form-btns {
      display: flex;
      place-content: space-evenly;
    }
  }

}

//merge pdf end

fury-tax-automation-add-configure {
  .headingLabel {
    margin-bottom: 8px;
    padding: 3px 10px 3px 5px;

    span {
      background: #f8f8f8;
      width: 100%;
      color: #000;
      font-size: 14px;
      display: block;
      padding: 5px;
      padding-left: 16px;
    }
  }

  #managementFeeSelectlabelInvestor {
    margin-bottom: 15px;
  }

  #managementFeeSelectlabelVendor {
    margin-bottom: 15px;
  }

  #performanceFeeSelectlabelInvestor {
    margin-bottom: 15px;
  }

  #performanceFeeSelectlabelVendor {
    margin-bottom: 15px;
  }


}




/////////Start  MFA Css////

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

body:has(#mfaCard),
body:has(#gaCard) {
  .customerdetails {
    margin: 22px 9px -29px 6px !important;
  }

  .otpBtn {
    button {
      height: 2.4rem !important;
      margin-left: 10px !important;
    }

    display: flex;
    align-items: center;
    justify-content: flex-start !important
  }

  .headerLogo .dark {
    display: none;
  }

  .mfa .headerLogo .dark {
    display: block;
  }

  .no-box-shadow .headerLogo .dark {
    display: block;
  }

  .mfa.fury-dark .headerLogo .dark {
    display: none;
  }

  .mfa.fury-dark .headerLogo .light {
    display: block;
  }

  .headerLogo {
    display: flex;
    margin-top: 2vw;
    margin-left: 3vw;
  }

  .headerLogo .light {
    display: none;
  }

  .fury-dark .no-box-shadow .headerLogo .light {
    display: block;
  }

  .fury-dark .no-box-shadow .headerLogo .dark {
    display: none;
  }

  .mfa .fury-dark .headerLogo .dark {
    display: none;
  }

  .mfa .fury-dark .headerLogo .light {
    display: block;
  }

  .fury-light .sideheaderLogo .dark {
    display: block;
  }

  .fury-light .sideheaderLogo .light {
    display: none;
  }

  .fury-default .sideheaderLogo .dark {
    display: none;
  }

  .fury-default .sideheaderLogo .light {
    display: block;
  }

  .fury-dark .sideheaderLogo .dark {
    display: none;
  }

  .fury-dark .sideheaderLogo .light {
    display: block;
  }

  .fury-flat .sideheaderLogo .dark {
    display: block;
  }

  .fury-flat .sideheaderLogo .light {
    display: none;
  }

  .fury-dark .inputColor {
    color: #000 !important;
  }

  .menuButton .mat-radio-label-content {
    display: flex;
    align-items: center;
  }

  .menuButton img {
    margin-right: 10px;
  }

  .setGridHeight {
    width: 100%;
  }

  .ag-center-cols-container {
    min-height: auto !important;
  }

  .contactFund .fury-list-name {
    font-size: 18px !important;
  }

  .contactFund {
    border: 1px solid #e3e1e1;
  }

  .contactFund .ag-header-cell-text {
    color: #000 !important;
    font-size: 13px;
    font-weight: 600;
  }

  .MFAmessage .text-warning {
    color: #ffc107 !important;
  }

  .MFAmessage .text-success {
    color: #4caf50 !important;
    padding-right: 5px;
  }

  .MFAmessage a {
    color: #1f87e6;
    text-decoration: none;
    background-color: transparent;
    font-weight: 600;
    // -webkit-text-decoration-skip: objects;
  }

  .MFAmessage {
    text-align: center;
  }

  .MFAmessage .mat-icon {
    color: #ffc107 !important;
    font-size: 20px;
    margin-top: 5px;
    margin-right: 3px;
  }

  .MFAmessage p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }

  #mfaCard {
    width: calc(100% - 50%);
  }

  .mfaHeader {
    background-color: #ededef;
    font-weight: bold;
    text-align: center;
    padding: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mfaHeader img {
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .mfaHeader p {
    font-size: 16px;
    margin: 0px;
    font-weight: 600;
  }

  .MFAfontstyle p {
    font-weight: 600 !important;
  }

  .passwordButton {
    display: flex;
    justify-content: space-between;
  }

  .passwordButton .login_blue_btns {
    border-radius: 0px;
    padding: 0 25px;
  }

  .custom {
    border: 1px solid rgba(0, 0, 0, .40);
    padding: 8px 0;
    color: #000000 !important;
    border-radius: 4px;
  }

  .otpBox input {
    border-radius: 0px !important;
    border: 1px solid #1f87e6 !important;
  }

  .identityText .p-dropdown {
    width: 37%;
    border: 1px solid rgba(0, 0, 0, .40);
  }

  .trainingCard .mat-vertical-stepper-content {
    overflow: unset !important;
  }

  .mfaModal .p-dialog {
    left: 0 !important;
  }

  .mfaModal .p-dialog-header {
    display: none;
  }

  .mfaModal .p-dialog-footer {
    text-align: center !important;
  }

  .trainingCard .mat-vertical-stepper-content {
    overflow: unset !important;
  }
  .identityText .p-dropdown {
    width: 37%;
    border: 1px solid rgba(0, 0, 0, .40);
  }
  .otpBtn {
    button {
      height: 2.4rem !important;
      margin-left: 10px !important;
    }
    display: flex;
    align-items: center;
    justify-content: start !important
  }
  .trainingCard .mat-vertical-stepper-content {
    overflow: unset !important;
  }
  .trainingCard .mat-vertical-stepper-content {
    overflow: unset !important;
  }
  .mfaModal .MFAbutton {
    text-align: center;

  }

  .mfaModal .MFAbutton button {
    background-color: #1976d2 !important;
    margin-top: 30px;
  }

  .mfaModal .MFAbutton button {
    background-color: #1976d2 !important;
    text-transform: uppercase;
    border-color: #1976d2 !important;
    font-size: 14px;
  }

  .otpIcon {
    display: flex;
  }

  .otpIcon img {
    width: 40px;
    margin-left: 10px;
  }

  .wrongOtp {
    display: flex;
    align-items: center;
  }

  .wrongOtp i {
    font-size: 25px;
    color: red;
    margin-left: 10px;
  }

  .mfaModalContent {
    text-align: center;
  }

  .mfaModalContent strong {
    color: #595959;
    font-size: 30px;
    font-weight: 600;
    text-transform: none;
    margin: 0;
    padding: 0;
    line-height: 60px;
    display: block;
  }

  .mfaModalContent p {
    font-size: 18px;
    text-align: center;
    //font-weight: 300;
    position: relative;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    color: #545454;
  }

  .mfaModalContent .wrongIcon {
    color: #f27474;
    font-size: 65px;
    font-weight: 300;
  }

  .MFAbutton button {
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    padding: 0 40px;
    line-height: 0px !important;
    height: 2.6rem;
  }

  .otpBox input {
    border-radius: 4px !important;
    border-color: rgba(0, 0, 0, .40) !important;
  }

  .MFAbutton .confirmButton {
    float: right;
  }

  .MFAmessage a {
    margin-left: 5px;
  }

  .customDropdown .mat-option {
    overflow: visible;
  }

  .customDropdown .mat-select-panel {
    overflow: auto !important;
  }

  .customDropdown .mat-option-text {
    text-overflow: unset;
    overflow: unset;
  }

  #otpBoxes .wrapper {
    display: flex;
  }

  // START All apps Choose category button 
  .homeDropdown .p-dropdown {
    border-radius: 5px !important;
  }

  // END All apps Choose category button 

  // START Font family change
  .p-component,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  .label-font,
  a.caption,
  p,
  b,
  .p-multiselect,
  .nav-tabs li a,
  .ag-status-panel-total-row-count span,
  .title,
  .link,
  .inputmodal li,
  .fury-title,
  .style-name,
  .gen-password ul li,
  .ag-side-button-label,
  .ag-column-select-column-label,
  .ag-picker-field-display,
  .ag-text-field-input,
  .ag-filter-apply-panel-button,
  .ag-menu-option-text,
  .moodal-view-toolbar ul li a,
  .dropdown .list-item span,
  .fury-list-name,
  .sectionheading,
  .MFAtext span,
  .p-inputtext,
  .value a,
  .label a,
  .homeDropdown span,
  .p-dropdown-items li span,
  .duration-rounded,
  .value,
  .paginationInput span,
  .docTabel tr th,
  .docTabel tr td,
  .ag-status-name-value span,
  .MFAfontstyle p,
  .MFAfontstyle span,
  .iti__flag-box span,
  .otpText span,
  .otp-input,
  .selected-dial-code,
  .custom,
  .search-container input {
    font-family: var(--font-family) !important;
  }

  .elevation {
    label {
      white-space: nowrap;
    }
  }

  p-multiselectitem span:first-child {
    font-family: 'primeicons' !important;
  }

  p-multiselectitem span {
    font-family: 'open sans' !important;
  }

  //  END Font family change


  // .mat-stepheader {
  //   .mat-step-header {
  //     background-color: #fff !important;
  //   }

  //   .mat-step-header:hover {
  //     background-color: rgb(242 242 242) !important;
  //   }
  //   .mat-step-text-label{
  //     color: #1d2125;
  //   }

  // }

  span.mat-placeholder-required.mat-form-field-required-marker {
    display: none !important;
  }

  .centerCheckbox {
    display: flex;
    justify-content: center;
  }

  .warning_external .p-dialog .p-dialog-footer {
    text-align: center !important;
  }

  .whiteListDropDown .p-dropdown {
    width: 90%;
    line-height: 30px;
  }

  .whiteListDropDownBtn .p-button {
    padding: 7px 30px;
    width: 100px;
    padding: 0.618rem;
    margin-top: 5px;
  }

  .setup-process .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
    display: none;
  }

  .hide-advance {
    display: none !important;
  }

  // .show-advance {
  //   display: block !important;
  // }
  .rapid-pos-btn {
    margin-left: 0px !important;
    margin-right: 0px !important;
    border-radius: 0px !important;

    .adv-config {
      color: #1976D2;
      cursor: pointer;
      font-size: 15px;
      font-weight: bold;

      &::after {
        content: '';
        position: absolute;
        right: 38px;
        top: 26px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 11px solid #1d2125;
        clear: both;
      }
    }

    .adv-config-after {
      color: #1976D2;
      cursor: pointer;
      font-size: 15px;
      font-weight: bold;

      &::after {
        content: '';
        position: absolute;
        right: 38px;
        top: 26px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 11px solid #1d2125;
        clear: both;
      }
    }

    .next {
      background: #1976d2 !important;
      color: #fff !important;
      margin-right: 1rem !important;
    }

    .setup-advance-details {

      .mat-step-header {
        background: #f9f9f9 !important;
        border-top: solid 1px #efefef;
        border-bottom: solid 1px #efefef;
        border-radius: 0;

        .mat-step-text-label {
          color: #000 !important;
          font-size: 16px !important;
          font-weight: 600 !important;
        }
      }

    }

    .splitgroupbtn {
      //height: 2.2rem;
      // margin-top: 0px;
      margin-right: 0% !important;
      //.mat-button-toggle {
      //background-color: #fff;
      //width: 70px;

      // span {
      //     .ui-text {
      //        // color: #2e2d2d;
      //         font-weight: 400 !important;
      //         border-right: none;
      //         text-transform: capitalize;
      //         font-weight: bold !important;
      //         padding: 0.5em 1em 0.5em 1.2em;
      //     }

      //     .mat-menu-item {
      //         line-height: normal;
      //         height: 33px;
      //     }
      // }
      // }
    }
  }

  .adv-config-acc {
    color: #1a73f1;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;
    width: 100%;
    padding: 15px 0px;

    &::after {
      content: "";
      position: relative;
      left: 3px;
      top: 16px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 11px solid #1a73f1;
    }
  }

  .adv-config-acc-after {
    color: #1a73f1;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;
    width: 100%;
    padding: 15px 0px;

    &::after {
      content: "";
      position: relative;
      left: 3px;
      top: -15px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 11px solid #1a73f1;
      clear: both;
    }
  }

  .setup-advance-details {

    .mat-step-header {
      background: #f9f9f9 !important;
      border-top: solid 1px #efefef;
      border-bottom: solid 1px #efefef;
      border-radius: 0;

      .mat-step-text-label {
        color: #000 !important;
        font-size: 16px !important;
        font-weight: 600 !important;
      }
    }

  }

  .u-managment-card {
    background: none !important;
    box-shadow: none !important;
    padding-bottom: 30px;

    .mat-stepheader {
      box-shadow: 0 4px 5px 1px rgba(64, 60, 67, 0.1607843137);
    }

    #formstiky {
      display: initial !important;
      box-shadow: 0 2px 5px 1px #403c4329;
      margin: auto;
    }

    .mat-step:first-child .mat-step-header .mat-step-label,
    .setup-process .mat-step:first-child .mat-step-header .mat-step-label:active {
      width: 100% !important
    }

    .mat-step:first-child .mat-step-header {
      padding: 24px !important;
      border-radius: 0 !important;
      line-height: 38px;
      background: #fff;
    }

    .mat-vertical-content-container {
      margin-left: 0px !important;
    }

    .setup-advance-details {
      .mat-step-header {
        background: rgba(25, 118, 210, .05) !important;
      }
    }

    .mat-stepper-vertical {
      border-radius: 0px;
      margin-top: -2rem;
    }

    .mat-vertical-stepper-header {
      align-items: center !important;
    }
  }

  .user_advance .mat-step-header {
    display: none !important;
  }

  .u-managment-card div#formstiky.setup-advance-details.user_advance {
    box-shadow: 0 4px 5px 1px rgba(64, 60, 67, 0.1607843137);
  }


  .process_log {
    display: flex !important;
    z-index: 111;
    align-items: center;
    margin-top: 0px !important;
    padding: 1em !important;
    justify-content: flex-end !important;

    .countClass2 {
      margin-right: 15px !important;
      padding: 0 0.7em !important;
    }

  }

  .processlogcal .md-drppicker.ltr {
    position: relative;
    left: 0px !important;
    right: 0px !important;
    width: 90%;
  }

  .processlogcal .datepick {
    border: 1px solid #e0e4e7;
    height: 42px;
    width: 96%;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .processlogcal {
    position: relative;
  }

  .processlogcal .date-range-picker-icon {
    margin-left: auto;
    z-index: 1;
    position: absolute;
    right: 22px !important;
    top: 15px;
    left: auto;
  }

  .processlogdropdown {
    padding-right: 1em !important;
  }

  .processloguser {
    padding-left: 0px !important;
  }

  //START Revenue Report datepicker,Input and Text alignment issue
  .reports-datepicker {
    margin-top: -17px;

    .mat-form-field-suffix {
      top: 0.75em !important;
    }
  }

  .reports-datepicker-customer-ledger {
    margin-top: 0px;

    .mat-form-field-suffix {
      top: 0.75em !important;
    }
  }

  #reportType {
    .mat-radio-group {
      margin-top: 7px !important;
      margin-left: -2px;
      display: flex;
      flex-flow: wrap;
    }

    .mat-radio-button {
      margin-right: 40px;
      width: 43%;
    }
  }

  //END Revenue Report datepicker,Input and Text alignment issue

  // START Header All Entity dropdwon arrow,all plus button & arrow and Datepicker alignment issue
  .mat-form-field-suffix {
    top: 0.75em !important;
  }

  .text-center {
    .mat-select-arrow-wrapper {
      align-items: center !important;
      display: flex !important;
    }
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }

  .addSelectOption {
    top: 0.7rem !important;
  }

  .addSelectOption-slab {
    width: 100% !important;
    display: flex !important;
    margin-left: 40px !important;
  }

  #formstiky span.stickyButton {
    right: 1.8em !important;
  }

  // END Header All Entity dropdwon arrow,all plus button & arrow and Datepicker alignment issue

  .showOnlyMonth {
    height: auto !important;

    .mat-calendar-header {
      display: none !important;
    }

    .mat-calendar-body-label {
      display: none !important;
    }
  }

  #mfaCard .pi-chevron-down:before,
  #gaCard .pi-chevron-down:before {
    content: "" !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    border: none;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
  }

  input#phone {
    padding-left: 90px !important;
  }

  .selected-dial-code {
    color: #212121 !important;
  }
}

// START MFA Message Header
.MFAmessage span {
  margin: 0.4rem 0.6rem;
}

.MFAmessage mat-icon.mat-icon {
  color: #dbb008 !important;
}

.MFAmessage a.mat-tooltip-trigger {
  color: #1976d2 !important;
  font-weight: 700;
  margin-left: 5px;
}

.mfabacktbn {
  align-self: center;
  margin-right: 2rem;

  span {
    display: flex;
    align-items: center;

    .mat-icon {
      margin-right: 5px;
    }
  }
}

.fury-dark .mfaModal {
  .mfaModalContent {
    color: #fff !important;

    p,
    strong {
      color: #fff !important;
    }
  }
}

.fury-dark .MFAfontstyle {

  .text-grey p,
  p,
  strong {
    color: #fff !important;
  }
}

.cash-balance-process {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 5px !important;
}

// END MFA Message Header 
// END 

// START Header help and Resource button Training video UI design issue fixed
.cuvcontrol {
  min-width: 13rem !important;
  max-width: 13rem !important;

  caption {
    white-space: normal !important;
    word-break: keep-all !important;
  }
}

.createpopup.custom-setting-panel .popuporow ul li span a.text {
  white-space: normal !important;
  word-break: break-word !important;
}

.trainingCard .mat-vertical-stepper-content {
  overflow: unset !important;
}

// END Header help and Resource button Training video UI design issue fixed

// Processing button Automate process card icon placemnet
fury-processing {
  .icnset-view {
    width: 25% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    align-items: center;
    padding-right: 5px;
  }
}

// Processing button Automate process card icon placemnet
// Taxation additionk1 details form UI design issue
fury-additionk1-details-entry-tax {
  #amendedReturnAndScheduleK1 .mat-form-field {
    display: unset !important;
  }

  .btnRadius .mat-focus-indicator {
    border-radius: 4px !important;
    position: absolute;
    right: 26px;
    margin-top: 0px;

  }

  #investorDetails {
    margin-top: 3.5rem;
  }

  .headingLabel {
    margin-bottom: 20px;
    padding: 3px 10px 3px 5px;

    span {
      background: #f8f8f8;
      width: 100%;
      color: #000;
      font-size: 14px;
      display: block;
      padding: 5px;
      padding-left: 16px;



    }
  }


  .rapid-pos-btn .splitgroupbtn:first-child {
    margin-left: 15px;
  }
}

// Taxation additionk1 details form UI design issue

fury-configure-execute-entry{
#fundListing{
  min-height: 60px !important;
  padding-bottom: 0px !important;
}
}
fury-compound-journal-entry,fury-recurring-transaction-entry,fury-payment-data-create,fury-nontradeactivity-entry,fury-receipts-data-create{
  .dialogwithwhitebtn .mright-10{
    margin-right: -30px !important;
    margin-top: 10px;
  }
  
  .dialogcustomcloseicon {
    position: absolute;
    right: 1em;
    top: 0.7em;
    z-index: 2;
    color: #fff;
    cursor: pointer;
  }
  }
fury-bills-create,fury-vendor-cashexpense,fury-invoice-create,fury-cash-invoice{
  .dialogwithwhitebtn .mright-10{
    margin-right: -30px !important;
    // margin-top: 10px;
  }
  
  .dialogcustomcloseicon {
    position: absolute;
    right: 1em;
    top: 0.7em;
    z-index: 2;
    color: #fff;
    cursor: pointer;
  }
  .topGap .mat-vertical-content{
    padding-top: 0px !important;
  }
}
// Start Configuration fee details form UI design issue
fury-tax-automation-add-configure {
  .headingLabel {
    margin-bottom: 8px;
    padding: 3px 10px 3px 5px;

    span {
      background: #f8f8f8;
      width: 100%;
      color: #000;
      font-size: 14px;
      display: block;
      padding: 5px;
      padding-left: 16px;
    }
  }

  #managementFeeSelectlabelInvestor {
    margin-bottom: 15px;
  }

  #managementFeeSelectlabelVendor {
    margin-bottom: 15px;
  }

  #performanceFeeSelectlabelInvestor {
    margin-bottom: 15px;
  }

  #performanceFeeSelectlabelVendor {
    margin-bottom: 15px;
  }


}


// Taxation Configuration fee details form UI design issue
fury-bills-create {
  #symbol {
    padding: 0px !important;
  }

  #investorNumber {
    padding: 0px !important;
  }
}

fury-configure-execute-entry {
  #fundListing {
    padding-bottom: 0px !important;
    min-height: 60px !important;
  }
}

fury-batch-tracking {

  #fund,
  #coustodianAccount {
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 1.2em;
    margin-bottom: 3px;
  }
}

fury-batch-tracking-entry {

  #fund,
  #coustodianAccount {
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 1.2em;
    margin-bottom: 3px;
  }
}

fury-dynamic-form-table {
  margin: 0 10px 8px 7px;
}

fury-fs-config-entry {
  .fsCard {
    .setup-process .step-flow {
      width: 10em !important;
    }

    .setup-process .step-flow .step-work {
      width: 230px;
    }

    .setup-process .step-flow .step-work p {
      text-align: left;
    }
    .setup-process .step-flow .step-work h1{
      font-size: 13.5px;
    }
  }

  .mat-form-field-wrapper {
    min-height: 59px;
  }
}

view-btn-cell-renderer {
  .agclear {
    color: red;
    cursor: pointer;
    font-size: 20px;
    margin-top: 14px;
    padding: 0px 5px;
  }

  .agvisibility {
    color: #212939;
    cursor: pointer;
    font-size: 20px;
    margin-top: 14px;
    padding: 0px 5px;
  }

  .unlockable-button {
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    position: relative;
    align-items: center;
    align-content: center;
    border-style: none;
  }

  .locked {
    cursor: not-allowed;
    border-style: none;
    background-color: transparent;
    color: grey;
  }
}

#pricingSource {
  // margin-bottom: 15px !important;
  padding: 0px 11px 19px 5px !important;
}

fury-chart-of-account-tax-classification {
  .actions.oms-list-btns {
    margin-left: -3rem !important;
  }
}


fury-chart-of-account-tax-classification {
  .actions.oms-list-btns {
    margin-left: -3rem !important;
  }
}

fury-rapid-workflow-tree {
  .pi {
    font-family: 'primeicons' !important;
  }
}

app-endpoint-schduler-input {
  .fury-page-layout-content .grid-spcui {
    padding-right: 0px !important;
    padding-left: 0px !important;
    border-top: 1px solid #f2f2f2 !important;
  }
}

.setup-process .default-grid .mat-step .trade-list-input:first-child .mat-step-header {
  background-color: #eae6e1 !important;
  line-height: 44px !important;
}

// Financial, AUM and Crypto Dashbaord
.financial-bg .content {
  background-color: #f4eadf;
}

fury-crypto-dashboard,
fury-financial-dashboard,
fury-aum-dashboard {
  .mat-button-wrapper {
    padding: 0px !important;
  }
}


fury-dashboard-carousel {
  .inv-card-bg {
    box-shadow: 3px 3px 15px 3px #dee8ef;
  }

  .column {
    background-color: #fff !important;
  }
}



.carousel-custom-container {
  max-height: calc(100vh - 215px);
  padding: 0 55px;
  overflow-y: auto;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  color: #fff !important;
  border-color: transparent;
  background: #283144 !important;
}

.p-carousel-prev-icon {
  font-size: 1.5rem !important;
}

.p-carousel-next,
.p-carousel-prev {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

canvas {
  max-width: 100% !important;
}

fury-dashboard-carousel {
  .mat-form-field-suffix {
    top: 0.75em !important;
  }
}

fury-dashboard-carousel {
  #fury-dashboard {
    padding: 0px;
  }

  .inputrow {
    padding-left: 24px !important;
    padding-top: 24px !important;
  }
}

.investor-height .inputrow {
  padding-left: 0px !important;
  padding-top: 24px !important;
  margin-left: -31px;
}


.investor-height {
  .carousel-custom-container {
    max-height: calc(100vh - 100px) !important;
  }

  .p-carousel .p-carousel-indicators {
    padding: 1rem !important;
  }

  .p-carousel-prev {
    margin-top: 7.8rem !important;
  }

  .p-carousel-next {
    margin-top: 7.8rem !important;
  }

  overflow-y: hidden !important;
}

.scroll .content {
  overflow-y: hidden !important;
}

// Financial, AUM and Crypto Dashbaord


.post-btn {
  background-color: #1976d2 !important;
  border-radius: 5px;
  height: 3.1rem !important;
  border: 0;
  span.ui-text {
    color: #fff;
  }
}


fury-create-capital-activity-entry {
  .capital-activity-input {
    .input-width {
      width: 48% !important;
    }

    .checkbox-width {
      width: 2% !important;
    }

    .col-3-input {
      width: 24% !important;
      padding: 0px !important;
    }
  }

}

.ca_body {
  .col-lg-3 {
    padding: 0px !important;
  }
}

fury-custodians-entry{
  .inputbtninset2.cancel {
  right: 5em;
  width: 1em !important;
  padding: 0px 0px !important;
}

.inputbtninset2 {
  position: absolute;
  right: 6em;
  top: 2.4em;
  width: 0.2em !important;
  padding: 0px 0px !important;

  button {
    width: 19px;
    height: 19px;

    .mat-button-wrapper {
      padding: 4px 0 !important;
      line-height: 0px;
      display: block;

      .material-icons-outlined {
        font-size: 14px;
      }
    }
  }
}
}
.addSelectOption img{
  width: 24px;
}
.save-btn{
  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {   
      max-width: 78vw !important;
  }
  }
  fury-compound-je-list{
    .gridbtn-ui{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
  fury-automate-process {
    .dialogbox_input {
      .mat-form-field-suffix {
        top: .75em !important;
      }
    }
  }
  fury-automate-process {
    .dialogbox_input {
      .mat-form-field-suffix {
        top: .75em !important;
      }
    }
  }
  
  .taxHistoricalData {
    padding-top: 15px;
  
    .mat-form-field {
      width: 50%;
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .tax-input {
      padding-left: 0px !important;
    }
  }
  fury-investors{
  .input-width{
    .label-font{
      padding: 0px !important;
    }
  }
  }
  
  body:has(#duplicateInv) .fury-list-header{
    height: auto !important;
  }
  .calculateTaxAllocationExecuteBtn {
    .mat-focus-indicator {
      margin-left: 0px !important;
    }
  
  }
  
  .tax-data {
    .mat-form-field-outline {
      margin: 3px 0px !important;
    }
  }
  
  .input-tax {
    .mat-form-field-outline {
      margin: 3px 0px !important;
    }
  }
  
  .save-btn {
    .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
      max-width: 78vw !important;
    }
  }
  
  // START Taxation button tax adjusted grid 
  body:has(tax-fury-setup .tax-adjusted-grid) {
    .ag-theme-alpine .ag-ltr .ag-cell {
  
      &:nth-child(3),
      &:nth-child(7) {
        border-right: 2px solid #b6aaaa !important;
      }
    }
  
    .ag-header-cell {
  
      &:nth-child(3),
      &:nth-child(7) {
        border-right: 2px solid #b6aaaa !important;
      }
    }
  }
  
  // END Taxation button tax adjusted grid 
  
  fury-tax-automation-add-configure {
    .infotax {
      p {
        text-align: justify !important;
        font-weight: 400 !important;
      }
    }
  }
  
  fury-additionk1-details-entry-tax {
    .newbtn {
      .material-icons {
        font-family: unset !important;
        vertical-align: middle;
        font-size: 14px;
      }
    }
  }
  
  .editableDropdown {
    .mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius: 4px 4px 0 0;
      padding: 0px 0px 0 0px !important;
    }
  
    .mat-form-field-wrapper {
      padding-bottom: 1.2em;
      min-height: 0px !important;
    }
  
    .mat-form-field-appearance-fill .mat-form-field-infix {
      width: 196px;
      padding: 0.25em 0 0.75em 0;
    }
  
    .mat-form-field-underline {
      display: none !important;
    }
  }
  
  
  fury-tax-return-package-new {
    .fury-page-layout-content {
      margin-top: -1.2rem;
    }
  }
  
  
  fury-common-income-expense-config-entry{
    .expense-input{
     padding: 0px !important;
     width: 23.33% !important;
    }
    
   
    .expense-input-sm{
      .p-inputnumber input {
        width: 15% !important;
      }
      padding: 0px !important;
      width: 15% !important;
    }
    }
      fury-book-income-expense-entry {
        .bookLabel {
          padding-top: 22px !important;
          padding-left: 5px !important;
        }
    
        .bookLabel-last {
          padding-top: 12px !important;
          padding-left: 5px !important;
        }
        .bookLabel1 {
          padding-left: 5px !important;
        }
      }
      
  
     .seticon { margin-left: 30px;}
  
  
     // Partnership Passthrough  K-1(from 1065) UI design issue
  .pship-table {
    .p-datatable table {
      border: 1px solid #e0e0e0 !important;
      margin-top: 24px !important;
    }
  
    thead tr th {
      padding: 0.5rem 0.5rem !important;
    }
  
    tbody tr td {
      padding: 0.3rem 0.5rem !important;
    }
  
    .p-datatable-thead tr th {
      height: 36px !important;
    }

    &:hover {
      border-color: #1976d2 !important;
      border: 2px solid #1976d2 !important;
    }
  }
  
  .pship-table table thead tr th:nth-child(1) {
    width: 12% !important;
  }
  
  .editableSearchDropdown {
    .p-dropdown-label {
      padding: 0.7px 19px !important;
    }
  
    input.p-dropdown-filter.p-inputtext.p-component {
      padding: 0px 5px !important;
    }
  
    .p-dropdown-panel.p-component {
      left: -8px !important;
    }
  
    .p-dropdown .p-dropdown-clear-icon {
      margin-top: 1px !important;
    }
  
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      text-transform: none !important;
      white-space: nowrap;
    }
  
    .p-dropdown {
      background: #fff !important;
      border: 1px solid lightgray !important;
      border-radius: 0px !important;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }
  }
  fury-fetch-csd-bank-transaction-review {
    button.mat-menu-item {
      width: unset !important;
    }
  }
  
  .toolbar {
    .duplicateico:hover {
      transform: scale(1.3) !important;
    }
  
    .note:hover {
      transform: scale(1.3) !important;
    }
  }
  .p-0{
    padding-top: 0px !important;}
  
    .investor-table {
      .p-datatable table {
        border: 1px solid #e0e0e0 !important;
        margin-top: 24px !important;
      }
    
      thead tr th {
        padding: 0.5rem 0.5rem !important;
      }
    
      tbody tr td {
        padding: 0.3rem 0.5rem !important;
      }
    
      .p-datatable-thead tr th {
        height: 36px !important;
      }
    
      .partnership-input {
        width: 100% !important;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        height: 24px;
    
        &:focus {
          border-color: #1976d2 !important;
          border: 2px solid #1976d2 !important;
        }
    
        &:hover {
          border-color: #1976d2 !important;
          border: 2px solid #1976d2 !important;
        }
      }
    }
    
    .investor-table table thead tr th:nth-child(1) {
      width: 50% !important;
    }
  
  // start css taxAllocationList
  .taxAllocationList{
    height: calc(85vh - 5em - 300px) !important;
      
      .ag-full-width-container{
        line-height: 0px;
        height: 450px;
        .ag-full-width-row {
          height: 300px !important;
          .ag-theme-alpine .ag-details-row {
            padding: 15px 30px 15px 30px !important;          
          }
          .ag-cell{
            line-height: 26px !important;
          }
        }
      }
    
    }
      fury-tax-allocation-adjustment-ratios-list{
        fury-detail-cell-renderer-table .ag-header-cell-text{
          overflow: unset !important;
        }
      }
      fury-tax-allocation-adjustment-ratios-list{
        .setGridHeight  .ag-cell:nth-child(2){
         line-height: 38px !important;
        }
      }
  
  // End taxAllocationList css
  .pship-k1 table thead tr th:nth-child(1) {
    width: auto !important;
  }

  fury-balance-sheet{
  #monthlyColumnarReport{
    align-items: center;
  }
  .label-font{
    white-space: nowrap;
  }
}
fury-tax-allocation-adjustment-ratios-list,fury-product-mapping-list,
fury-corporate-action {
  fury-detail-cell-renderer-table .ag-header-cell-text {
    overflow: unset !important;
  }
}

fury-tax-allocation-adjustment-ratios-list,fury-product-mapping-list,
fury-corporate-action {
  .setGridHeight .ag-cell:nth-child(2) {
    line-height: 38px !important;
  }
}

// End taxAllocationList css
fury-create-capital-activity-entry {
  .transferCa {
    font-weight: 700;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 2%;
    font-size: larger;
    min-height: 3.5em !important;
  }

  // #Transferor{
  //   min-height: ;
  // }
}

.pship-k1 table thead tr th:nth-child(1) {
  width: auto !important;
}

.fsNotesTaxation {
  .mat-vertical-content {
    padding: 0 24px 10px 24px;
  }

  #addNotesToAccount {
    margin-top: -1rem;
  }

  .first-form {
    margin-top: 24px !important;
  }

  .formShow {
    margin-top: 0px !important;
  }

  .formcheck-box {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pb-2 {
    padding-bottom: 0px !important;
  }
}
.isduploadicon {
  width: 9.0rem !important;
}

edit-cell-multi-select-dropdown {
  .p-multiselect-label {
    padding: 0px 15px !important;
  }
}
// fury-equity-schedule-summary {
.equity-schedule {
  .iframe-title {
    position: relative;
  
    .title-change {
      position: absolute;
      top: 11px;
      left: 50px;
      height: 34px;
      line-height: 34px;
      background: #323639;
      width: 100%;
      max-width: 377px;
      color: #fff;
    }
  
    .download {
      position: absolute;
      top: 15px;
      right: 80px;
      height: 34px;
      line-height: 34px;
      background: #323639;
      width: 36px;
      height: 36px;
      color: #fff;
      cursor: default;
      border-radius: 100%;
    }
  
    #title {
      color: #323639;
    }
  }
}

// cr-icon-button #download {
//   display: none !important;
// }
// }
fury-trades-pending-for-review,
other-pending-transactions {
  .actions {
    white-space: nowrap !important;
  }
}

.rowInput {
  display: flex;

  .col-lg-4 {
    width: 100%;
  }

  .mat-form-field {
    width: 100% !important;
  }
}
fury-product-mapping-list {
  .fury-page-layout-content {
    padding-left: 0px !important;
  }
}
.inputfield-producttax {

  .mat-form-field {
    position: relative;
    top: 0px;
    height: 40px;
  }

  .mat-form-field-infix {
    padding: .5em 0 .5em 0;
  }

  .mat-form-field-label-wrapper {
    top: -1.25375em;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    top: 31px !important;
  }
}
fury-product-mapping-list {
  .fury-page-layout-content {
    padding-left: 0px !important;
  }
}
edit-cell-dropdown-add-value {
.addSelectOptionTax{
  position: absolute;
  top: 3px;
  display: block;
  right: 30px;
  font-size: 22px;
}

.addTax{
width: 100%;
    height: 30px;
    border-color: #a2d1f6;
}
.addSelectOptionTax1{
  position: absolute;
  top: 3px;
  display: block;
  right: 7px;
  font-size: 22px;
}
}

.customtooltipnew {
  span{ display: none; position: relative;}
  &:hover span{ 
    position: absolute; left: 0;
    display: flex;
      background: #646668;
      color: #fff;
      font-size: 11px;
      border-radius: 2px;
      padding: 0px 7px;
      /* line-height: 20px; */
      height: auto;
      /* display: -webkit-inline-box; */
      max-height: 23px;
      line-height: 2;
      
  }
  }

  fury-Investor-dashboard{
  .customtooltipnew:hover span {
    position: absolute;
    left: 0;
    display: flex;
    background: #646668;
    color: #fff;
    font-size: 11px;
    border-radius: 2px;
    padding: 0px 7px;
    /* line-height: 20px; */
    height: auto;
    /* display: -webkit-inline-box; */
    max-height: 23px;
    line-height: 2;
    bottom: 35px;
}
}
