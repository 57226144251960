/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
@function mat-color($palette, $hue: default, $opacity: null) {
  @if type-of($hue) == number and $hue >= 0 and $hue <= 1 {
    @return mat-color($palette, default, $hue);
  }

  $color: map-get($palette, $hue);

  @if (type-of($color) != color) {
    @if ($opacity == null) {
      @return $color;
    }

    // Here is the change from the original function:
    // If the $color resolved to something different from a color, we assume it is a CSS variable
    // in the form of rgba(var(--rgba-css-var),a) and replace the 'a' value.
    @return #{str-slice($color, 0, str-index($color, ',')) + $opacity + ')'};
  }

  @return rgba($color, if($opacity == null, opacity($color), $opacity));
}

$mat-elevation-color: #523f68;
$mat-elevation-opacity: 0.3;
