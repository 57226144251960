/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");

// Rapid only
.mr-0 {
    margin-right: 0px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.txt-upr-case {
    text-transform: uppercase !important;
}

fury-sidenav-item mat-icon.setup {
    display: none;
}

fury-sidenav-item .imgSetup {
    width: 20px;
    display: block !important;
}

.progress-bar text tspan:nth-child(3) {
    display: none;
}

.progress-bar text tspan {
    font-size: 30px;
    fill: #a3a3a3 !important;
    font-weight: 700;
    alignment-baseline: mathematical;
}

.progress-bar svg path {
    stroke: #48bf91;
    stroke-width: 14;
    stroke-linecap: inherit;
    fill: none;
}

.progress-bar svg circle {
    r: 60;
}

.setup-process .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
    display: none;
}

.setup-process .mat-step-header .mat-step-icon-selected,
.setup-process .mat-step-header .mat-step-icon-state-done,
.setup-process .mat-step-header .mat-step-icon-state-edit,
.setup-process .mat-step-header .mat-step-icon-state-number {
    display: none !important;
}

.setup-process .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.setup-process .mat-vertical-stepper-header {
    padding: 16px 27px !important;
    background: #f9f9f9;
    border-top: solid 1px #efefef;
    border-bottom: solid 1px #efefef;
}

.setup-process {
    .mat-step {
        &:first-child {
            .mat-step-header {
                //background: #1976d2 !important;
                padding: 16px 25px 16px 25px !important;
                border-radius: 0px !important;
                line-height: 38px;
                background: #1976d2;

                .mat-step-text-label {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 500;

                    .mat-button-toggle-group {
                        margin-right: 0%;
                    }
                }

                .cdk-keyboard-focused,
                .mat-step-header.cdk-program-focused,
                .mat-step-header:hover {
                    background-color: #efefef !important;
                    border-radius: 0px !important;
                }

                .mat-step-label {
                    width: 100% !important;

                    &:active {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .selected {
        h1 {
            color: rgb(25 118 210) !important;
        }
    }

    .setGridHeight {
        i {
            line-height: 0px;
        }
    }

    .mat-select-arrow-wrapper {
        height: auto;
    }
}

.setup-process {
    .mat-step:last-child {
        .mat-vertical-content-container {
            margin-left: 0px !important;
        }
    }

    .mat-step-text-label {
        text-transform: capitalize !important;
        color: #fff;
    }
}

.setup-process .mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
    background-color: rgb(239 239 239);
    border-radius: 0;
}

.setup-process {
    span.stickyButton {
        // bottom: 1% !important;
        position: absolute;
        top: 0;
        width: 109px;
        right: 5.2em !important;

        .splitgroupbtn {
            right: 9px;
        }
    }
}

.rapid-pos-btn {
    margin-left: 0px !important;
    margin-right: 0px !important;
    border-radius: 0px !important;

    .adv-config {
        color: #1976D2;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;

        &::after {
            content: '';
            position: absolute;
            right: 38px;
            top: 26px;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 11px solid #1d2125;
            clear: both;
        }
    }

    .adv-config-after {
        color: #1976D2;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;

        &::after {
            content: '';
            position: absolute;
            right: 38px;
            top: 26px;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 11px solid #1d2125;
            clear: both;
        }
    }

    .next {
        background: #1976d2 !important;
        color: #fff !important;
        margin-right: 1rem !important;
    }

    .setup-advance-details {

        .mat-step-header {
            background: #f9f9f9 !important;
            border-top: solid 1px #efefef;
            border-bottom: solid 1px #efefef;
            border-radius: 0;

            .mat-step-text-label {
                color: #000 !important;
                font-size: 16px !important;
                font-weight: 600 !important;
            }
        }

    }

    .splitgroupbtn {
        height: 2.2rem;
        margin-top: 5px;

        .mat-button-toggle {
            background-color: #fff;
            //width: 70px;

            span {
                .ui-text {
                    color: #2e2d2d;
                    font-weight: 400 !important;
                    border-right: none;
                    text-transform: capitalize;
                    font-weight: bold !important;
                    padding: 0.5em 1em 0.5em 1.2em;
                }

                .mat-menu-item {
                    line-height: normal;
                    height: 33px;
                }
            }
        }
    }
}

.setup-process span {
    .mat-raised-button {
        //float: left !important;
        right: 20% !important;
        width: 90px;
        line-height: 31px;
        margin-top: 14px;
        color: #2e2d2d;

        span {
            font-weight: 700 !important;
        }
    }
}

.setup-process span.crypto-marketdata-form {
    .mat-raised-button {
        right: 0% !important;
        width: auto;
        margin-top: 0px;
        background: #fff !important;
        line-height: 30px;
    }
}

.setup-process .card {
    .content {
        margin-top: 24px;
    }

    .adv-content {
        display: flow-root !important;

        .warningm {
            margin-bottom: 10px !important;

            span {
                padding-left: 0px !important;
            }
        }
    }
}

.setup-process {
    .rapid-pos-btn {
        .mat-stepper-vertical {
            min-height: auto;
            width: 615px;
            border-radius: 0px;

        }
    }

    .rapid-field {
        margin-bottom: 24px;
        margin-top: 24px;
        border: solid 1px rgba(0, 0, 0, .12);
        padding: 1em 1em 1em 1em;
        width: 98.6%;
        border-radius: 5px;
        margin-bottom: 1.2em !important;

        [type=checkbox],
        [type=radio] {
            width: 16px;
            height: 16px;
            vertical-align: sub;
            margin-left: 16px !important;
        }

        label {
            padding-left: 8px;
        }
    }

    .algin-gridexpandicon-processing .splitgroupbtn.mr08 {
        margin-right: 0.8em !important;
    }
}

.rapid-field-icon {
    margin-bottom: 24px;
    border: solid 1px rgba(0, 0, 0, .12);
    padding: 0em 1em 1em 1em;
    width: 98.6%;
    border-radius: 5px;
    margin-bottom: 1.2em !important;
    margin-top: 24px !important;

    .ac-typelabel {
        position: absolute;
        top: 13px;
        background: #fff;
        font-weight: 500;
        padding: 0 5px;
    }

    .sm-logo {
        width: 17%;
        vertical-align: unset;
        margin-left: 20px;
        margin-right: 25%;
    }
    .troniq-logo {
        width: 13%;
        vertical-align: bottom;        
        margin-left: 20px;
        margin-right: 25%;
    }

    .sr-logo {
        width: 8%;
        vertical-align: baseline;
        margin-left: 20px;
        margin-right: 24%;
    }

    .si-logo {
        width: 8%;
        vertical-align: sub;
        margin-left: 20px;
    }

    input {
        width: 20px;
        height: 20px;
    }
}

.rapid-field-icon-processing {
    margin-bottom: 24px;
    border: solid 1px rgba(0, 0, 0, .12);
    padding: 0em 1em 1em 1em;
    width: 98.4%;
    border-radius: 5px;
    margin-bottom: 1.2em !important;
    margin-top: 24px !important;
    margin-left: 4px;

    .ac-typelabel {
        position: absolute;
        top: 13px;
        background: #fff;
        font-weight: 500;
        padding: 0 5px;
    }

    input {
        width: 20px;
        height: 20px;
    }
}

.rapid-pos-btn {
    .setup-advance-details {
        .mat-step-header {
            &[aria-selected='true'] {
                background: rgba(25, 118, 210, 0.05) !important;
            }
        }
    }

    .mat-stepper-vertical-line {
        &:before {
            bottom: 0px !important;
        }
    }

    .mat-step-header {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        background: #fff;
        z-index: 1;
    }
}

.adv-config-acc {
    color: #1976D2;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    width: 160px;
    white-space: nowrap;

    &::after {
        content: "";
        position: relative;
        left: 3px;
        top: 16px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 11px solid #1a73f1;
        clear: both;
    }
}

.adv-config-acc-after {
    color: #1a73f1;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    width: 160px;
    white-space: nowrap;

    &::after {
        content: "";
        position: relative;
        left: 3px;
        top: -15px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 11px solid #1a73f1;
        clear: both;
    }
}

.iconset.icnset-view .material-icons-outlined {
    font-size: 18px;
    margin-right: 0px;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #000;
    cursor: pointer;
}

span.material-icons-outlined.vi-icn {
    color: #1976d2 !important;
    padding: 5px;
    border-radius: 50px;
    cursor: pointer;
}

.iconset.icnset-view span img {
    width: 18px;
    margin-top: 5px;
}

.setup-gridview {
    .setup-form {
        .mat-stepper-vertical {
            min-height: auto;
            width: 100% !important;
            border-radius: 0;
        }
    }
}

.setup-grid-form-view {
    .formgrid-view {
        .mat-stepper-vertical {
            min-height: auto;
            width: 100% !important;
            border-radius: 0;
        }

        .fury-list-table {
            background: #fff;
        }

        .setGridHeight {
            height: calc(84vh - 6em - 40px);
        }

        .ag-header-icon.ag-header-cell-menu-button {
            padding: 0.5em 4% 0.5em 96%;
        }

        .ag-theme-alpine {
            .ag-status-name-value {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }

        .iconGroup {
            margin: 0.6em 1rem
        }

    }
}

.formgrid-view .fury-list-table .fury-list-header .fury-list-name {
    width: 21vw;
}

.setup-grid-form-view {
    width: 68%;
    margin-top: 40px;
    display: flex;

    .formgrid-view {
        width: 100%;

        .rapid-pos-btn {
            #formstiky {
                display: contents;
            }
        }

    }
}


.upload-usersetup.setup-progress {
    width: 68%;
    margin-top: 40px;
    display: flex;

    .setup-form {
        width: 100%;

        .rapid-pos-btn {
            #formstiky {
                display: contents;
            }
        }

    }
}

.setup-process .upload-usersetup .rapid-pos-btn .mat-stepper-vertical {
    min-height: auto;
    width: 100%;
    border-radius: 0px;
}

.user-management-flow {
    .content-setup-icon {
        .step-content {
            padding: 10px !important;
        }
    }
}

.gridsetup-modal-view .card {
    // box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    margin-bottom: 0px !important;
}

.gridsetup-modal-view .mat-step:first-child .mat-step-header {
    padding: 16px 27px !important;
    border-radius: 0 !important;
    // line-height: 38px;
    background: #1976d2;
    //position: absolute !important;
    // width: 83%;
    left: 0px;
    right: 0px;
    // background: none !important;
    border: none;
}

.input-headerbtnui {
    .gridsetup-modal-view {
        .mat-step:first-child {
            .mat-step-header {
                .mat-step-text-label {
                    padding-right: 0px;
                }
            }
        }
    }
}

.gridsetup-modal-view {
    .mat-step:first-child {
        .mat-step-header {
            .mat-step-text-label {
                padding-right: 35px;
            }
        }
    }
}

.gridsetup-modal-view:first-child .p-dialog-header {
    display: none;
}

.confirm_undo.gridsetup-modal-view .p-dialog .p-dialog-content {
    min-height: auto !important;
    //overflow: scroll!important;
    position: relative !important;
    padding: 0px !important;
    margin-top: 0px;
}

.gridsetup-modal-view .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    max-width: 50vw !important;
    margin-bottom: 4em;
    margin-top: 8em;
}

.gridsetup-modal-view .p-dialog-header-close {
    position: absolute !important;
    right: 10px;
    top: 13px;
    color: #fff;
    width: 2rem;
    height: 2rem;
    padding: 14px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.gridsetup-modal-view .p-dialog-header-close:hover {
    box-shadow: 0 0 5px #212939;
    border-radius: 50%;
    -webkit-animation: pop .3s 2;
    animation: pop .3s 2;
    border: 2px solid #fff;
}

.gridsetup-modal-view .p-dialog .p-dialog-header {
    padding: 2rem !important;
    display: none;
}

p-dialog.tax-classf .p-dialog .p-dialog-header {
    display: flex;
}

.gridsetup-modal-view .mat-vertical-content-container {
    margin-left: 25px !important;
}

.gridsetup-modal-view #formstiky {
    width: 100% !important;
}

.gridsetup-modal-view .p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, .4);
}

.gridsetup-modal-view .p-dialog-content {
    padding: 10px 0px !important
}

.gridsetup-modal-view .fury-page-layout-content {
    padding-right: 0 !important;
    position: relative;
    top: 0px;
    padding-left: 0px;
}

.gridsetup-modal-view .splitgroupbtn .mat-button-toggle .mat-menu-item .mat-icon {
    padding: 0 0px 4px 0px;
    color: #000 !important;
}

.file-dynamic-upload .fury-page-layout-content>div {
    display: block !important;
    margin-top: -18px;
}

.file-dynamic-upload.upload-formui .fury-page-layout-content>div {
    margin-top: -16px;
}

.file-dynamic-upload .fury-page-layout-content {
    padding: 24px 0px !important;
    margin-top: -6px;
}

.gridsetup-modal-view .p-dialog-content::-webkit-scrollbar-track {
    margin-top: 55px;
}

body.is-blink .gridsetup-modal-view .p-dialog-content::-webkit-scrollbar {
    width: 0 !important;
}

.selected-matheader .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
    background: transparent;
    color: #fff;
}

.setup-process {
    .upload-setupview {
        margin: 50px 10px 60px 79px !important;
        width: 21.8em !important;
    }
}

.setup-process {
    .upload-progess-bar {
        margin-left: -3px !important;
        margin-top: -17px !important;
    }

    .rapid-expend-grid {
        position: absolute;
        width: 100%;
        left: 0;

        .fury-list-table {
            width: 100% !important;
            float: right !important;
            background: #fff !important;
        }

        .fury-page-layout-content.expand-userentry {
            width: 100% !important;
            float: right !important;
        }
    }
}


file-dynamic-upload {
    .file-dynamic-upload {
        .card {
            margin-top: 5px;
        }
    }
}

.formgrid-view fury-page-layout-content .card.rapid-pos-btn {
    margin-top: 0em;
}

.tab-hide {
    width: 100%;
    position: absolute;
    top: 0;
    padding-left: 25px;
    left: 0;
    line-height: 55px;
}

.setup-gridview {
    .expend-view {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: -1.6em;
        margin-bottom: 0.2em;
        margin-right: 25px;
        float: right;

        .mat-icon {
            vertical-align: middle;
            color: #1976d2;
            cursor: pointer;
        }

        img {
            width: 20px;
            vertical-align: middle;
            cursor: pointer;
        }
    }
}

.setup-grid-form-view {
    .collapse-view {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: -1.6em;
        margin-bottom: 0.2em;
        float: right;

        .mat-icon {
            vertical-align: middle;
            color: #1976d2;
            cursor: pointer;
        }

        img {
            width: 20px;
            vertical-align: middle;
            cursor: pointer;
            //margin-right: 1vw;
        }
    }
}

.setup-grid-form-view {
    .expend-view {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: -1.6em;
        margin-bottom: 0.2em;
        margin-right: 20px;
        float: right;

        .mat-icon {
            vertical-align: middle;
            color: #1976d2;
            cursor: pointer;
        }

        img {
            width: 20px;
            vertical-align: middle;
            cursor: pointer;
        }
    }
}

.setup-gridview {
    .collapse-view {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: -1.6em;
        margin-bottom: 0.2em;
        float: right;

        .mat-icon {
            vertical-align: middle;
            color: #1976d2;
            cursor: pointer;
        }

        img {
            width: 20px;
            vertical-align: middle;
            cursor: pointer;
            //margin-right: 0vw;
        }
    }
}

.setup-grid-form-view {
    .formgrid-view {
        .collapse-view {
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin-top: -1.6em;
            margin-bottom: 0.2em;
            float: right;

            .mat-icon {
                vertical-align: middle;
                color: #1976d2;
                cursor: pointer;
            }

            img {
                width: 20px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }
}

.setup-grid-form-view {
    .formgrid-view {
        .expend-view {
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin-top: -1.6em;
            margin-bottom: 0.2em;
            margin-right: 22px;
            float: right;

            .mat-icon {
                vertical-align: middle;
                color: #1976d2;
                cursor: pointer;
            }

            img {
                width: 19px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }
}

.setup-process {
    .expand-customization {
        position: absolute;
        left: 20.9em;
        top: 6.5em;
        color: #1976d2;
        cursor: pointer;
        font-size: 12px;
        font-family: 'open sans';
        font-weight: bold;
        letter-spacing: -0.4px;

        .mat-icon {
            font-size: 27px;
            position: absolute;
            top: -3px;
            left: 53px;
            cursor: pointer;
        }
    }

}

.setup-process {
    .collapse-customization {
        position: absolute;
        left: 20.2em;
        top: 6.5em;
        color: #1976d2;
        cursor: pointer;
        font-size: 12px;
        font-family: 'open sans';
        font-weight: bold;
        letter-spacing: -0.4px;

        .mat-icon {
            font-size: 27px;
            position: absolute;
            top: -2px;
            left: 60px;
            cursor: pointer;
        }
    }

    .mat-button-toggle-label-content {
        line-height: normal !important;
    }
}

.expand-viewfund-spc {
    .rapid-expend-grid {
        .fury-page-layout-content.grid-spcui {
            padding-right: 24px;
        }
    }

}

.default-grid {
    .fury-page-layout-content.grid-spcui {
        padding-right: 15px;
    }
}


.content.fund-checkbox mat-label.label-font {
    display: inline-block;
    padding-right: 6px;
}

.fund-checkbox {
    .fund-other-checkbox {
        display: flex;
    }
}

.mat-accordion {
    .mat-expansion-panel-body {
        padding-bottom: 0px !important;
        padding: 0px;
    }
}

span.detail-fields p {
    width: 100% !important;
    font-size: 13px !important;
    font-family: 'open sans' !important;
}

.mat-expansion-indicator::after {
    transform: rotate(45deg) !important;

}

span.mat-expansion-indicator {
    margin-top: 7px;
}


span.detail-fields:last-child {
    padding-bottom: 0px !important;
}

mat-expansion-panel-header.mat-expansion-panel-header.header-expension.expend-expension {
    width: 34%;
    float: right;
    margin-right: 39px;
    color: #1976d2;
}

mat-expansion-panel-header.mat-expansion-panel-header.header-expension.expend-expension span.mat-expansion-indicator {
    margin-top: 0px;
}

.mat-expansion-panel-content {
    width: 100%;
}

.setup-form {
    .default-grid {
        .fury-list-table {
            width: 43.6vw;
        }
    }
}

.setup-grid-form-view {
    .grid-spcui {
        .fury-list-table {
            width: 100%;
        }
    }

}

.dropdown-bgcolors.editinput-spac .mat-form-field-wrapper {
    padding-bottom: 0em !important;
    margin-right: 0px !important;
}

.dropdown-bgcolors.editinput-spac {
    padding-left: 0px !important;
}

.setup-process {
    .mat-vertical-content {
        padding-right: 15px !important;
    }

}

.setup-process span.stickyButton.fundgroup-btn {
    position: absolute;
    top: 8px;
    width: 109px;
    right: 1em !important;
}

.rapidgridDisplay {
    display: none !important;
}

.user-mang-expendgrid.rapid-expend-grid {
    width: 98% !important;
    left: 24px !important;
}

.customiz-expendgrid.rapid-expend-grid {
    width: 99.5%;
    left: 0px;
}

.user-mang-expendgrid {
    .fury-card {
        border-radius: 0px !important;
    }
}

.p-dialog .mat-header-fix .mat-step-header {
    position: fixed !important;
    //width: 93%;
    z-index: 999999;
}

.p-dialog .mat-header-fix .mat-vertical-content-container {
    margin-top: 4em !important;
}

.setup-process .splitgroupbtn .mat-button-toggle {
    margin-top: -5px !important;
}

.edit-window-btn {
    .mat-button-toggle .mat-menu-item .mat-icon {
        padding-left: 6px !important;
        padding-right: 10px !important;
        margin-right: 3px !important;
    }

    span.ui-text {
        padding: 0.5em 1em 0.5em 1.2em !important;
        margin-left: -2.2em;
    }

}

.sv-btn {
    span.ui-text {
        padding: 0.5em 1em 0.5em 1.2em !important;
        margin-left: 0px !important;
    }
}

.content.adv-content.mt-0 .mat-form-field-appearance-outline .mat-form-field-outline {
    left: 0px !important;
}

.user-mang-expendgrid.default-grid .mat-vertical-content {
    margin-top: 24px !important;
}

.mglr0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.non-custodian-main {
    .non-custodian-form {
        .mat-step-header {
            .mat-step-text-label {
                color: #fff;
                font-size: 18px;
                font-weight: 500;

                .stickyButton {
                    top: 1vh;
                    right: 1vw !important;
                }
            }


            .noncustodin-sourceinfo-header {
                display: initial;
            }
        }

        .mat-step-header:nth-child(1) {
            border-radius: 0px;
            background: #1976d2;
        }
    }
}

.non-custodian-main {
    .non-custodian-form {
        .splitgroupbtn {
            .mat-button-toggle {
                background-color: #fff;
                //width: 70px;
                margin-top: -6px !important;

                span {
                    .mat-menu-item {
                        line-height: normal;
                        height: 43px;
                        line-height: 43px;
                        //width: 70px;
                        text-align: center;
                        display: flow-root;
                    }

                    .ui-text {
                        padding: 0.8em 1.5em;
                    }
                }
            }
        }
    }

    .mat-vertical-stepper-content {
        width: 100%;
        overflow: scroll;
        //display: grid;
    }

    .mat-vertical-stepper-header {
        border-bottom: none;
    }

    .p-table-sourcedetails .p-datatable-scrollable-body {
        overflow-x: auto !important;
    }
}

span.stickyButton.source-detail-editbtn .splitgroupbtn .mat-button-toggle span .mat-menu-item {
    height: 43px;
    line-height: 43px;
    width: 70px;
    text-align: center;
    display: flow-root;
}

.setup-process span.stickyButton.source-detail-editbtn .splitgroupbtn {
    right: 9px;
    top: 0px;
}

.setup-process .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable span.stickyButton.source-detail-editbtn .splitgroupbtn {
    right: 45px;
    top: 0px;
}

.gridsetup-modal-view .source-detail-editbtn .p-dialog-header-close {
    right: 0px;
    top: 6px;
}

.edit-window-btn .non-custodian-form span.ui-text {
    margin: 0 !important;
    padding: 0.9em !important;
}

.edit-window-btn .non-custodian-form .mat-button-toggle .mat-menu-item .mat-icon {
    padding-left: 5px !important;
    padding-right: 0px !important;
    margin-right: -9px !important;
    margin-left: 4px;
}

.setup-process th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
    white-space: normal !important;
}

fury-page-layout-content.fury-page-layout-content.sroll-grid .fury-card {
    margin-bottom: 1.5em;
}

p-dialog.Dialog_width_Medium.confirm_undo.gridsetup-modal-view.edit-window-btn.header-close {
    .p-dialog {
        transform: inherit;
    }
}

.gridsetup-modal-view {
    .modal-heading-sec {
        .p-dialog {
            .p-dialog-header {
                display: block !important;
                padding: 1.5em !important;
            }
        }
    }
}

.card.rapid-pos-btn.expand-icon-z {
    position: relative;
}

.notes_comment_header {
    .fury-page-layout-header {
        display: none;
    }
}

.route-animations-elements.cheker-header-fix {
    border-radius: 0px !important;

    .p-dialog-header-close {
        color: #fff;
        z-index: 10000000;

        &:hover {
            box-shadow: 0 0 5px #1976d2;
            border-radius: 50%;
            animation: pop .3s 2;
            border: 2px solid #fff;
            cursor: pointer;
        }
    }

    .mat-tab-list {
        background: #1976d2;
        position: fixed;
        width: 50vw;
        padding: 0px 27px;
    }

    .mat-tab-label {
        opacity: initial;

        .mat-tab-label-content {
            color: #fff;
        }
    }

    .mat-tab-group {
        .mat-tab-body.mat-tab-body-active {
            z-index: -5;
        }

        .mat-tab-body-wrapper {
            margin-top: 40px;
        }
    }

    .checker-closebtn {
        position: fixed !important;
        right: 19.5em;
        top: 6em;
    }

    .mat-tab-label {
        .mat-tab-label-content {
            font-size: 18px !important;
            font-weight: 500 !important;
        }
    }

}

.content.adv-content.mt-0.abc {
    margin-top: 0px !important;
}

.header-customization {
    .p-dialog-header {
        padding: 16px 27px !important;
    }
}

.reportNotification .p-dialog-header-close {
    display: none !important;
}

.reportNotification button.p-dialog-header-icon.p-link.p-ripple {
    position: absolute;
    top: 13px;
    right: 20px;
    color: hsl(0, 0%, 100%);
    width: 2rem;
    height: 2rem;
}

.reportNotification button.p-dialog-header-icon.p-link.p-ripple:hover {
    box-shadow: 0 0 5px #212939;
    border-radius: 50%;
    animation: pop .3s 2;
    border: 2px solid #fff;
}

.edit-mode-dailog {
    .p-dialog {
        .p-dialog-header {
            padding: 16px 27px !important;
            display: block !important;
        }
    }

    .p-dialog-content {
        .delCriteria {
            padding: 1em !important;
        }

        h3 {
            padding: 1em !important;
            margin: 0px !important;
        }
    }
}

.setupaccount_configmodal {
    .edit-mode-dailog {
        .p-dialog {
            .p-dialog-header {
                padding: 30px 27px !important;
                display: block !important;
            }
        }
    }
}

.accountdialog.edit-mode-dailog .p-dialog {
    position: initial;

    .modal-body {
        padding-top: 10px !important;
    }
}

.investorgroup-modal-ctr {
    .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
        position: initial;
    }
}

.edit-window-btn span.ui-text.advinfo-btn {
    margin-left: 0em;
    padding: 0em !important;
}

.userentrybtn-center {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

p-dialog.Dialog_width_Small.confirm_undo.generate-popup.edit-mode-dailog .p-dialog {
    position: inherit;
}

.gridsetup-modal-view {
    .modal-center-edit {
        .p-dialog {
            position: initial;
        }
    }
}

// Start Custodian Input red text  alignment
.inputunderlabel1 {
    left: 54.4em !important;
}

.newinputunderlabel1 {
    position: absolute;
    top: 5.8em;
    font-size: 11px;
    left: 34.4em !important; //custodian connection Pending label fix
    color: red;
}

.edit-window-btn .inputunderlabel1 {
    left: 47.4em !important;
}

.edit-window-btn .newinputunderlabel1 {
    position: absolute;
    top: 5.8em;
    font-size: 11px;
    left: 47.4em !important; //custodian connection Pending label fix
    color: red;
}

// END Custodian Input red text  alignment

.setup-process {
    .rapid-pos-btn {
        .notesandcomments {
            .note-comment-input {
                .mat-form-field-wrapper {
                    width: 95% !important;
                    display: inline-block;
                    padding-bottom: 0px;
                }
            }

            .text-area {
                width: 95.4%;
                margin-left: 21px;
            }

            .form-button {
                left: 2em !important;
            }

            .mat-form-field-wrapper {
                width: 95.9% !important;
            }

            .addoption_icon {
                position: inherit;

                .addicon_right {
                    right: 40px;
                }
            }

            .notesremove-icon {
                .Notes_icon {
                    .notescomments_icon {
                        right: 40px;
                    }
                }
            }
        }

        .notes-main-card {
            display: block !important;
        }
    }
}

.left0-spc .mat-form-field-appearance-outline .mat-form-field-outline {
    left: 0 !important;
}

.left0-spc {
    .mat-form-field-wrapper {
        padding-bottom: 1.2em;
    }
}

.grid-spcui {
    .setGridHeight {
        height: calc(85vh - 5em - 45px) !important;
        width: 100%;
    }
}

.fs-grid {
    .setGridHeight {
        height: calc(72vh - 5em - 45px) !important;
        width: 100%;
    }
}

.confim-nofreemodal {
    .p-dialog-content {
        min-height: auto;
    }

    .p-dialog-header {
        padding: 16px 27px !important;
    }
}

.chofiles.upload-btndocument {
    display: inline-block;
}

.chofiles.multipalupload-btndocument {
    display: inline-block;
    padding-left: 0.37em;
}

.tab-active-clr a.mat-tab-link.mat-tab-label-active {
    color: #1976d2;
    opacity: 1;
}

.documentTable.documentheader {
    .mat-form-field-label {
        padding: 5px 0px !important;
        top: 1.45em !important;
    }
}

.view-data-collection .card {
    width: 100% !important;
}

.view-data-collection .fury-list-table {
    width: 100% !important;
}

.alertThreshold.mrg-right0 {
    .mat-form-field-wrapper {
        margin-right: 0px !important;
    }
}

span.connection-source-detailbtn {
    .mat-button-toggle {
        margin-left: 10px;
    }

    .splitgroupbtn {
        background: none !important;
        margin-top: 0px;

        .mat-button-toggle {
            span {
                .mat-menu-item {
                    height: 2.2rem;
                    line-height: 2.2rem;
                }
            }
        }
    }
}

.default-grid fury-list {
    display: flex !important;
}

.expansion-heading.selected {
    h3 {
        color: rgb(25 118 210) !important;
    }
}

.detail-fields.selected {
    p {
        color: rgb(25 118 210) !important;
    }
}


fury-fs-config-entry #fs-advanceDetail,
#fs-schedule-stepper,
#fs-investor-stepper {
    .mat-step {
        width: 100%;
    }

    .mat-vertical-stepper:first-child .mat-step-header {
        background: #e0e0e0 !important;
    }
}

fury-fs-config-entry mat-vertical-stepper:nth-child(2) mat-step-header {
    background: #e0e0e0 !important;
}

fury-fs-config-entry fury-fs-schedule-entry mat-vertical-stepper:first-child mat-step-header {
    background: #e0e0e0 !important;
}


fury-fs-cash-flow-update fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header,
fury-fs-exposure fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header,
fury-fs-cash-equivalents fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header,
fury-fs-investor fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header,
fury-fs-chart-of-account fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header,
fury-fs-portfolio fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header {
    display: none !important;
}

.buttononheader.fundpropertyclass button span.mat-button-wrapper {
    font-weight: bold !important;
}
#fs-chartOfAccount, #fs-Portfolio {
    .investor-process p {
        text-align: left !important;
    }
}

fury-fs-config-entry fury-template-configuration #firstTemplateFormateVS mat-step-header {
    background: rgba(25, 118, 210, 0.05) !important;

    .mat-step-text-label {
        color: #000 !important;
        font-size: 16px !important;
        font-weight: 600 !important;
    }
}
.rapid-expend-grid {
    fury-compound-journal-entry{
        cdk-virtual-scroll-viewport{
                min-height: 185px !important;
        }
    }
    .compound-card .as_row:has(.col-lg-4){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(1){ width:16.4%!important;}
    .compound-card .as_row:nth-child(2){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(3){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(4){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(5){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(6){ width: 17.8%!important;}
    .compound-card .as_row:nth-child(7){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(8){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(9){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(10){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(11){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(12){ width: 17.7%!important;}
    .compound-card .as_row:nth-child(13){ width: 16.4%!important;}
    .compound-card .as_row:nth-child(14){ width: 16.4%!important;}
    .compound-card .as_row:has(.compound-form4){ width: 100%!important;     max-width: 290px;}
   
    .compound-form {
      width: 100% !important;
    }
    #customerDr {
        width: 100% !important;
      }
  
    .compound-form1 {
      width:100% !important;
    }
  
    .compound-form2 {
      width: 100% !important;
    }
  
    .compound-form3 {
      width: 100% !important;
    }
  
    .compound-form4 {
      width:100%!important;
    }
  
    .compound-form5 {
      width: 100% !important;
    }
  
    .compound-form6 {
      width: 100% !important;
    }
    #descriptionNotes{
        width: 100%!important;
    }
  
    .compound-form7 {
      width: 100% !important;
      float: right !important;
      margin-top: -9px;
    }
  
    .compound-form8 {
      width: 100%!important;
    }
    .add-circles{
      display: flex;
      align-items: center;   
      margin-top: -20px;
      margin-bottom: 10px;
    }
    .addIcon_removecircle{
    // position: relative;  
    // // top: 41px;
    // right: -4px;
    float: left;
    }  
    .compound-card .debit-card {
      margin-top: -0.8rem;
  }
   .compound-card .debit-card.extended {
    margin-top: -2.8rem;
    clear: both;
    float: left;
    width: 100%;
    
    // mat-form-field{
        // margin-left: 0px !important;
    // }
  }
  fury-compound-journal-entry cdk-virtual-scroll-viewport .compound-card .addIcon_removecircle{
    clear: both;
    float: left;
  }
//   .compound-JE{
//     padding-left: 24px;
//   }
  }
  
  .default-grid{
    .as_row:has(#customerDr), .as_row:has(#customerCr) {
        clear: both;
    }
    .add-circles{
      display: flex;
      align-items: center;
      margin-top: -19px;
    }
    .addIcon_removecircle{
      position: relative;  
      top: 4px;
      right: -4px;
      float: left;
    }
    .debit-card{
      margin-top: -0.8rem;
  }
  
//     .debit-card.extended{
//       margin-top: -2.9rem;
//   }
  
    }
  
    .card.rapid-pos-btn.gridsetup-modal-view.edit-window-btn.compound-je{width: 100%;}
    .compound-je .compound-card .col-lg-4{min-height: 4rem;}

    .extended mat-form-field {
        margin-left: 30px;
        display: flex;
    flex-direction: column;
    width: 15%;
    }
    fury-compound-journal-entry{
        //  .compound-card {
        //     max-height: 16rem;
        //     overflow-y: auto;
        // }
        cdk-virtual-scroll-viewport{
                // min-height: 185px !important;
            .compound-card {
                margin-top: 0.75rem;
                .addIcon_removecircle{
                    top:15px;
                }
            }
        }
    }  
    .ag-cell-wrapper.ag-row-group {
        align-items: center;
    }
    #fs-Portfolio .investor-process p {
        text-align: left !important;
    }
   
    fury-fs-config-entry fury-template-configuration #firstTemplateFormateVS mat-step-header {
        background: rgba(25, 118, 210, 0.05) !important;
    
        .mat-step-text-label {
            color: #000 !important;
            font-size: 16px !important;
            font-weight: 600 !important;
        }
    }
    
    .fs-primaryHeader {
        .popup-label {
            color: #1976d2 !important;
            font-weight: 500;
            background-color: #f4f4f4;
            padding: 5px;
        }
    
        .n-label {
            margin-top: 10px;
            padding-right: 10px !important;
            padding-left: 5px !important;
        }
    
        .p-inputnumber input {
            width: 4rem !important;
            height: 3rem !important;
        }
    
        label.p-float-label {
            padding: 0px !important;
            margin: -13px 18px !important;
        }
    
        #rowHeight:has(p-inputnumber.ng-untouched) label.p-float-label {
            margin: -13px 18px !important;
            top: 0rem !important;
            font-size: 12px !important;
            background: #fff;
            padding: 2px 9px;
            color: #1976d2 !important;
        }
    
        .formcheck-bx {
            padding-left: 7px !important;
            padding-top: 12px !important;
        }
    }
    
    #templateconfigmodal {
    
        #headerKey,
        #lineItemKey,
        #FooterKey {
            flex: 80%;
            width: 80%;
            float: left;
        }
    
        #lineItemKey {
            margin-top: -7px;
        }
    
        .add-icon {
            top: 2.7rem;
            white-space: nowrap;
        }
    }
    
    #specificGenricLineItem mat-step-header {
        width: 95% !important;
    }
    
    .fs-primaryHeader #textTransformation mat-radio-button {
        margin-left: 10px;
        width: 20%;
    }
    
    #templateconfigmodal {
        .mat-step {
            width: 100%;
        }
    
        .mat-step-header {
            background: #1976d20d !important;
            position: relative !important;
            width: 94%;
        }
    
        .mat-step-text-label {
            color: #000 !important;
            font-size: 16px !important;
            font-weight: 600 !important;
    
        }
    }
// Start Fund Account confirmation dialog 
.gridsetup-modal-view{
    .dialog-faccount{
        .p-dialog{
            .bdetailsp{
                font-family: var(--font-family);        
            }
          .p-dialog-header{
            display: flex !important;
            padding:1.5rem 2rem !important;
          }
          .p-dialog-content{
            padding: 1.5em !important;
            min-height: 10em;
          }
        }
      }
    }
    .confirm_undo.gridsetup-modal-view{
        .second-p-dialog{
        .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable{
            min-width: 70vw !important;
            max-width: 60vw !important;
            margin-top: 0em;
            left: auto !important;
        }
        .p-dialog-content {
            height: 74vh;
            min-height: 53vh !important;
        }
    }
    }
    
// End Fund Account confirmation dialog 

body:has(.collapse-view) fury-compound-journal-entry .addSelectOptions{
cursor: pointer;
margin-left: 5.2em;
margin-top: -2.2rem;
display: block;
position: absolute;
z-index: 99;
}
#fs-stepper .fs-advanceDetail {
    .mat-accordion {
        width: 100% !important;
    }

    .mat-accordion .mat-expansion-panel:last-of-type {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .mat-expansion-panel-header,
    .mat-expansion-panel-header.mat-expanded {
        padding: 15px 38px 15px 20px;
        place-items: center;
    }

    .mat-expansion-indicator::after {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 10px solid #1d2125;
        transform: rotate(0deg) !important;
        padding: 0px
    }

    .fs-accordian-header {
        background: #e0e0e0 !important;
    }

    .mat-expansion-panel-header {
        font-size: 16px;
        font-weight: 600;
        color: #424242;
    }

    .mat-accordion .mat-expansion-panel-body {
        padding: 15px 15px 15px 25px !important;
    }
}

.fs-schedule-step .addIcon_removecircle {
    float: none;
}
.setup-grid-form-view:has(fury-automation-data) .formgrid-view .expend-view img{
    position: relative;
    right: 21px !important;
    }
    .setup-grid-form-view:has(fury-endpoint-scheduler) .formgrid-view .expend-view img{
        position: relative;
        right: -9px !important;
        }
        .aligment.col-lg-6.ml-2 {
            padding-left: 4px;
            padding-right: 10px;
            padding-top: 0px;
        }


        // vendorReport CSS Start

        .rapid-expend-grid{
            .vendorReport .iframe-title .title-change {
                top: 13px;
                left: 63px;
                max-width: 377px !important;
                
            }
            .vendorReport .iframe-title .download {
                top: 14px;
                right: 7% !important;
            }
        }
        .default-grid{
            .vendorReport .iframe-title .title-change {
                top: 13px;
                left: 63px;
                max-width: 205px !important;
                
            }
            .vendorReport .iframe-title .download {
                top: 14px;
                right: 10% !important;
            }
            }

             // vendorReport CSS End
             .scheduleIframe{
                margin-right: 44px !important;
                .downloadBtn{
                    color: #fff !important;
                }
                            }
                .detail-fields.selected p span{
                    color: rgba(29, 33, 37, 0.5) !important;
                }
                .iconSet .mat-button-wrapper svg{
                    position: relative;
                    top: 7px;
                }
                .toggleStyles {
                    position: absolute;
                    top: 77%;
                    left: 30px ;
                    width: 100%;
                    .GpMPr-align{
                        margin-right: 15px !important;
                    }
                    .toggle-refresh-btn{
                        position: absolute;
                        right: 59px;
                        bottom: -15px;
                    }
                  }