/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
fury-list {
  @include mat-elevation($elevation);
  border-radius: $border-radius;
  overflow: inherit;
  display: flex;

  .fury-list-table {
    flex: 1 1 auto;
  }

  .fury-list-header {
    background: $theme-card;
    border-bottom: 1px solid $theme-divider;

    .fury-filter-input {
      background: $theme-card;
      color: currentColor;

      ::placeholder {
        color: currentColor;
      }
    }

    .fury-filter-input-icon, .fury-column-filter {
      color: $theme-icon;
    }

    .fury-list-name {
      border-right: 1px solid $theme-divider;
    }
  }

  .mat-header-row, .paginator {
    background: $theme-app-bar;
  }

  .mat-row:hover {
    background: $theme-background-hover;
  }

  .mat-cell.actions-cell, .mat-header-cell.actions-cell {
    button {
      color: $theme-icon;
    }
  }

  .mat-cell.image-cell, .mat-header-cell.image-cell {
    img {
      background: $theme-app-bar;
    }
  }
}
