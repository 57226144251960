// start tax_automation
.taxation_list {
  .fury-page-layout-header {
    display: none;
  }
}

.taxdetailheading .label {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  font-style: normal;
  color: #000 !important;

}

.taxdetailheading {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  font-style: normal;
  margin: 0px 0px 8px 13px !important;

  .span {
    color: #000 !important;
  }

}




.finaltext_info {
  background: #f8f8f8;
  font-size: 14px;
  color: #000;
  padding: 4px 18px 4px 6px !important;
  margin: 10px 5px;
  width: 98% !important;
}

.warningNote {
  /* top: auto; */
  margin: -2em 1em 0.75em 0;
  padding-top: 1.34375em;
  /* position: relative; */
  font-size: 10px;
  /* color: red; */
}

//End tax_automation
#gpIsPrAlso {
  background: #f8f8f8;
  font-size: 12px;
  color: #000;
  padding: 4px 18px 4px 18px !important;

  mat-checkbox {
    padding: 0 !important;
  }
}

.tax-period_radio {
  .mat-form-field {
    display: flex;
    align-items: center;

    mat-label {
      margin-top: 8px;
      min-width: 140px;
    }
  }

  mat-radio-group {
    margin-top: 0 !important;

    mat-radio-button {
      span.mat-radio-outer-circle {
        border-color: #1976d2 !important;
      }

      span.mat-radio-inner-circle {
        background-color: #1976d2 !important;
      }
    }
  }
}

.span.mat-radio-outer-circle {
  border-color: #1976d2 !important;
}

.k1-radiobtn {
  mat-radio-button {
    span.mat-radio-outer-circle {
      border-color: #1976d2 !important;
    }

    // .mat-radio-checked .mat-radio-outer-circle {
    //   border-color:#1976d2 !important
    // }
    // .mat-radio-button.mat-accent .mat-radio-inner-circle{
    //   background-color: #1976d2 !important;
    // }
    span.mat-radio-inner-circle {
      background-color: #1976d2 !important;
    }
  }
}

.k1-radiobtn {
  .mat-form-field {
    margin-left: 7px;
  }
}

.k1recipient {
  .mat-form-field {
    mat-label {
      font-weight: 500 !important;
    }
  }
}

.ml-tax {
  mat-label {
    margin-left: 10px !important;
  }
}

#amendedReturnAndScheduleK1 {
  .mat-form-field {
    display: flex;
    align-items: center;
    justify-content: space-between;

    mat-label {
      margin-top: 8px;
    }
  }
}

#finalReturn,
#addressChange {
  .mat-form-field {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    mat-label {
      margin-top: 8px;
    }
  }
}

.k1recipient {
  mat-radio-group {
    margin-left: 10px;
    display: flex;
    font-weight: 500 !important;
  }
}

#guaranteedPaymentlabel span,
#accountWarning,
#partnerRepresentativelabel span,
#designatedIndividuallabel span {
  color: #000 !important;
}

.content {
  .info-msg {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    margin: -8px 15px 5px 2px !important;
    border-radius: 4px !important;

    .msg {
      margin-left: 5px !important;
      font-size: 11px !important;
      font-weight: 100 !important;
      color: grey !important;
    }
  }
}

.info-msgpartnership {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin: -4px 15px 15px 4px !important;
  border-radius: 4px !important;

  .msgpartnershipname {
    margin-left: 5px !important;
    font-size: 11px !important;
    font-weight: 100 !important;
    color: grey !important;
  }
}

.taxWarning {
  margin: 2px 0px 5px 2px !important;
  padding-right: 0.8em;

  .material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
}

.label {
  background: #f8f8f8;
  font-size: 14px;
  color: #000;
  padding: 4px 18px;
  margin: 10px 5px;
  width: 98% !important;
}

// h4 {
//   background: #f8f8f8;
//   font-size: 14px;
//   color: #000;
//   padding: 4px 18px;
//   margin: 10px 5px;
//   width: 98% !important;
// }

#guaranteedPaymentlabel {
  background: #f8f8f8;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  font-style: normal;
  margin: 0px 0px 8px 5px !important;
  width: 98% !important;
  padding: 4px 9px !important;

  span {
    color: #000;
  }

}

.section.example-section {
  margin: 0px 11px 0px 5px !important;
}

section.toggleStyle {
  margin: -30px 10px 0px 5px !important;
}

.mat-step-header-overflow {
  overflow: initial;

  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: #1976d287;
    }

    .mat-slide-toggle-thumb {
      background-color: #1976d2;
    }
  }
}

// .radioBtn_input {
//   .mat-form-field {
//     border: 1px solid #eae6e1;
//     border-radius: 3px;
//   }
// }


.taxheading {
  color: #1976d2 !important;
  font-weight: 600;
  padding: 3px 10px 3px 5px;
  margin-bottom: 8px;
}

.tax-period_radio_feedetails {
  .mat-form-field {
    display: table-caption;
    align-items: center;

    mat-label {
      margin-top: 8px;
      min-width: 140px;
    }
  }

  mat-radio-group {
    margin-top: 0 !important;

    mat-radio-button {
      span.mat-radio-outer-circle {
        border-color: #1976d2 !important;
      }

      span.mat-radio-inner-circle {
        background-color: #1976d2 !important;
      }
    }
  }

  br {
    display: none;

  }

  mat-label {
    display: none;
  }
}

.infotax {
  p {
    //display: flex;
    align-items: left !important;
    font-weight: 400 !important;
  }

  span:first-child {
    color: black !important;
    font-size: 16px;
    padding-right: 5px;
  }
}


fury-calculate-tax-allocation p-dialog {
  .p-datatable-wrapper {
    max-height: 65vh;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
  }
}

fury-chart-of-account-tax-classification {
  .taxHistoricalData {
    display: flex;
    margin-top: 25px;
    margin-right: -6rem !important;

    .mat-form-field-infix {
      padding: 0.8em 0 0.8em 0 !important;
    }
    .actions {
     margin-left: -3rem !important;
    }
    .mat-form-field{
      width: 27% !important;
    }
  }
}

// fury-chart-of-account-tax-classification .countClass {
//   order: 2;
// }