/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
// XX-Large devices (large desktops, less than 1680px)
@media (max-width: 1680px) {
  .h2twoline{
    display: flex;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1440px) {
  fury-toolbar-search-bar{
    min-width: 16em !important;
  }
  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable{
    max-width: 70vw;
  }
  .h2twoline{
    display: flex;
  }
  .formidium-img .formidium-default-img {
    padding-left: 20px;
}
  // .p-dialog .p-dialog-footer {
  //   padding-top: 0.5em !important;
  //   padding-bottom: 0.5em !important;
  // }
  #fury-dashboard{
    padding: 24px 0px !important;
    .p-carousel-next{
      width: 1.2rem !important;
      height: 1.2rem !important;
    }
    .p-carousel-prev{
      width: 1.2rem !important;
      height: 1.2rem !important;
    }
    .p-carousel-prev-icon{
      font-size: 0.8em !important;
    }
    .metric-value{
      font-size: 18px !important;    
    }
    .metric-label{
      font-size: 18px;
    }
    .netRor{
      img{
        width: 50px !important;
      }
    }
    .grossRor{
      img{
        width: 50px !important;
      } 
    }
  }
  .calculateBtn-tax{
    display: flex;
  }
  tax-fury-setup{
    .step-content{
      width: 236px !important;
    }
    
    .setup-process .step-flow .step-work {
      width: 240px !important;
  }
    .mat-icon{
      overflow: visible !important;
    }
  }
}
@media (max-width: 1399.98px) { 
  .executework-flowrun.fulwithmodal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    min-width: 80%;}
 }
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .workflowtree_design .fury-list-table .fury-list-header .fury-list-name {
  
    font-size: 16px!important;
    
  }
  .executework-flowrun.fulwithmodal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    min-width: 75%;}

  .fury-default fury-list .fury-list-header .fury-list-name {
    white-space: nowrap;
    margin-right: 0px !important;
    padding-right: 5px;
    font-size: 15px;
  }
  .fury-default fury-list .fury-list-header .fury-list-name + div {
    margin-right: 10px !important;
  }
  .switch-field {
    label {
      white-space: nowrap;
      padding: 8px !important;
    }
  }
  .actions.oms-list-btns .countClass {
    white-space: nowrap;
  }

  .cardrow {
    height: auto !important;
  }
  .btrack{
    .btrackform{
      mat-form-field {
        margin-right: 1em;
        margin-left: 0em !important;
      }
    }
  }
  mat-checkbox.runtaxlot-checkbox {
    margin-bottom: 20px;
}
.newbtn button {
  margin-bottom: 10px;
}
// .p-dialog .p-dialog-footer {
//   padding-top: 0.5em !important;
//   padding-bottom: 0.5em !important;
// }
}
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .executework-flowrun.fulwithmodal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    left: 0rem!important;
    .modal-left-part{ width: 100%;}
    .modal_right{ width: 100%;}
  }
  // .fury-default .setting_faq{position: relative;}
  .card-btnbox .fury-card-content {
    overflow: scroll;
  }
  .documentTable .chofiles input {
    height: auto;
  }
  .cardrow .tradcard .bodytext ul li .iconset {
    display: flex;
  }
  .fury-default .fury-card {
    padding-right: 0px;
  }
  .mat-tab-header-pagination-controls-enabled {
    width: 100%;
  }
  fury-toolbar-notifications {
    border-left: none !important;
  }

  .toolbar {
    padding-left: 0px !important;
  }
  .theme_btn {
    padding: 0 10px !important;
  }
  .translatedrop {
    padding: 0.6em !important;
  }
  .fury-list-table .fury-list-header {
    height: 64px !important;
  }
  .actions.oms-list-btns .countClass {
    white-space: nowrap;
  }
  .switch-field {
    white-space: nowrap;
    margin: 0.5em 0em;
  }
  .fury-list-table .fury-list-header .fury-list-name {
    margin-right: 0px !important;
    padding-right: 10px !important;
  }
  .toolbar-create,
  .toolbar-upload,
  .toolbar-view,
  .duplicateico {
    display: none !important;
  }
  .fury-list-table .fury-list-header .fury-list-name {
    white-space: nowrap;
  }
  .fury-list-table .fury-list-header {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .p-datatable .p-datatable-scrollable-header,
  .p-datatable .p-datatable-scrollable-footer {
    background: #f5f5f5 !important;
  }
  .p-datatable .p-datatable-thead > tr > th {
    padding: 1rem 0.5rem !important;
  }
  .mat-column-startdate,
  .mat-column-enddate,
  .mat-column-viewaction {
    white-space: nowrap !important;
  }
  .p-datatable-wrapper {
    overflow-x: scroll;
    white-space: nowrap;
  }
  mat-vertical-stepper .mat-raised-button {
    line-height: 31px !important;
  }
  .p-fluid .p-inputtext {
    width: auto !important;
  }

  .toolbar-user .dropdown .list .list-item {
    font-family: var(--font-family) !important;
  }

  .sidenavbttxt {
    font-size: 14px;
    padding-left: 1.1em !important;
  }
  .toolbarsetting {
    padding-left: 2em !important;
  }
  .mat-calendar-table-header th {
    font-size: revert;
    font-weight: bold;
} 
// .mat-button-wrapper{font-size: medium !important;}
.mat-calendar-header {padding: 8px !important; }
.mat-calendar-controls{margin: 0px !important;}
.mat-calendar-body-cell{ width:40px !important; height: 40px !important;}
// .fury-default .setting_faq{position: relative;}
.mat-calendar-body-cell-content.mat-focus-indicator {
    position: absolute !important;
}
// Processing workflow Tree page responsive
.workflowtree_design .fury-list-table .fury-list-header .fury-list-name {
  right: 24px !important;
  font-size: 12px!important;
  border: none!important;
}
.example-button-row.select_executebtn {
  top: 5px;
  position: relative;
  display: flex;
}
.workflowtree_design .mat-form-field {
  top: 10px !important;
}
.treetable_text .p-treetable table {
  border-collapse: collapse;
  table-layout: auto !important;
  width: 100%;
  overflow-x: scroll !important;
}
.treetable_text .p-treetable-wrapper {
  overflow: scroll !important;
}
.treetable_text .p-treetable .p-treetable-tbody > tr > td:nth-child(1) {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  white-space: nowrap !important;
}
.fury-dark .btn-logout.mat-button.workflow_button {
  top: 0 !important;
}
// System manager fury dark Download New Sm header pagination width and dwbtn alignment issue
 .fury-dark .dwnewsm_card .mat-tab-header-pagination-controls-enabled {
  width: 90% !important;
}
.fury-dark .example-button-row.tab-menu-calculate.downloadbtn.btn_newsm {
  margin-top: -2px !important;
}
.fury-default .dwnewsm_card .mat-tab-header-pagination-controls-enabled {
  width: 88% !important;
}
.fury-default .example-button-row.tab-menu-calculate.downloadbtn.btn_newsm {
  margin-top: -2px !important;
}
.instructionmodal {
  flex-direction: column;
}
.instructionmodal .mat-stepper-vertical{
  width: 100%;
}
.breadcrumbinfo .p-dialog .p-dialog-content{
  overflow-x: scroll;
}
.col-lg-3.downloadfund{
  width: 100% !important;
}
.btrackform.form-input-set .align-forms {
  display: grid !important;
}
.btrackform.form-input-set .align-forms mat-form-field{
  margin-right: 0em;
  width: 100% !important;
}
.modal-scd-head{
  display: inherit;
}
.btrack .btrackform{
  display: inherit;
}
.btracknav {
  display: initial;
}
.btrack .btrackform mat-form-field{width: 100%;}
.p-dialog .p-dialog-content{padding: 0 1rem 2rem 1rem !important;}
.modal-scd-head .btrackform{padding: 0px !important;}
.btrack{
  .btrackform{
    mat-form-field {
      margin-right: 1em;
      margin-left: 0em !important;
    }
  }
}
mat-checkbox.runtaxlot-checkbox {
  margin-bottom: 20px;
}
.newbtn button {
  margin-bottom: 10px;
}
.ror-input.ror_config {
  margin-top: 15px;
}
.cardrow.querycard .tradcard.accoutcard {
  margin-left: 0em !important;
}
.toolbar_inputfield .mat-form-field{
  width: 70% !important;
}
// .p-dialog .p-dialog-footer {
//   padding-top: 0.5em !important;
//   padding-bottom: 0.5em !important;
// }
.batchpad{padding-left: 0em;}
.bouncebox{right: 5em;top: 7.5em;text-align: right;}
.report-batchissue .p-dialog .p-dialog-footer{padding-top: 0em !important;}
}
// Small devices (landscape phones, less than 768px)
@media (min-width: 821px) and (max-width: 920px) {
  .cardrow.querycard .tradcard.accoutcard{margin-bottom: 5em !important;}
  .bouncebox{right: 5em;top: 7.5em;text-align: right;}
  .report-batchissue .p-dialog .p-dialog-footer{padding-top: 0em !important;}
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 768px) and (max-width: 820px) {
  .cardrow.querycard .tradcard.accoutcard{margin-bottom: 5em !important;}
  .bouncebox{right: 5em;top: 7.5em;text-align: right;}
  .report-batchissue .p-dialog .p-dialog-footer{padding-top: 0em !important;}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  
  .p-treetable .p-treetable-thead>tr>th{ font-size: 12px;}
  p-table {
    height: auto !important; overflow-x:scroll ;
}
  .input-append.date.form_datetime.custom_calende_css.sequence-spc{ display: flex; align-items: center; white-space: nowrap;}
  fury-card.fury-card.basic-forms.route-animations-elements .mat-linkexprot{
    position: static;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;}
  .grid-formui .col-lg-3.workflowheadinput {
    width: 235px;
}
.gen-password{
  margin-left: 2em !important;
}
.ellipsis{width: 200px !important;}
  .mat-button-toggle-standalone,
  .mat-button-toggle-group {
    margin-right: 0%;
  }
  // .p-dialog .p-dialog-footer {
  //   padding-top: 0.5em !important;
  //   padding-bottom: 0.5em !important;
  // }
  .mat-step-text-label {
    display: block;
  }

  mat-step-header[tabindex="0"] {
    .mat-step-icon-selected {
      margin-top: -28px;
    }
  }
  file-dynamic-upload mat-step-header[tabindex="0"] .mat-step-icon-selected {
    margin-top: -5px;
  }

  .pagenot-found {
    width: 100%;
    .leftimg {
      width: 98% !important;
      margin-top: 2rem;
      svg {
        width: 50%;
      }
    }
    .rightlogin {
      width: 100% !important;
      text-align: center;
      padding: 20px;
      left: 0 !important;
      button {
        margin-left: 0 !important;
      }
    }
  }
  .fury-default .pagenot-found {
    display: block;
  }
  .cardrow .tradcard {
    max-width: 100%;
    // margin: 1.5rem 0 !important;
  }
  .tradcard.adminent {
    margin-left: 0 !important;
  }

  mat-vertical-stepper {
    .splitgroupbtn .splitbtn {
      width: 28px;
    }
    .splitgroupbtn {
      height: 30px;
    }
 
    .mat-button,
    .mat-raised-button,
    .mat-icon-button,
    .mat-stroked-button,
    .mat-flat-button,
    .mat-fab,
    .mat-mini-fab {
      font-size: 12px;
    }
    .col-lg-6 {
      width: 100% !important;
    }
    .mat-raised-button {
      min-width: auto;
      line-height: 29px;
      padding: 0px 10px;
    }

    .mat-button-toggle-label-content {
      padding: 0 10px;
    }
    .mat-menu-item {
      line-height: 34px !important;
      height: 40px;
      font-size: 12px;
      .ui-text {
        font-weight: normal !important;
      }
    }
  }

  .mat-button,
  .mat-raised-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    font-size: 12px;
  }
  .col-lg-6 {
    width: 100% !important;
  }
  .actions.button-view {
    flex-direction: column !important;
    align-items: flex-start !important;
    width: 100%;
    button {
      width: 100%;
      margin-bottom: 1em;
      .outline_btn {
        width: 100%;
      }
    }
  }
  .actions.button-view.execute_btn button {
    width: auto;
    margin-top: 0.5em !important;
    right: 0.5em !important;
}
.p-datatable table{width: auto !important;}
  .login-page-component {
    display: block;
  }
  .grid-formui .col-lg-6 {
    width: 100% !important;
  }
  #formstiky {
    width: 98%;
  }
  .cardrow {
    height: auto !important;
    padding: 20px 8px;
    padding-right: 0px;
    margin-top: -3em;
  }
  .fury-list-table .mat-cell,
  .fury-list-table .mat-header-cell {
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
  }
  //  .p-datatable table {
  //   table-layout: auto !important;
  //  }
  .fury-default fury-list,
  .fury-dark fury-list {
    overflow-y: scroll;
 
    display: flex;
  }
  

  .ag-body-horizontal-scroll-viewport {
    overflow-x: hidden;
  }
  .paginationInput input {
    text-align: center;
  }
  .mat-menu-panel {
    width: auto !important;
  }
  .p-datatable .p-datatable-thead > tr > th {
    padding: 1rem !important;
  }
  .mat-stepper-vertical {
    max-width: 100%;
  }
  .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    left: auto;
    top: 0%;
    width: 100% !important;
    left: 0em;
    margin: 0px auto;
    z-index: 101;
    min-width: 85%;
  }
  span.stickyButton {
    display: flex;
    position: initial;
  }
  .mat-vertical-stepper-header .mat-step-icon:first-child {
    display: inline-block;
    vertical-align: bottom;
  }
  .login-page-component .card.border-radius.elevation {
    //  width:  100%;

    width: 100% !important ;
    margin-top: 15% !important;
  }
  .topshape {
    top: -3% !important;
    right: -124% !important;
    img {
      width: 40em !important;
    }
  }
  // .circle1,
  // .circle2,
  // .circle3,
  // .topshape {
  //   display: none;
  // }

  .workflowprocess {
    white-space: nowrap !important;
  }
  .outline_btn{
      margin-top: 0px !important;
      height: 32px !important;
  }
  mat-vertical-stepper {
    .mat-raised-button {
      line-height: 40px;
    }
    .mat-vertical-content {
      padding: 24px;
    }
  }
  .card .content {
    margin-top: 0px;
  }
  .process_card {
    padding-left: 22px;
    padding-right: 16px;
  }
  // table {
  //   display: inherit;
  // }

  // .mat-form-field-appearance-outline .mat-form-field-flex{margin-left: -6px;}
  .Rightwriting_attachment,
  .leftwriting_attachment {
    padding-left: 0px !important;
    margin: 0px 0 20px 0;
  }
 .right_attachment.pl-2, .left_attachment.pl-2 {
   padding-left: 5px !important;
   margin-top: 4px;
  }
  .right_attachment {
    padding-left: 5px !important;
    margin: 0px 0 20px 0;
}
.newbtn button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary{
  border-radius: 51%;
  padding: 0px;
}
.Rightwriting_attachment {
    padding-left: 7px !important;
  }
.leftwriting_attachment{
  padding-left: 7px !important;
}
.notesandcomments{
.addicon_right{
  cursor: pointer;
  position: absolute;
  display: inline-flex;
  height: 32px;
  top: -37px;
  right: 12px !important;
}
.notescomments_icon{
  display: block;
  position: absolute;
  right: 12px !important;
  left: auto;
  top: 4em;
}
 .Notes_icon h5{
  margin: 12px 0;
  margin-left: 44px !important;
  margin-top: 0 !important;
 }
 .form-btns .text-area{
  width: 76% !important;
  left: 0em !important;
  bottom: 0em !important;
  margin: 0px 0px 14px 43px!important;
  }
  .form-calender{
    position: relative;
    margin-left: 3em !important;
    top: 1em;
}
.form-button{
  position: relative;
  left: 0 !important; 
  top: -2em !important;
  display: inherit !important;
  align-items: center;
}
.form-button .download-btn{
  margin-right: 2em !important;
  margin-top: 2em !important;
  margin-left: 3.4em !important;
}
}

  td .p-float-label {
    background-color: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .example-element-detail .workflow-check#assetbase {
    margin-left: 6em;
    margin-right: 4em;
  }
  .example-element-detail .workflow-check#statusRun {
    margin-right: 3em;
  }
  .example-element-detail .workflow-check#status2 {
    margin-left: 1em;
    margin-right: 1em;
  }
  .example-element-detail#fund {
    padding-top: 2px;
  }
  .dropdown.popupvw {
    margin-left: calc(100vw - 80vw);
    overflow: scroll;
    height: 100vh;
    top: 3vw !important;
  }
  .toolbarsetting.arrow.open::after{
    left: 4.89em !important;
    bottom: 1.3em !important;
  }
  .createpopup .popuporow {
    display: table !important;
  }
  .popuporow .col-1 {
    width:calc(100vw - 90px) !important;
    padding-top: 0px !important ;padding-bottom:0px !important;
    a {
      width: 53vw;
      white-space: normal !important;
    }
  }
  .popuporow .col-1:first-child {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
  }
  .createpopup .popuporow ul li .iconset {
    display: flex !important;
  }

    .tableheader-remove .work-flow  {
      
     
        width: 36% !important;
        float: left !important;
        }
        .grid-formui .col-lg-3.workflowheadinput{
          padding-left: 0px !important;
        }
        .p-multiselect-panel, 
        .p-dropdown-panel {
          position: absolute;
          min-width: 75% !important;
          left: 9% !important;
      }
      .report-delivery-btn {
         
        margin-top: -0.9em !important;
      }

      .report-del-btn{
        padding-left: 20px !important;

      }
      .report-del-btn-1{
        padding-right: 20px !important;
      }
      .report-del-btn-2{
        padding-right: 20px !important;
        padding-left: 20px !important;
      }
      
      
        .fury-default .workflow-scroll{
          overflow: auto !important;
        }
        // Login Page 404 Responsive
      .fury-dark .page_not_found .leftimg svg {
        display: none;
    }
    .fury-dark .page_not_found .rightlogin {
      width: 30%;
      align-items: center;
      justify-content: center;
      position: absolute;
  }
   //START New 404 page Responsive
 .new-404-page {
     width: 100%;  
 .sudrania-logo {
    position: absolute;
    top: 4vw !important;
    left: 3vw;    
    width: 55em;   
  }
  h1{
    font-size: 25px !important;
    line-height: 20px !important;
  }
  .center-page-login {
    width: 100% !important;
    text-align: center;
    padding: 20px;
    left: 0 !important;
    top: 2em;
    button {
      margin-left: 0 !important;
    }
  }  
  .circle1{
    top: 20em !important;
  }
  .circle2{
    top: 25em !important;
 }
  .circle3{
    top: 25em !important;
  }  
}
.fury-default .new-404-page {
    display: block;
}
.fury-dark .new-404-page .center-page-login { 
    top: -4em !important;
}
.fury-dark .login-footer1.login-footer2 {
    background-color: #1d2125 !important;
}
//END New 404 page Responsive
  .bond-scroll .example-element-detail div{
    overflow-y: initial !important;
  }
  .mat-grid-list.docManagementFields.date-of-birth {
    white-space: nowrap;
  }

.doc-upload {
  margin-left: 1em;
}
.documentTable{
  width: 600px;
}
// Toolbar Dropdown & Change password(Text) popup issue
.padding.toolbaruser_dialogbox {
  flex-flow: wrap;
}
.card .add-new-invest .content{
  margin-right: 3em;

}
.add-new-invest .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
  margin-top: 0em !important;
}
// Workflow page Responsive issue
table.mat-table.cdk-table.mat-sort.tableheader-remove.table_grid {
  width: 100% !important;
  table-layout: auto !important;
}
// Investor Create icon Fetch Button alignment issue
.createca_discapital .newbtn button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary {
  height: 33px;
  line-height: 33px;
  margin: 0px 0 0 6px;
  // border-radius: 4px;
  padding: 0em 2em 0em 2em;
}
// Doc mangement 
.pause_upload{
  width: auto !important;
  margin-left: 11.5em !important;
}
.mat-grid-tile.doc-fund {
  margin-left: -2.5em;
}
.doc-upload1 {
    margin-left: -3em;
}
.docTabel th {
  padding: 0em 0.5em !important;
  padding-right: 6.4em !important;
}
.doc-footer {
  margin-right: -9em;
}
.queue{
  margin-right: -9em;
}
// END Doc Management

// Investor Portal user Create icon plus button alignment issue
.addselection_icon{
  .add_icon {
    display: block;
    position: absolute;
    right: 10px;
    top: 0 !important;
    left: auto;
    bottom: auto;
  }
  }
  // END Investor Portal user Create icon plus button alignment issue

// Enterprises manager ROR config & Setup Je Transfer(fund level) input alignment isssue
.ror_config{  
  mat-form-field.mat-form-field.mat-primary.mat-form-field-type-mat-select.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-has-label.mat-form-field-should-float.mat-focused {
     padding: 1em 0em 0em 0em;
}
}
// END Enterprises manager ROR config & Setup Je Transfer(fund level) input alignment isssue

.recon-1 {
  white-space: nowrap;
  margin-left: 9em !important;
  margin-bottom: -3em !important;
  padding-left: 11em !important;
  left: 1em !important;
}
.recon-2{
  white-space: nowrap;
  margin-left: 9em !important;
  margin-bottom: -5em !important;
  padding-left: 7em !important;
  left: 0em !important;
}
//  Recon Trade Manually Responsive issue
.drop-1{
  margin-right: 0px; 
  margin-left: 10em;
 }
 button.mat-focus-indicator.mat-menu-trigger.options.icon.sm_button.mat-icon-button.mat-button-base {
  margin-top: -4em;
  display: flex;
  top: -2.4em !important;
  right: -10em;
}
.actions.oms-list-btns.count_button {
  display: inline-flex;
  position: relative;
  top: 5.6em;
  right: 2em !important;
}
button.mat-focus-indicator.mat-menu-trigger.options.icon.sm_button.sm_btn.mat-icon-button.mat-button-base {
  float: right;
  top: 9.2em !important;
  display: flex;
  right: -1em !important;
}
  // END Recon Trade Manually Responsive issue

  // Multi Trade Manually Responsive issue
  .drop-1.mrm_input {
    margin-left: 4em !important;
    margin-right: -1em !important;
    margin-left: 10em !important;
    margin-right: -9em !important;
}
.actions.oms-list-btns.mrm_count {
  display: inline-flex;
  position: relative;
  top: 4.2em;
  right: 10px !important;
}
// END Multi Trade Manually Responsive issue
.forgot-svg{
  width: 10em;
}

.background-pattern .sudrania-logo {
  position: absolute;
  top: 5% !important;
  left: inherit !important;
  justify-content: center;
  display: flex;
  text-align: center;
      img{
        width: 55% !important;
      }
}
.forgot-password-page{
    position: relative;
    top: 6em !important;
}
.forgot-password-page .sudrania-logo {
  position: absolute;
  top: -3em!important;
  left: 6em!important;
  justify-content: center;
  display: flex;
      img{
        width:55% !important;
 	     }
}
.copy-right-generate{

  margin-bottom: -6em !important;
}
.cardrow.querycard .tradcard.accoutcard {
 margin-left: 0em !important;
}
.cardrow.querycard .tradcard.accoutcard:nth-child(2) {
  margin-top: 2em !important;
  margin-bottom: 4em !important;
}
.introductionv
{
  padding-top: 0em !important;
  padding-bottom: 0em !important;
  flex-direction: column !important;
  .heading{
    padding-top: 2em;
  }
 iframe {
  width: auto;
  padding-left: 0em !important;
}
.introductionp iframe{
  width: auto !important;
}
}
.p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-footer button{
top:0px;
}
.btrackform.form-input-set .align-forms {
  display: grid !important;
}
.btrackform.form-input-set .align-forms mat-form-field{
  margin-right: 0em;
  width: 100% !important;
}
.modal-scd-head{
  display: inherit;
}
.btrack .btrackform{
  display: inherit;
}
.btracknav {
  display: initial;
}
.btrack .btrackform mat-form-field{width: 100%;}
.p-dialog .p-dialog-content{padding: 0 1rem 2rem 1rem !important;}
.modal-scd-head .btrackform{padding: 0px !important;}
.bouncebox{right: 5em;top: 7.5em;text-align: right;}
.bdetailsp{
  white-space: normal;
}
.mat-step-text-label {
  font-size: 12px;
}
span.stickyButton.button-crypto .mat-button-wrapper{padding: 0px !important;}
span.stickyButton.button-crypto .mat-raised-button{ padding: 0px 5px !important; height: 30px !important;}
span.stickyButton.button-crypto .mat-mini-fab{width: 36px;  height: 36px;}
span.stickyButton{
  top: 50% !important;
}
.btrack{
  .btrackform{
    mat-form-field {
      margin-right: 1em;
      margin-left: 0em !important;
    }
  }
}
mat-checkbox.runtaxlot-checkbox {
  margin-bottom: 20px;
}
.newbtn button {
  margin-bottom: 10px;
}
.ror-input.ror_config {
  margin-top: 15px;
}
.toolbar_inputfield .mat-form-field{
  width: 100% !important;
}
.expanddiv{
  flex-direction: column !important;
  width: calc(100% - 4em) !important;
}
.example-element-detail .formcontainer{
  width: calc(100% - 25em) !important; 
  padding: 0px 0px !important;
}
.example-element-detail .actioncontainer{
  padding: 0px 0px 0px 30px !important;}
  .align-forms.f-align {
    flex-direction: column !important;
}
  .report-batchissue .p-dialog .p-dialog-footer{padding-top: 0em !important;}
 
  .executework-flowrun.Dialog_width_small .left-execut-workflwo{
    width: 100% !important;
  }
  .executework-flowrun.Dialog_width_small .left-execut-workflwo .p-button{
    padding: 0.5rem 0.3rem;
    font-size: 0.9rem;
  }
  .executework-flowrun.Dialog_width_small .right-execut-workflwo{
    left: 0em !important;
    width: 100% !important;
  }
  .reportingsvg{
    width: 100% !important;
  }
  .migsvgsro{
    width: 26em !important;
  }
  h3.svgclicktext{
    font-size: 0.8em !important;
  }
}
@media only screen and (min-device-width : 640px) and (max-device-width : 767px) {
  .executework-flowrun .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
    min-width: 40em;
}
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  fury-breadcrumbs .crumb.current .link{     font-size: 12px;
    .mat-icon.notranslate{ padding-left: 2px; padding-right: 2px;}
  }
  .bouncebox{text-align: right;}
  .documentTable .mat-grid-tile {
    overflow: inherit;
  }
  // OMS Allocated Order button issue
  .Oms_button .p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0 1.3rem 2.5rem 1.4rem !important;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

  .Oms_button .p-dialog .p-dialog-footer button {
    margin: 0 !important;
    width: auto;
}
.btrackform.form-input-set .align-forms {
  display: grid !important;
}
.btrackform.form-input-set .align-forms mat-form-field{
  margin-right: 0em;
  width: 100% !important;
}
.modal-scd-head{
  display: inherit;
}
.btrack .btrackform{
  display: inherit;
}
.btracknav {
  display: initial;
}
.btrack .btrackform mat-form-field{width: 100%; margin-right: 0px;}
.p-dialog .p-dialog-content{padding: 0 1rem 2rem 1rem !important;}
.modal-scd-head .btrackform{padding: 0px !important;}
span.stickyButton.button-crypto .mat-button-wrapper{padding: 0px !important;}
span.stickyButton.button-crypto .mat-raised-button{ padding: 0px 5px !important; height: 30px !important;}
span.stickyButton.button-crypto .mat-mini-fab{width: 36px;  height: 36px;}
span.stickyButton{
  top: 50% !important;
  right: 2em !important;
}
.btrack{
  .btrackform{
    mat-form-field {
      margin-right: 1em;
      margin-left: 0em !important;
    }
  }
}
mat-checkbox.runtaxlot-checkbox {
  margin-bottom: 20px;
}
.newbtn button {
  margin-bottom: 10px;
}
.ror-input.ror_config {
  margin-top: 15px;
}
.toolbar_inputfield .mat-form-field{
  width: 100% !important;
}
.report-batchissue .p-dialog .p-dialog-footer{padding-top: 0em !important;}
.executework-flowrun.workflow-execute-modal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable{
  min-width: 23em;
}
.executework-flowrun.Dialog_width_small .left-execut-workflwo{
  width: 100% !important;
}
.executework-flowrun.Dialog_width_small .left-execut-workflwo .p-button{
  padding: 0.5rem 0.3rem;
  font-size: 0.9rem;
}
.executework-flowrun.Dialog_width_small .right-execut-workflwo{
  left: 0em !important;
  width: 100% !important;
}
}
@media (max-width: 320.98px) {
  .bouncebox{text-align: right;}
  span.stickyButton.button-crypto .mat-button-wrapper{padding: 0px !important;}
  span.stickyButton.button-crypto .mat-raised-button{ padding: 0px 5px !important; height: 30px !important;}
  span.stickyButton.button-crypto .mat-mini-fab{width: 36px;  height: 36px;}
  span.stickyButton{
    top: 50% !important;
    right: 0.3em !important;
  }
  span.stickyButton.button-crypto.mat-button,
    .mat-raised-button,
    .mat-icon-button,
    .mat-stroked-button,
    .mat-flat-button,
    .mat-fab,
    .mat-mini-fab {
      font-size: 11px !important;
    }
    .ror_config .mat-form-field-wrapper{margin-top: 10px !important;}
    .btrack{
      .btrackform{
        mat-form-field {
          margin-right: 1em;
          margin-left: 0em !important;
        }
      }
    }
    mat-checkbox.runtaxlot-checkbox {
      margin-bottom: 20px;
  }
  .newbtn button {
    margin-bottom: 10px;
}
.toolbar_inputfield .mat-form-field{
  width: 100% !important;
}
.report-batchissue .p-dialog .p-dialog-footer{padding-top: 0em !important;}
.executework-flowrun.workflow-execute-modal .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable{
  min-width: 19em;
}
.executework-flowrun.Dialog_width_small .left-execut-workflwo{
  width: 100% !important;
}
.executework-flowrun.Dialog_width_small .left-execut-workflwo .p-button{
  padding: 0.5rem 0.3rem;
  font-size: 0.9rem;
}
.executework-flowrun.Dialog_width_small .right-execut-workflwo{
  left: 0em !important;
  width: 100% !important;
}
}
@media (max-height: 753px) {
  .simplebar-content-wrapper {
    height: calc(100vh - 16.6vh) !important;
  }
}
@media (min-width: 2733px) {
  .cardrow {
      display: flex;
      // flex-flow: wrap;
      justify-content: flex-start;
        align-items: flex-start;
      max-width: 100vh;
      .tradcard{width: 28em !important;} 
      .bodytext{
        li{
          white-space: nowrap;
        }
      }  
      } 
.cardrow.adminentrow {
        max-width: inherit !important;
        
      }
      .process_card{max-width: inherit;} 
  }
.cardrow.adminentrow.queryrow{
  padding: 20px 0px;
  display: block;
  column-gap: 10%;
  -webkit-column-gap: 10%; 
}
.cardrow.adminentrow {
  height: auto !important;
  display: block;
  flex-flow: wrap;
  justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 24px;
  .tradcard{width: 24em !important;} 
  .bodytext{
    li{
      white-space: nowrap;
    }
  }
  .cardrow.sysadminrow {
    height: auto !important;
    display: block;
    flex-flow: wrap;
    justify-content: flex-start;
      align-items: flex-start;
      padding: 20px 24px;
    .tradcard{width: 24em !important;} 
    .bodytext{
      li{
        white-space: nowrap;
      }
    }}
  .querycard{
    .bodytext{
      li{
        white-space: normal;
      }
    }

  }
}  
// Galaxy Fold max width 320px
    @media(max-width: 320px){ 
      .login-page-component{
        display: block;
        .leftimg{ margin: auto; display: none;}
        svg{ margin-top: -0.4em;    width: 7em; }
        
        }
        .reconcilication-body .accoutcard:nth-child(3) h2{
          padding-right: 3.8em;
          line-height: initial;
      }
        
    }
    @media (orientation: landscape){
    .mat-datepicker-content-touch .mat-datepicker-content-container {
      width: inherit !important;
      height: inherit !important;}}