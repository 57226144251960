/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
fury-inbox {
  .drawer-content > .header {
    background: $theme-color-accent url('/assets/img/backgrounds/inbox.png');
  }

  fury-inbox-navigation {

    .mat-list-item {
      &.active {
        color: $theme-color-primary;
        background: $theme-background-hover;
      }

      &:hover {
        background: $theme-background-hover;
      }
    }
  }

  fury-inbox-mail {

    .vertical-divider {
      background: $theme-divider;
    }
  }

  fury-inbox-mail-list {

    .mail {
      &:hover {
        background: $theme-background-hover;
      }
    }

    .picture {
      background: $theme-app-bar;
    }
  }
}
