/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
$config: mat-get-color-config($theme);

$theme-foreground-map: map-get($config, foreground);
$theme-background-map: map-get($config, background);
$theme-primary-map: map-get($config, primary);
$theme-accent-map: map-get($config, accent);
$theme-warn-map: map-get($config, warn);

$theme-text: mat-color($theme-foreground-map, text);
$theme-hint-text: mat-color($theme-foreground-map, hint-text);
$theme-secondary-text: mat-color($theme-foreground-map, secondary-text);
$theme-divider: mat-color($theme-foreground-map, divider);
$theme-icon: mat-color($theme-foreground-map, icon);
$theme-elevation: mat-color($theme-foreground-map, elevation);

$theme-background: mat-color($theme-background-map, background);
$theme-app-bar: mat-color($theme-background-map, app-bar);
$theme-status-bar: mat-color($theme-background-map, status-bar);
$theme-background-hover: mat-color($theme-background-map, hover);

$theme-card: mat-color($theme-background-map, card);

$theme-color-primary-palette: map-get($config, primary);
$theme-color-accent-palette: map-get($config, accent);
$theme-color-warn-palette: map-get($config, warn);

$theme-color-primary: mat-color($theme-color-primary-palette);
$theme-color-accent: mat-color($theme-color-accent-palette);
$theme-color-warn: mat-color($theme-color-warn-palette);

$theme-color-primary-contrast: mat-contrast($theme-color-primary-palette, $theme-primary-hue);
$theme-color-accent-contrast: mat-contrast($theme-color-accent-palette, $theme-accent-hue);
$theme-color-warn-contrast: mat-contrast($theme-color-warn-palette, $theme-warn-hue);
