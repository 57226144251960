  /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
@use "sass:math";
@import "var";

body:has(#fury-dashboard),
html:has(#fury-dashboard) {
  --font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
body:has(#fury-dashboard .fundinput) .cdk-overlay-pane .mat-select-panel-wrap{
  top: 2.4em !important;
}
body:has(#fury-dashboard .fundinput .mat-focused) .mat-select-panel-wrap{
  top: 8.4em !important;
}

fury-dashboard{
.inputrow{
  margin-bottom: 0% !important;
}
  span.mat-button-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    width: 2.5em;
}
    fury-card-actions{
      span.mat-button-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
      .mat-form-field-appearance-fill{
        .mat-select-value-text {
          font-size: 15px;
      }      
        .mat-form-field-wrapper {
          padding-bottom: 0em;
          .mat-form-field-underline {
            bottom: 0px;
            }
          }
         .mat-form-field-flex {
          padding: 1em 0.75em 1rem 0.75em;
          background-color: #0000000a;
          .mat-select-arrow-wrapper {
            transform: translateY(-18%);
        }
      }
     
      }
    }
}
#fury-dashboard {
  fury-advanced-pie-chart-widget .legend-item{
    min-width: 9.5rem !important;
    margin-top: 2px; 
  }
    fury-recent-sales-widget-table {
      .table {
        border-top: 1px solid rgba(0, 0, 0, .12);
      //  min-height: 297px;
      }
  
      .mat-header-cell {
        font-size: 14px;
        color: #000000de;
        // font-weight: 800 !important;
      }
    }
    .toprow .header {
      //fury-line-chart-widget.MultiPeriodRor_card .header
      padding: 8px 24px;
  }

  // fury-audience-overview-widget .fury-card-content {
  //   padding: 0 24px 24px !important;
  //   flex: 1 1 auto;
  //   display: flex;
  //   flex-direction: column;
  //   overflow: hidden;
  // }
    div.norecord{
    padding: 9.97rem 0rem;
    display: flex;
    align-self: center;
  }
  // .simplebar-content:hover > div{
  //   overflow: auto;
  // }
  .simplebar-scrollbar::before {
    background: black !important;
}
.pb-10 {
  padding-bottom: 10px;
}
  .mat-paginator-range-actions{
    max-width: 12rem;
    .mat-paginator-range-label {
      margin: 0 0px 0 12px;
      white-space: nowrap;
  }
  }
  .fury-card {
    border-radius: 8px !important;
    //background: white;
    // color: #000000de;
    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  }

    // .fury-default .fury-card {
    //   background: white;
    //   color: #000000de;
    //   box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
    // }

  .fury-default .fury-card .fury-card-actions {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .fury-card .fury-card-actions .section:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .fury-card .fury-card-content {
    padding: 0 24px 24px !important;
  }
  .fury-card .fury-card-content.card-content {
    padding: 0 !important;
    .sections{
      padding: 0 24px 24px !important;
    }
  }
  // .fury-card .fury-card-content.card-content :not(fury-audience-overview-widget){ 
  //   padding: 0 !important;
  //   .sections{
  //     padding: 0 24px 24px !important;
  //   }
  // }

  fury-audience-overview-widget fury-card .fury-card-content.card-content {
    padding: 0 24px 24px !important;
  }
 .fury-card-content:has(.fury-list-table) {
    padding: 0px !important;
}

  .mat-card {

    width: auto !important;
    height: auto !important;
  }

  fury-donut-chart-widget .label {
    background: none !important;
    color: rgba(0, 0, 0, 0.54) !important;
    width: auto !important;

  }

  .fury-default .fury-list-header,
  .fury-default .fury-card,
  .fury-card {
    border-radius: 8px !important;
  }

  .fury-card-content {
    padding: 0 24px 24px !important;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
    // fury-card-header .fury-card-header.header {
    //   padding: 8px 24px !important;
    // }
  .fury-card-header {
    .header {
      padding: 8px 24px !important;
    }
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
  }
    fury-card.fury-card.realtime-users-widget {
      min-height: 335px !important
    }
  
    fury-line-chart-widget.MultiPeriodRor_card fury-card.fury-card {
      min-height: 400px !important
    }

    fury-bar-chart-widget.MonthEndNav fury-card.fury-card {
      min-height: 214px !important
    }
  
  .tableview_desgin th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
   border: 1px solid #f7f7f700;
   border-bottom-color: #f7f7f7 !important;
}

}

.dashboard_Input {
  margin-top: 10px;
  margin-left: 21px;
  margin-bottom: -30px;
  box-sizing: border-box;
  display: flex;
  // justify-content: end;
}


td.sudrania-staticLineWidth.sudrania-lineBorderSingle {
  padding: 8px 0 8px 0;
}

// .sudrania-staticLineWidth {
//     width: 110px;
// }


.mytable {
  border: 1px solid #dee2e6;

  tbody tr:nth-child(2n + 2) {
    background: #fcfdfe;
  }

  tbody tr td,
  th:nth-child(1) {
    padding-left: 15px;
  }

  tbody tr:hover {
    background: #f1f8f8;
  }

  th,
  td {
    padding: 0.4em;
    font-size: 12px !important;
    font-weight: normal;
  }
}

  .mytable {
    border: 1px solid #dee2e6;

    tbody tr:nth-child(2n + 2) {
      background: #fcfdfe;
    }

    tbody tr td,
    th:nth-child(1) {
      padding-left: 15px;
    }

    tbody tr:hover {
      background: #f1f8f8;
    }

    th,
    td {
      padding: 0.4em;
      font-size: 12px !important;
      font-weight: normal;
    }
  }
    .fury-dark {
      #fury-dashboard {
      .label {
          color:#ffffffb3
        }
        .total-value{
          color: #ffffffb3;
        }
        .total-label{
          color: #ffffffb3;
        }
        .item-value{
          color: #ffffffb3;
        }
        .item-label{
          color: #ffffffb3;
        }
        .mat-header-cell{
          color: #fff;
        }
        td.mat-cell {
          border-bottom-color: rgba(239, 199, 199, 0.12) !important; 
       }
      }
      fury-audience-overview-widget .property.active .label {
        color:#fff !important;
      }
      

      
    }