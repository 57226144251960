/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
body.is-blink {

  ::-webkit-scrollbar {
    width: $blink-scrollbar-width;
    height: $blink-scrollbar-width;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
    border-radius: $blink-scrollbar-width;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
    border-radius: $blink-scrollbar-width;
  }
}

body.is-blink.fury-dark {

  ::-webkit-scrollbar {
    background-color: rgb(255 255 255);
  }

  ::-webkit-scrollbar:hover {
    background-color: rgb(255 255 255);
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 12px rgb(48 48 48 / 40%);
    
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 12px rgba(255, 255, 255, 0.54);
    background-color: rgb(148, 148, 148);
  }
}
