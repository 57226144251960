//froala editor custom css
.fr-text-none {
  text-transform: none;
}
.fr-text-uppercase {
  text-transform: uppercase;
}
.fr-text-lowercase {
  text-transform: lowercase;
}
.fr-text-capitalize {
  text-transform: capitalize;
}
fury-fs-notes-editor {
  button[data-cmd="fontSize"] span {
    width: 41px !important;
  }
}
// FS Exclude Headers Start
#excludeHeadersName {
  padding-left: 5px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
}
// FS Exclude Headers End
#expenseGPCheck {
  min-height: 30px !important;
}