.setup-process .default-grid .view-data-collection .card {
    border-radius: 0px !important;
    z-index: 9;
    margin-top: 0px;

    .fury-list-table {
        z-index: 1000000;
    }

    .fury-list-header {
        z-index: 1000000;
    }
}
.setup-process .default-grid .view-data-collection.algin-gridexpandicon-processing {
    .card.grid-headerarchive {
        .fury-list-header {
            z-index: 10;
        }
    }
}

.newmodalui-view .moodal-view-toolbar .popup-view-toolbar1 .yellow {
    color: #ee8434;
    font-size: 16px;
}
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar1 .setup-content-view .gray-bg{
    background: #fff9f1;
}
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar1 .gray{
    color: #646668;
} 
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar1 .setup-content-view li:hover .iconset{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar1 .setup-content-view li:hover .iconset span{
    font-size: 16px;
    font-style: normal;
}
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar2 .yellow {
    color: #ee8434;
    font-size: 16px;
}
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar2 .setup-content-view .gray-bg{
    background: #fff9f1;
}
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar2 .gray{
    color: #646668;
} 
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar2 .setup-content-view li:hover .iconset{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.newmodalui-view .moodal-view-toolbar .popup-view-toolbar2 .setup-content-view li:hover .iconset span{
    font-size: 16px;
    font-style: normal;
}



.newmodalui-create .moodal-view-toolbar .popup-view-toolbar1 .yellow {
    color: #ee8434;
    font-size: 16px;
}
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar1 .setup-content-view .gray-bg{
    background: #fff9f1;
}
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar1 .gray{
    color: #646668;
} 
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar1 .setup-content-view li:hover .iconset{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar1 .setup-content-view li:hover .iconset span{
    font-size: 16px;
    font-style: normal;
}
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar2 .yellow {
    color: #ee8434;
    font-size: 16px;
}
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar2 .setup-content-view .gray-bg{
    background: #fff9f1;
}
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar2 .gray{
    color: #646668;
} 
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar2 .setup-content-view li:hover .iconset{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.newmodalui-create .moodal-view-toolbar .popup-view-toolbar2 .setup-content-view li:hover .iconset span{
    font-size: 16px;
    font-style: normal;
}

app-grid-filter.oms-fullwidth.input-card.trade-view-input {
    .fury-page-layout-card {
        padding-bottom: 0px !important;
    }

    .card {
        margin-bottom: 0px;
    }

    .content {
        padding: 0 20px;
    }

}
.ordermanagement-grid .tab-active-clr .mat-tab-links .mat-tab-link{
    padding: 0 20px;
}
.ordermanagement-grid .actions.oms-list-btns.uploadbtn_alignment{
    margin-right: 15px;
}
.ordermanagement-grid .fury-list-name.english_gridheader {
    width: 18vw !important;
}
.ordermanagement-grid .fury-list-name.french_gridheader{
    width: 18vw !important;
}
.ordermanagement-grid .custom_calende_css{
    margin-left:5px ;
}
.ordermanagement-grid .fury-list-table .fury-list-header{
    padding-left: 16px;
    padding-right: 16px;
}
.data-set-align .data-setui {
    width: 100%;
}
.rapid-expend-grid .view-data-collection .data-set-align .data-setui .card{
    margin-top: 0;
}

.default-grid .view-data-collection .data-set-align fury-list {
display: inherit !important;
}
.card.grid-input-viewform .tab-margin {
    .fury-page-layout-content {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px;
    }

}

.default-grid .rapid-field-icon-processing-account {
    margin-bottom: 24px;
    border: solid 1px rgba(0, 0, 0, .12);
    padding: 0em 1em 1em 1em;
    width: 93.7%;
    border-radius: 5px;
    margin-bottom: 1.2em !important;
    margin-top: 24px !important;
    margin-left: 24px;

    .ac-typelabel {
        position: absolute;
        top: 13px;
        background: #fff;
        font-weight: 500;
        padding: 0 5px;
    }

    input {
        width: 20px;
        height: 20px;
    }
}
.rapid-expend-grid .rapid-field-icon-processing-account {
    margin-bottom: 24px;
    border: solid 1px rgba(0, 0, 0, .12);
    padding: 0em 1em 1em 1em;
    width: 95.7%;
    border-radius: 5px;
    margin-bottom: 1.2em !important;
    margin-top: 24px !important;
    margin-left: 24px;

    .ac-typelabel {
        position: absolute;
        top: 13px;
        background: #fff;
        font-weight: 500;
        padding: 0 5px;
    }

    input {
        width: 20px;
        height: 20px;
    }
}

// 0=== new common css start==========
.setup-process {
    .step-flow {        
        position: relative;
        margin: 40px 22px 20px 50px;
        width: 23em;
        border-right: solid 1px #eae6e1;
        opacity: 1;
        height: calc(100vh - 11em);
        // overflow: auto;
        .step-work {
            width: 275px;
            .accordian-part{ 
                display: block; 
                width:275px;
            }
        }
    }

    .setup-progress {        
        display: flex;
        margin-top: 50px;
        .setup-form {
            .setup-success-screen {                
                margin: 145px 185px;
                width: 290px;
                text-align: center;
                p {
                    font-size: 18px;
                    color: #1d2125;
                }
            }
        }
        .progress-bar {
            p {
                text-align: center;
                margin: 0px;
                color: #1d2125;
                opacity: 1;
                font-size: 15px;
                font-weight: 500;
            }    
        }

    }

    .user-management-flow {
        margin-left: 50px !important;
    }
    .setup-gridview {
        display: flex;
        width: 68%;
        justify-content: flex-end;
        p {
            text-align: center;
            margin: 0px;
            color: #1d2125;
            opacity: 1;
            font-size: 15px;
            font-weight: 500;
        }
    }    
    .mat-accordion .mat-expansion-panel-body {
        padding: 6px 0;
    }
    .mat-expansion-panel:not([class*=mat-elevation-z]) {
        box-shadow: none;
        background: none;
    }
    .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
    .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
        background: none;
    }    
    .setup-grid-form-view .formgrid-view ::ng-deep .setGridHeight {
        height: calc(78vh - 7em - 45px) !important;
    }   
    
    .mat-expansion-panel-header.mat-expanded {
        height: auto;
        border-radius: 0px;
        padding-bottom: 10px;
        place-items: self-start;
    }
    
    .mat-expansion-panel-header {
        height: auto;
        padding: 0 16px 0 10px;
        place-items: self-start;
    }    
}

// 0=== new common css end==========


// 1======================setup component css start=====
.setup-process.setup_rapid {
    .setup-progress {
        display: flex;
        margin-top: 3.35em;
        width: 65%;

        .progress-bar {
            margin-top: -18px;
            margin-left: 16px;
        }
    }
    .step-flow{
        margin:40px 26px 0 79px;
    }
    .setup-gridview {
        margin-top: 40px;
        width: 65%;
        .progress-bar {
            margin-top: -13px;
            margin-left: -6px;
        }

        .setup-form {
            .setup-success-screen {
                margin: 150px 182px;
                width: 300px;
                text-align: center;
            }
        }
    }
}

// 2==========customization component css start ======

.setup-process.customization_rapid {
    .step-flow {
        .step-work {           
            .step-content {
                padding: 13px 0px;
                cursor: pointer;
                text-align: initial; 
                width: 275px;
            }
        }
    }
    .setup-progress {

        .progress-bar {
            margin-top: -18px;
            margin-left: 10px;
               
        }
    }
    .mat-content {
        flex: inherit; width: 94%;
    }

    .expansion-heading h3 {
        margin: 0px 0px 0px 0px;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 500;
    }

    span.detail-fields {
        display: flex;
        border-top: solid 1px #eae6e1;
        padding: 10px;
        .default-icons {
        font-size: 18px!important;
        padding: 4px;
        color: #1d2125;
        }
        .material-icons-outlined{width: auto; margin-right: auto; }
    }
    span.detail-fields .active-icon {
        font-size: 18px!important;
        padding: 4px;
        color: #1976d2;
    }

    .mat-expansion-indicator::after {
        transform: rotate(45deg) !important;
    }

    .mat-accordion .mat-expansion-panel-body {
        padding: 6px;
    }   
}

// 3=========================== user management conponent css start ====

.setup-process.usermanagement_rapid {
    .step-flow {
        .step-work {
            .step-content {
                padding: 0px 10px 0px 10px;
                cursor: pointer;
                text-align: initial;
                width: 100%;
            }
        }
    }
    .setup-progress {
        .progress-bar {
            margin-top: -18px;
            margin-left: 10px;
        }
    }
    .selected {
        h1 {
            color: #1976d2 !important;
        }
    }    
    .setup-grid-form-view .formgrid-view .expend-view {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: -1.6em;
        margin-bottom: 0.2em;
        margin-right: 25px;
        float: right;
    }
    .setup-grid-form-view .formgrid-view .collapse-view {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: -1.6em;
        margin-bottom: 0.2em;
        float: right;
    }
}

// 4================== input component css start ====

.setup-process.input_rapid {
    .setup-grid-form-view{
        margin-top: 50px !important;
    }
    .setup-progress {
        .progress-bar {
            margin-top: -18px;
            margin-left: 16px;
        }
    }
    .setup-gridview {

        .progress-bar {
            margin-top: -13px;
            margin-left: -6px;            
        }
        .setup-form {
            .setup-success-screen {
                margin: 150px 182px;
                width: 300px;
                text-align: center;
            }
        }
    }    
}
// 5=================== processing component css start  ===========
.setup-process.processing_rapid {
    .setup-grid-form-view{
        margin-top: 50px !important;
    }
    .setup-progress {
        .progress-bar {
            margin-top: -18px;
            margin-left: 16px;
        }
    }
    .setup-gridview {
        .progress-bar {
            margin-top: -13px;
            margin-left: -6px;            
        }
        .setup-form {
            .setup-success-screen {
                margin: 150px 182px;
                width: 300px;
                text-align: center;
            }
        }
    }    
}

// 6================== distribution component css start====
.setup-process.distribution_rapid {
    .step-flow {
        .step-work .step-content {
            padding: 13px 0;
            cursor: pointer;
            text-align: initial;
        }
    }
    .mat-content {
        flex: inherit;
        width: 100%;
    }
    .expansion-heading h3 {
        margin: 0px 0px 0px 0px;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 500;
    }
    span.detail-fields {
        display: flex;
        border-top: solid 1px #eae6e1;
        padding: 10px;
    }
    span.detail-fields .default-icons {
        font-size: 18px;
        margin-right: 0px;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        color: #1d2125;
        cursor: pointer;
    }
    span.detail-fields .active-icon {
        font-size: 18px;
        padding: 4px;
        color: #1976d2;
    }
    .mat-expansion-indicator::after {
        transform: rotate(45deg) !important;
    }
    .mat-accordion .mat-expansion-panel-body {
        padding: 6px 0;
    }   
}

// // my css vks 
.setup-process{
    width: 100%;
    display: flex;
    .content-setup-icon {
        //display: flex;
        width: 100%;
        .iconset.icnset-view {
            width: 10%;
        }
    }
    .step-flow {
        .step-work {
            width: 272px;
            min-height: 79px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0 3px 6px #eae6e1;
            border-radius: 10px;
            opacity: 1;
            text-align: center;
            align-items: center;
            display: flex;
            text-align: justify;
            margin-bottom: 10px;
            border: solid 1px #eae6e1;
            
            h1 {
                font-size: 14px;
                margin: 0 0 7px 0px;
                padding: 0px;
                font-weight: 500;
                line-height: normal;
                color: #1d2125de;
                font-family: "Open Sans";
            }
            p {
                font-size: 9px;
                margin: 0px;
                padding: 0px;
                color: rgba($color: #1d2125, $alpha: 0.5);
                font-weight: bold;
                width: 100%;
            }

            .act-view {
                background-color: #ffffff;
                width: 67px;
                height: 67px;
                border: solid 9px #646668;
                border-radius: 50px;
                padding: 16px;
                align-items: center;
                justify-content: center;
                display: flex;
                position: relative;
                margin-left: -30px !important;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -19px;
                    left: 10px;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-top: 15px solid #1976d2;
                }


                .act-inner {
                    font-size: 30px;
                    font-weight: bold;
                    color: rgba($color: #1d2125, $alpha: 0.5);
                }

            }

            .deactive-view {
                background-color: #ffffff;
                width: 67px;
                height: 67px;
                border: solid 9px #eae6e1;
                border-radius: 50px;
                padding: 16px;
                align-items: center;
                justify-content: center;
                display: flex;
                position: relative;
                margin-left: -30px;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -19px;
                    left: 10px;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-top: 15px solid #eae6e1;
                }

                .act-inner {
                    font-size: 30px;
                    font-weight: bold;
                    color: rgba($color: #1d2125, $alpha: 0.5);
                }
            }

            .done-view {
                background-color: #ffffff;
                width: 67px;
                height: 67px;
                border: solid 9px #1976d2;
                border-radius: 50px;
                padding: 16px;
                align-items: center;
                justify-content: center;
                display: flex;
                position: relative;
                margin-left: -30px;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -19px;
                    left: 10px;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-top: 15px solid #1976d2;
                }

                .act-inner {
                    font-size: 30px;
                    font-weight: bold;
                    color: rgba($color: #1d2125, $alpha: 0.5);
                }
            }

            .step-content {
                padding: 0px 7px 0px 7px;
                cursor: pointer;
                text-align: initial;
                width: 87%;
            }

            &:last-child {
                .act-view {
                    &:after {
                        opacity: 0;
                    }
                }
                .deactive-view {
                    &:after {
                        opacity: 0;
                    }
                }
            }
            .content-setup-icon {
                display: contents;
                width: 100%;
    
                .iconset.icnset-view {
                    width: 10%;
                }
            }
        }    
    }    
}
@media (min-width: 1200px) and (max-width: 1440px) {
    .setup-process {        
        .collapse-customization {
            position: absolute;
            left: 17em;
            top: 6.8em;
            color: #1976d2;

            .mat-icon {
                font-size: 42px;
                position: absolute;
                top: -10px;
                left: 60px;
            }
        }
        .expand-customization {
            position: absolute;
            left: 18em;
            top: 6.8em;
            color: #1976d2;

            .mat-icon {
                font-size: 42px;
                position: absolute;
                top: -9px;
                left: 50px;
            }
        }

    }     
}

//7=========connection-entry component css========

.form-control:disabled:hover{
    cursor: not-allowed;
}

.fileFormat-control{
   cursor: pointer;
}
.fileFormat-control:disabled:hover{
    cursor: not-allowed;
}
.non-custodian {
    padding-bottom: 3em;
}
button.mat-focus-indicator.blueheader.next.mat-button-disabled {
    opacity: 0.5 !important;
}
// SR-920 Custom Workflow: Trade Archive List modal ui
.investorgroup-modal-ctr.edit-mode-dailog.unarchive-record-ui {
    z-index: 1000000000;
    position: relative;
    .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable{
        margin-top: 4em;
        min-height: 100%;
    }
}
// SR-920 Custom Workflow: Trade Archive List modal ui

.card.cardw100.manualtax-lotui {
    margin: 0px;
    border-radius: 0px;
    table{
        table-layout: inherit;
    }
}
.rapid-expend-grid{
    .card.cardw100.manualtax-lotui {        
        width: 99%;
        margin-left: 40px;
        margin-right: 20px;
    }
}

.formcheck-box.inputalign-allrapid {
    padding-bottom: 15px !important;
    padding-top: 0px !important;
}
.tiered-radio .label-font{
    padding-left: 0px !important;
}

// wash sale comoponent css

.panel-heading1{
    max-height: 40px !important;
  }
  .panel-heading1 button{
    max-height: 39px !important;
  }
  .dropdown-item{
      cursor: pointer !important;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 2rem;
  }
  
  .navbar {
      display: inline !important;
      padding: 0 !important;
      min-height: auto;
  }
  
  .marginButton {
    margin-right: 5px !important;
  }
  
  
  .example-flex-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    float: right;
  }
  
  .example-button-container {
    display: flex;
    justify-content: center;
    width: 120px;
    float: right;
  }
  
  .example-edit-buttons{
    display:flex;
    overflow: auto;
    white-space: nowrap;
    margin:20px 0px auto 600px;
  }
  .panel-heading1 button:hover {
    background: #0fb7f8  !important;
  }

//   run tax lot closing comoponent scss

:host ::ng-deep .manualTaxLots p-checkbox {
    vertical-align: baseline;
  }
   
  .cardw100{
    width: 100%;
     }
  
     .pl-2{padding-left:30px;}
  
     .color1 {
      border-color: red;
    }
    .color2 {
      border-color: #7cb342;
    }
    .bgcolorTable {
      background-color: #0771b2 !important;
     }
// reset-open-tax-log component scss
::ng-deep p-tabMenu .p-component {
    margin-top: 25px !important;
}

::ng-deep .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    width: 20% !important;
}

::ng-deep .tab-content {
    padding: 0 !important;
}

::ng-deep .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    flex-flow: column-reverse !important;
}

::ng-deep .p-tabmenu .p-tabmenu-nav>li.p-highlight>a {
    color: #ffffff !important;
    background: #004e4e !important;
    font-weight: bold;
    border: none !important;
}

:host ::ng-deep .p-datatable-auto-layout>.p-datatable-wrapper {
    overflow-x: initial !important;
}

.comment {
    h5 {
        float: left;
    }

    textarea {
        overflow-x: auto;
        max-width: 100%;
        width: 100%;
        padding: 4px;

    }
}

.cardCustomClass {
    h5 {
        float: left;
        padding-left: 15px;
    }

    h4 {
        font-size: 14px;
        font-weight: 400;

        &.text-left {
            text-align: left;
        }
    }

    p-card {
        width: 100%;
        margin-bottom: 2em;
        min-height: 147px !important;
        height: 56px !important;
    }

    img {
        width: 5em;
        float: left;
        margin-right: 1em;
        margin-top: 1.5em;
    }

    a {
        float: left;
        color: #2185d0;
        align-items: center;
    }

}

.status {
    background-color: #21a055;
    color: #eae6e1;
    width: 4.5em !important;

}

:host ::ng-deep .omsTable {
    border: 1px solid #eae6e1;
    border-collapse: collapse;

    tr {
        border-bottom: 1px solid #eae6e1;
    }

    td {
        width: 120px !important;
        padding: 1em !important;
        border-right: 1px solid #eae6e1;
    }

    th {
        position: sticky;
        top: 0;
        z-index: 99;
        background: white;
        text-align: center;
        word-wrap: break-word;
        background: #eae6e1;
        white-space: nowrap;
        padding: 0.5em;
        font-weight: 400;
        overflow: hidden;
        border-bottom: 1px solid #eae6e1;
        border-right: 1px solid #eae6e1;
    }


    .p-dropdown {
        width: 110px !important;
        height: 32px !important;
        align-items: center !important;
        cursor: not-allowed !important;
    }

    input {
        width: 70px !important;
        background: transparent !important;
    }
}

:host ::ng-deep .headerOption {
    padding: 15px !important;
    padding-left: 0% !important;

    .p-dropdown {
        width: 100% !important;
        margin-bottom: 10px;
        height: 35px !important;
        align-items: center !important;
    }
}

.disablePdropdown {
    cursor: not-allowed !important;
}

.mat-tab-link {
    height: 4.5em;
    ;
}

.omsTable input {
    border: 1px solid #a49e9e;
    height: 2.1em;
    max-width: fit-content;
    border-radius: 3px;
    min-width: -webkit-fill-available;
}

.headerOption {
    display: flex;
    justify-content: space-between;

    .trade_dropdown {
        width: 49%;

        p-dropdown {
            width: 100%;
        }

    }

}

nav.mat-tab-nav-bar ::ng-deep .mat-ink-bar {
    visibility: visible !important;
    min-width: 160px; // or whatever your min size is
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1) !important;
}

.order_quantity {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}

.omsTable tr:last-child {
    font-weight: 700;
}

.approved_order {
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 8em;
    border-radius: 0.5em;
    padding: 15px;
    display: flex;
    align-items: center;
    position: relative;
    overflow-x: scroll;

    img {
        margin-top: 0;
    }




    .col-md-9 {
        width: calc(100% - 90px);

        .mailID {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            width: 100%;
            display: flex;
            padding-top: 2em;
        }

        .status {
            width: 100%;
            display: flex;
        }
    }

    .datediv {
        position: absolute;
        top: 0px;
        right: 15px;
    }

}

.text_comment {
    text-align: left;

}

// Approved order Popup Design issue on desktop
p-table.table_text.omstable_text {
    white-space: nowrap;
}

// trade archive component scss
.fund-multiselect .p-multiselect .p-multiselect-panel {
    min-width: 100%;
    z-index: 1000;
    transform: translate(0px, 17%) !important;
    
  }
  .first-multiselect .p-multiselect .p-multiselect-panel {
    width: 10em !important;
    z-index: 1000;
    transform: translate(0px, 43%) !important;
  }

  #brokerAccount .p-multiselect .p-multiselect-panel { 
    width: 40em;
    z-index: 1000;
    transform: translate(0px, 43%) !important;
  }

.fetch-date-modalheader {
    .marketdata-gridbbtn .p-dialog.p-component.p-dialog-draggable.p-dialog-resizable {
        max-width: 60vw !important;
        margin-bottom: 0em;
        min-width: 60vw !important;
        min-height: auto;
        background: #fff;
        left: -1.7em !important;
        max-height: fit-content;
    }
}
.no-record {
    text-align: center;
    margin: 20px;
  }
  .tableview_desgin table.mat-table{
    box-shadow: none;
  }
.report-delivery-card {
    .mat-tab-group {
        padding-bottom: 20px;
    }
    .download-btn {
        top: -50px !important;
    }
}
.reportapproval-modal {
    .p-dialog .p-dialog-content {
        min-height: auto;
    }
}
.align-custodian{
.mat-form-field-wrapper{
    padding-bottom: 0em !important;
    }
}
.choose-irr-checkbox {
    .process_lebel {
        width: 100%;
        display: block;
        .process_item {
            width: 33%;
            display: inline-flex;
            margin-top: 1em;
            margin-left: 0.1em;
        }

        mat-label {
            padding-left: 10px;
            vertical-align: super;
        }
    }
}
.allsmupload-inputalign{
    .mat-form-field{
        margin-bottom: 0px !important;
    }
    .mat-form-field-wrapper{
        margin-top: 0px !important;
    }
    .browse_section{
        margin-bottom: 0px !important;
        width: 99.3%;
        margin-left: 5px;
    }
}
// input workflow tree grid row css start
.p-treetable .p-treetable-tbody>tr>td {        
    padding: 0.3em 0rem !important;
    p-treetablecheckbox {
        padding-top: 6px !important;
        .p-checkbox .p-checkbox-box {
            width: 17px !important;
            height: 17px !important;
        }
    }
    span{
        line-height: 30px !important;
        min-height: 30px !important;
    }
    .treebutton{
        margin-top: 5px !important;
    }
}
// input workflow tree grid row css end

.fee_details.tradefee-inputspace{
    .col-lg-4{
        min-height: 4em !important;
    }
}

.formgrid-view .traderecon-gridheader .fury-list-table .fury-list-header .fury-list-name {
    width: 18vw;
}
.rapid-expend-grid .grid-expand-collapseicon .fury-page-layout-content.pr-1{
    padding-right: 25px;
}
.rapid-pos-btn.trade-list-input.grid-border {
    .fury-list-header {
        border-top: 1px solid rgba(0, 0, 0, .12);
    }
    .mat-form-field-infix{
        padding: 0.8em 0;
    }
    button.sm_button.mat-raised-button.mat-button-base.mat-primary{
        bottom: 19px!important;
    }
}
.mat-divider.button-mat-divider{
    border: none;
    padding: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: none !important;
    li.list-group-item{
        white-space: normal !important;
        background: none !important;
        width: auto !important;
        padding: 0 !important;
    }
}
.inputupload-brose-section.borwse-btn-index .p-fileupload .p-fileupload-buttonbar{
    z-index: 111 !important;
}
// run trade recon matrial popup 

#recontrad-popup {
    .mat-step:first-child {
        .mat-step-header {
            padding: 16px 27px !important;
            border-radius: 0 !important;
            background: #1976d2;
            left: 0;
            right: 0;
            border: none;
        }
    }
    .p-dialog-header-close {
        position: absolute !important;
        right: 10px;
        top: 13px;
        color: #fff;
        width: 2rem;
        height: 2rem;
        padding: 14px;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .mat-step:first-child .mat-step-header .mat-step-text-label .mat-button-toggle-group {
        margin-right: 0%!important;
    }
    .mat-step:first-child .mat-step-header .mat-step-text-label {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        padding-right: 35px;
        line-height: 33px;
    }
    .splitgroupbtn .mat-button-toggle {
        margin-top: -2px!important;
    }
    mat-step:last-child .mat-vertical-content-container {
        margin-left: 0!important;
    }
    .card .adv-content {
        display: flow-root!important;
    }
    .mat-step:first-child .mat-step-header .mat-step-label, .setup-process .mat-step:first-child .mat-step-header .mat-step-label:active {
        width: 100%!important;
    }
    .mat-vertical-content-container{
        margin-left: 0px;
    }  
    .mat-step-icon{
        margin-right: 0px;
    }
    .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
        display: none;
    }
    .p-dialog-header-close:hover {
        box-shadow: 0 0 5px #eae6e1;
        border-radius: 50%;
        animation: pop .3s 2;
        border: 2px solid #fff;
    }
}
.mat-dialog-container#recontrad-popup {
    background: none;
    box-shadow: none;
}
.trpidupload-card-inputspace.modal-heigthsp{
    .p-dialog .p-dialog-content{
        min-height: auto !important;
    }
}
.bondinterest-modal.pr-35{
    .fury-list-table{        
        .fury-list-header{
            padding-right: 34px !important;
        }
    }
}

.non-input fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header {
    background-color: #1976d2 !important;  
    .mat-step-text-label {
        color: #fff;
    } 
}
.formcheck-box.col-lg-6.inputalign-allrapid {
    padding-top: 0px !important;
    padding-bottom: 15px !important;
    min-height: auto;
}
.formcheck-box.col-lg-4.inputalign-allrapid {
    padding-top: 0px !important;
    padding-bottom: 15px !important;
    min-height: auto;
}
.runtxt-lotmulti-broker{
    .p-dialog .p-dialog-content{
        padding: 0 1.5em !important;
    }
    .p-dialog .p-dialog-header{
        padding: 1em 1.5rem !important;
    }
}
.gridsetup-modal-view .dailogheader-block .p-dialog .p-dialog-header{
    padding: 2rem !important;
    display: block;
}
.setup-process .input-sp-batch .mat-step .mat-vertical-content {
    padding-right: 24px !important;
    .mat-form-field-flex {
        .mat-form-field-outline {
            left: 0px !important;
        }
    }
}

.sidepocket-inputspace.col-lg-4{
    min-height: auto !important;
}

// input all create option ui

.allcreate-inputmodal{width: 100%;}
.mainmodal-cardui {
    display: flex;
    .customworkflow{
    --height: 24vh !important;
    max-height: calc(var(--height) + 11em) !important;
    }
    .exportview{
        --height: 30vh !important;
        max-height: calc(var(--height) + 16em) !important;
        }
    .newmodalui-view-modal,.newmodalui-view-modal1,.newmodalui-view-modal2 {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row: 1/3;
        column-width: 183px;
        column-gap: 25px;
        -webkit-column-gap: 25px;
        -moz-column-gap: 25px;
        --height: 100vh;
        padding: 0 25px;
        max-height: calc(var(--height) + 18em);
        overflow-y: auto; 
        .inputmodal {
            background: #fff;
            box-shadow: 0 2px 5px 1px #403c4329;
            cursor: pointer;
            display: inline-block;
            margin: 15px 1px;
            width: 100%;
            ul {
                list-style: none;
                margin: 0;
                padding: 0 0 10px 0px;

                li {
                    padding: 10px;
                    a{
                        color: #1d2125;
                        font-weight: 500;
                        cursor: pointer;
                        &:hover{
                            color: #1976d2;
                        }
                    }
                    &:hover{
                        background: #ee8434;
                        color: #fff;
                        cursor: pointer;
                        a{
                            color: #fff;  
                            cursor: pointer;
                        }                         
                    }
                }
                h3 {
                    width: 100%;
                    display: block;
                    border-bottom: solid 1px #eae6e1;
                    margin: 0 0 5px 0px;
                    padding: 5px 0 5px 10px;
                    font-size: 14px;
                    color: #646668;
                    background: #eae6e133;
                    cursor: auto;
                }
            }
        }
    }
}
.setup-process span.default-allinputmodal .mat-raised-button,.setup-process span.french-allinputmodal .mat-raised-button {
    right: 0%!important;
    width: auto;
    margin-top: 0;
    background: #fff!important;
    line-height: 30px;
}
.setup-process span.default-allinputmodal,.setup-process span.french-allinputmodal {
    position: absolute;
    top: 15%;
    right: 1.8em!important;
    width: auto;
    .mat-button-wrapper{
        padding: 0px !important;
    }
}

.allcreate-inputmodal {
    .p-component.p-dialog-draggable.p-dialog-resizable.p-dialog .p-dialog-header{
        padding: 16px 27px!important;
        border-radius: 0!important;
        left: 0;
        right: 0;
        border: none;
        }
}
// START Selector Button see all options
span.stickyButton.optionselectorbtn.default-allinputmodal {
    width: 100%;
    span.ui-text{
        margin-left: 0px !important;
    }
}
    // END Selector Button see all options

    // grid new button ui css start

    .fury-list-table {
        .fury-list-header {
            .actions.oms-list-btns.uploadbtn_alignment.gridbtn-ui button {
                margin-left: 10px;
                margin-right: 0px !important;
            }
        }
    }
    .header-group-btn.gridupoad-btn{
        .mat-button-toggle-group{
            box-shadow: none !important;
        }
        button{
            margin-left: 0px !important;
        }
    }

    // grid new button ui css end

    button.mat-tooltip-trigger {
        span {
            .mat-icon {
                &:hover {
                    transform: scale(1.3);
                }
            }
        }
    }
    .gridexpand-icon-responsive .setup-grid-form-view .formgrid-view .collapse-view {
        padding-right: 0px;
        margin-top: -2.1rem !important;
        margin-right: -5px;
      }
      fury-troniq-vendor .rapid-expend-grid fury-page-layout-content.fury-page-layout-content.fury-page-layout-content.trade-view-input .card.trade-view-card {
        width: 96.5% !important;
    }
    .default-grid .paymentgrid .fury-page-layout-content.grid-spcui {
        padding-right: 24px;
    }
    fury-payments-data, fury-bills-create,fury-invoice-create ,fury-cash-invoice,fury-cash-invoice-list,fury-aged-payable-report,fury-vendor-unpaid-bill, fury-vendor-balance-report,fury-vendor-transaction-report,
    fury-vendors-entry, fury-vendors,fury-compound-je-list,fury-income-distribution-base,fury-investor-groups,fury-investors,fury-customer-entry,fury-customer-list, fury-vendor-cashexpense,fury-vendor-cash-expenselist, fury-vendor-credit, fury-bills, fury-compound-journal-entry{
        fury-page-layout.fury-page-layout.fury-page-layout-card {
            margin-top: -7px;margin-right: -2px;
        }
    } 
    #fs-investor-stepper {
        .investor-process .step-flow {
            height: auto !important;
            border-right: none !important;
            margin: 0px 0px 0px 0px !important;
        }
    }
    fury-bills-create,fury-invoice-create,fury-cash-invoice,fury-cash-invoice-list, fury-vendor-cashexpense, fury-vendor-credit{
        .content.adv-content.trpidupload-card-inputspace {
            margin: inherit;padding-left: inherit;
        }
       .mat-vertical-content {
            padding: 24px 0px 0px 10px;
        }
        .setup-advance-details.card{
            .mat-step .mat-vertical-content-container .mat-vertical-content {
                padding-top: 0px;
            }
        } 
    }
    .content.total-amount-input {
        margin: 0px 0px 15px 0px !important;
    }
    app-grid.paymentsview, app-grid.compoundJElistgrids, app-grid.gridbillpayable {
        fury-page-layout-content.fury-page-layout-content.grid-spcui.all-grid-expand-collapseicon {
            margin: 0px !important;
            padding: 0px !important;
        }
    } 
    fury-page-layout-content.fury-page-layout-content .card.ifram-card {
        margin: -5px 0px !important;
        padding: 0px;
        border-radius: 0px;
    }
    fury-bills-create,fury-invoice-create ,fury-cash-invoice, fury-cash-invoice-list,fury-vendor-cashexpense, fury-vendor-credit{
        .card.rapid-pos-btn.mr-0.gridsetup-modal-view { width: 100%;}
    } 
    .setup-process fury-bills{
        .mat-vertical-content{padding: 0px !important;padding-right: 0px !important;}
    } 
    .rapid-expend-grid fury-bills{
    fury-page-layout.fury-page-layout.fury-page-layout-card{
        .card{margin-top: 0px !important;}
        .card.grid-input-viewform{margin-top: -7px;}
       }}
       fury-troniq-vendor .content.adv-content.trpidupload-card-inputspace,fury-troniq-customer .content.adv-content.trpidupload-card-inputspace {
        margin: 0px;padding-left: 24px;
    }
    #applyCredit mat-label.label-font {
        color: #1976d2;
        font-weight: 600;
        font-size: 16px;}
   
    
        
    //fs-investor start
    #fs-investor-stepper {
        .investor-process .step-flow {
            height: auto !important;
            border-right: none !important;
            margin: 0px 0px 0px 35px !important;
        }

        .percent-disable {
            pointer-events: none;
        }
    
        .not-allow {
            cursor: not-allowed !important;
        }
    }
    fury-fs-investor fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header {
        display: none !important;
    }
    fury-fs-portfolio fury-page-layout-content.fury-page-layout-content.trade-view-input.input-upload-header .mat-vertical-stepper-header {
        display: none !important;
    }
    //fs-investor end

    .showfooter{
        padding-top:unset;
    }

    
