/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
fury-navigation {
  display: block;
  background: $sidenav-background;
  color: $sidenav-color;
  @include mat-elevation($toolbar-elevation);

  .navigation-item-container {
    .navigation-item {
      color: $sidenav-color;

      &.active {
        &.active {
          background: $sidenav-item-background-active;
          color: $sidenav-item-color-active;
        }
      }
    }

    &:hover, &:focus {
      .navigation-item {
        color: $sidenav-item-color-hover;
        background: $sidenav-item-background-hover;
      }
    }
  }
}
