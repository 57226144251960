/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
.fury-preview-card {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-radius: $border-radius;
  @include mat-elevation(2);

  .preview {
    padding: $spacing;
    background: $theme-app-bar;
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: $spacing;
      font: var(--font-title);
    }

    .preview-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 200px;
    }
  }

  .content {
    background: $theme-card;
    @include mat-elevation(8);

    .header {
      padding: $spacing $spacing 0;
    }

    .title {
      font: var(--font-headline);
    }

    .tag {
      color: $theme-secondary-text;
      font: var(--font-body-1);
    }

    .tabs {
      margin-top: $spacing;
      border-top: 1px solid $theme-divider;
    }

    .tab-content {
      padding: $spacing;
    }
  }
}
