/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
.fury-page-layout {
  display: block;
}

.fury-page-layout-simple {

  .fury-page-layout-content {
    padding-top: $padding;
    padding-bottom: $padding;
  }
}

.fury-page-layout-card {
  padding-bottom: $padding;

  .fury-page-layout-header {
    margin-bottom: -$page-layout-toolbar-height;
    padding-bottom: $page-layout-toolbar-height;
    .title{font-weight: 400;}
  }

}

.fury-page-layout-header {
  height: $page-layout-height - $page-layout-toolbar-height - 6;
  padding-left: $padding;
  padding-right: $padding;
  display: flex;
  flex-direction: row;
  place-content: center flex-start;
  align-items: center;
}

.fury-page-layout-content {
  display: block !important;
  padding-left: $padding;
  padding-right: $padding;

  @include layout-bp(lt-md) {
    padding-left: $padding-md;
    padding-right: $padding-md;
  }
}
