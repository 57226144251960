/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
@import "./var-helpers";

$sidenav-background: $theme-app-bar !default;
$sidenav-color: $theme-text !default;
$sidenav-toolbar-background: $theme-app-bar !default;
$sidenav-logo-color: $theme-text !default;

$sidenav-item-color: $sidenav-color !default;
$sidenav-item-color-hover: $theme-text !default;
$sidenav-item-background-hover: $theme-background-hover !default;
$sidenav-item-text-icon-color: $theme-hint-text !default;

$sidenav-item-subheading-color: $theme-secondary-text !default;

$toolbar-background: $theme-app-bar !default;
$toolbar-search-background: $theme-card !default;

@import "./default";
