/* You can add global styles to this file, and also import other style files */
@import "./@fury/styles/core";
@import "./app/app.theme";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "../node_modules/tributejs/dist/tribute.css";
@import '../styles/global_ui.scss';
@import '../styles/dark-themeui.scss';
@import '../styles/mobile-responsive.scss';
@import '../styles/rapid-mobile-responsive.scss';
@import '../styles/rapid.scss';
@import '../styles/rapid1.scss';
@import '../styles/rapid2.scss';
@import '../styles/taxautomation.scss';
@import '../styles/furydashboard.scss';
@import '../styles/financialstatement.scss';
@import './styles/iconsmind/iconsmind.css';


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
