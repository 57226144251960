/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

fury-audience-overview-widget {

  .label {
    color: $theme-secondary-text;
  }

  .property {

    .compared {
      color: $theme-secondary-text;
    }

    &:hover {
      background: $theme-background-hover;
    }

    &.active {
      border-color: #4285f4;

      .label {
        color: $theme-text;
        font-weight: 500;
      }

      .value {
        font-weight: 400;
      }

      .compared {
        visibility: visible;
      }
    }
  }

  .gain {
    &.success {
      color: $color-success;
    }

    &.warn {
      color: $theme-color-warn;
    }
  }
}
